import localforage from 'localforage';
import isPast from 'date-fns/is_past';
import setSeconds from 'date-fns/set_seconds';
import isEmpty from 'lodash/isEmpty';

import aiTracking from './aiTracking';
import { LookupTable } from '../actions/profilePictures';
import { store } from '../store';
import { ACTIONS } from '../actions/profilePictures';

const profileImageCacheDurationInDays = 3;
const oneDayInSeconds = 24 * 60 * 60;

const totalCachingTimeInSeconds = oneDayInSeconds * profileImageCacheDurationInDays; // store it for 3 days;

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'sherlock-scheduling',
  storeName: 'profile_images',
  description: 'Local caching of profile images for sherlock scheduling',
});

type ImageKeys = string[];
type Item = ProfileImageStructure | ImageKeys | null;
interface ProfileImageStructure {
  blob: Blob;
  lookupTable: LookupTable;
  createdAt: Date;
  expiredIn: Date;
}
function isItemAsProfileImage(item: Item): item is ProfileImageStructure {
  return Boolean(item && ('blob' && 'lookupTable' && 'created' && 'expiredIn') in item);
}

export async function storeProfilePicturesToIndexDB(
  key: string,
  blob: Blob,
  lookupTable: LookupTable
) {
  const createdAt = new Date();
  const expiredIn = setSeconds(createdAt, totalCachingTimeInSeconds);

  await storeItem(key, {
    blob,
    lookupTable,
    createdAt,
    expiredIn,
  });
}

export async function syncProfilePicturesToReduxStore() {
  try {
    const allImageIds: string[] = await localforage.keys();
    const promises = [];

    for (const id of allImageIds) {
      const getProfilePicturePromise: Item = await retriveItem(id);

      promises.push(getProfilePicturePromise);
    }

    const allProfileImages = await Promise.all(promises);

    let lookupTablesToSync = {};
    let profileImagesToSync = {};

    allProfileImages.forEach((item, index) => {
      if (isItemAsProfileImage(item)) {
        const id = allImageIds[index];
        const hasNotExpired = !isPast(item.expiredIn);

        if (hasNotExpired) {
          profileImagesToSync = Object.assign(profileImagesToSync, {
            [id]: URL.createObjectURL(item.blob),
          });
          lookupTablesToSync = Object.assign(lookupTablesToSync, item.lookupTable);
        } else {
          removeItem(id);
        }
      }
    });

    if (!(isEmpty(lookupTablesToSync) || isEmpty(profileImagesToSync)))
      store.dispatch({
        type: ACTIONS.RECEIVE_PROFILE_PICTURES,
        payload: {
          profileImage: profileImagesToSync,
          lookupTable: lookupTablesToSync,
        },
      });
  } catch (e) {
    aiTracking.catchError(e);
  }
}
/*
Writing and getting from indexDB could fail for various reasons, we ignore the errors and continue on.
*/
export async function storeItem(key: string, data: ProfileImageStructure) {
  try {
    await localforage.setItem(key, data);
  } catch (e) {
    aiTracking.catchError(e);
  }
}
async function removeItem(key: string) {
  try {
    await localforage.removeItem(key);
  } catch (e) {
    aiTracking.catchError(e);
  }
}

export async function retriveItem(key: string): Promise<Item> {
  try {
    const data: Item = await localforage.getItem(key);

    return data;
  } catch (e) {
    aiTracking.catchError(e);

    return null;
  }
}
