import React from 'react';
import ContentEditable from 'react-contenteditable';
import DOMPurify from 'dompurify';

import emitter from '../../../modules/emitter';
import { getHighLightTalentSearchInput } from '../../../modules/hitHighLight/highLightTalentSearchInput';
import { TalentSearchPayload } from '../../../actions/talentSearch';
import { eventMessages } from '../../../constants';

/*
For some reasons functional component does not work with content editable.
*/
interface HitHighLightInputProps {
  talentSearchResponse: TalentSearchPayload | null;
  search: (value: string) => void;
  onChange: (value: string) => void;
  placeholder: string;
  className: string;
  disabled: boolean;
}
interface HitHighLightInputState {
  html: string;
  content: string;
}
const sanitizeConf = { ALLOWED_TAGS: ['span'], ALLOWED_ATTR: ['class'] };

class HitHighLightInput extends React.Component<HitHighLightInputProps, HitHighLightInputState> {
  // eslint-disable-next-line
  private readonly inputRef: React.RefObject<any>;
  private unsubscribe: () => void;
  constructor(props: HitHighLightInputProps) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      html: '',
      content: '',
    };
    this.unsubscribe = emitter.subscribe(
      eventMessages.HIGH_LIGHT_TALENT_SEARCH_INPUT,
      talentSearchResponse => {
        this.onHighLight(talentSearchResponse);
      }
    );
  }
  clearStyle() {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0 && selection.isCollapsed) {
      const range = selection.getRangeAt(0);

      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
  sanitize = () => {
    this.setState({ html: DOMPurify.sanitize(this.state.html, sanitizeConf) });
  };
  handleChange = (evt: any): void => {
    if (!this.inputRef.current.textContent) {
      this.inputRef.current.innerHTML = '';
      this.clearStyle();
    }

    this.setState({
      html: this.inputRef.current.textContent ? evt.target.value : '',
      content: this.inputRef.current.textContent,
    });
    this.props.onChange(this.inputRef.current.textContent);
  };
  onHighLight(talentSearchResponse: TalentSearchPayload) {
    if (talentSearchResponse) {
      const newInput = getHighLightTalentSearchInput(talentSearchResponse);

      if (newInput) {
        this.setState({
          html: DOMPurify.sanitize(newInput, sanitizeConf),
        });
      }
    }
  }
  componentWillUnmount() {
    this.unsubscribe();
  }
  render() {
    const { talentSearchResponse, search, ...rest } = this.props;

    return (
      <ContentEditable
        style={{
          overflow: 'hidden',
          overflowWrap: 'normal',
        }}
        {...rest}
        onKeyPress={(event: React.KeyboardEvent<any>) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            search(this.state.content);
          }
        }}
        onBlur={this.sanitize}
        innerRef={this.inputRef}
        html={this.state.html}
        onChange={this.handleChange}
      />
    );
  }
}

export default HitHighLightInput;
