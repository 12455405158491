import React from 'react';
import Grid from '@material-ui/core/Grid';

/**
 *
 * Connection error page
 * 
 * @constructor
 * @return <Grid> the connection error page
 * @example <Grid
    container
    spacing={0}
    direction='column'
    alignItems='center'
    justify='center'
    style={{ minHeight: '50vh' }}
  >
    <Grid item xs={3}>
      <h1 style={{ color: '#39aefe', textAlign: 'center' }}>Connection error</h1>
      <p style={{ textAlign: 'center' }}>Internet connection or server temporarily unavailable.</p>
    </Grid>
  </Grid>
 */
const ServerError: React.FC = () => (
  <Grid
    container
    spacing={0}
    direction='column'
    alignItems='center'
    justify='center'
    style={{ minHeight: '50vh' }}
  >
    <Grid item xs={3}>
      <h1 style={{ color: '#39aefe', textAlign: 'center' }}>Authorization Error</h1>
      <p style={{ textAlign: 'center' }}>You don't have the proper role assigned to use this function.</p>
    </Grid>
  </Grid>
);

export default ServerError;
