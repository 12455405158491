import { NotificationType } from '../../reducers/notification/types';

export enum ACTIONS {
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS',
}
export interface NotificationContent {
  type: NotificationType;
  message: string;
  timeout?: number;
}
export interface AddNotification {
  type: ACTIONS.ADD_NOTIFICATION;
  payload: NotificationContent;
}
export interface AddNotifications {
  type: ACTIONS.ADD_NOTIFICATIONS;
  payload: NotificationContent[];
}
export interface RemoveNotification {
  type: ACTIONS.REMOVE_NOTIFICATION;
  payload: string;
}

export type NotificationActions = RemoveNotification | AddNotification | AddNotifications;
