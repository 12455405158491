import { PMI_TalentData } from '../actions/talentSearch/types';
import { store } from '../store';

const lineBreaks = (number = 1) => {
  const lineBreak = '%0D%0A';

  return lineBreak.repeat(number);
};

function getTalentInfoToEmailFormat(
  talent: PMI_TalentData,
  talentSearchResponse: any,
  isProjectRecommendations?: boolean
) {
  const { firstName, lastName, email, rankPosition } = talent;
  let uri;

  if (!isProjectRecommendations) {
    const query =
      talentSearchResponse.data && talentSearchResponse.data.query
        ? talentSearchResponse.data.query
        : null;

    const row = query ? query.raw.replace(/ /g, '+') : null;

    uri = query
      ? encodeURIComponent(
          `/search/talents?query=${row}&freeTextQuery=${query.freeText}&searchMode=${query.searchMode}`
        )
      : null;
  } else {
    const enterpriseID = store
      .getState()
      .projectRecommendations.filters.enterpriseID.replace(/ /g, '+');

    uri = encodeURIComponent(`/project-recommendation?enterpriseID=${enterpriseID}`);
  }

  const setOrigin = (origin: string) => {
    if (origin.includes('localhost')) {
      return 'https://api.dev-sherlock.pmicloud.biz';
    }

    return origin;
  };

  return `

Hello,${lineBreaks()}${lineBreaks()}

I think this employee would be a good match for your project.${lineBreaks()}
Name: ${firstName} ${lastName}${lineBreaks()}
Email: ${email}${lineBreaks()}
${rankPosition ? `Rank Position: ${rankPosition}${lineBreaks()}` : ``}
You can find more information here: ${lineBreaks()}
${setOrigin(window.location.origin)}${uri}${lineBreaks()}`;
}

export default getTalentInfoToEmailFormat;
