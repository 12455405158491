import uuidv1 from 'uuid/v1';

import { sherlockBackend, store } from '../../store';
import { ACTIONS, ProfileImage, LookupTable, NewTalentProfilePictures } from './types';
import { profilePictureSize } from '../../constants';
import { storeProfilePicturesToIndexDB } from '../../utils/cacheProfileImages';
import { OpenRoleTalentData } from '../openRoles';

export * from './types';

function profilePictureModel(data: Blob, talentNames: string[]): NewTalentProfilePictures {
  const imageUrl = URL.createObjectURL(data);
  const imageId = uuidv1();

  const profileImage: ProfileImage = {
    [imageId]: imageUrl,
  };

  const lookupTable: LookupTable = {};

  talentNames.forEach((name, index) => {
    lookupTable[name] = {
      position: -(profilePictureSize * index),
      imageId,
    };
  });
  storeProfilePicturesToIndexDB(imageId, data, lookupTable);

  return {
    profileImage,
    lookupTable,
  };
}

export const fetchTalentProfilePictures = async (talents: any | OpenRoleTalentData[]) => {
  const {
    profilePictures: { lookupTable },
  } = store.getState();

  const prefix = 'ids=';
  const params: string[] = [];
  const talentNames: string[] = []; // this is for used as a hash table later for look up.

  // @ts-ignore
  talents?.forEach(({ supplyKey }) => {
    if (!lookupTable[supplyKey]) {
      params.push(`${prefix}${supplyKey}`);
      talentNames.push(supplyKey);
    }
  });

  if (params.length === 0) {
    // there's nothing to fetch.
    return;
  }

  return sherlockBackend(
    {
      method: 'get',
      url: `/users/images?${params.join('&')}`,
      responseType: 'blob',
    },
    {
      onSuccess: response => {
        store.dispatch({
          type: ACTIONS.RECEIVE_PROFILE_PICTURES,
          payload: profilePictureModel(response.data, talentNames),
        });
      },
    }
  );
};
