import { CSSProperties } from '@material-ui/styles';

const categories = ['PARTIAL_MATCH', 'SKILL', 'ROLE', 'FUZZY', 'SPECIALIZATION'];

const commonStyle: CSSProperties = {
  position: 'relative',
  padding: 1,
  '&:hover': {
    '& > .hit-highlight-label': {
      visibility: 'visible',
    },
  },
};

const hitHighLightStyles: CSSProperties = {
  '.hit-highlight-PARTIAL_MATCH': {
    ...commonStyle,
    backgroundColor: '#FFFACD',
  },
  '.hit-highlight-PARTIAL_MATCH-label': {
    '&::after': {
      content: '"Partial Match"',
    },
  },
  '.hit-highlight-label': {
    visibility: 'hidden',
    width: '120px',
    backgroundColor: 'black',
    color: ' #fff',
    textAlign: 'center',
    borderRadius: '6px',
    padding: '5px 0',
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    marginLeft: '-60px',
    zIndex: 1,
  },
  '.hit-highlight-ROLE': {
    ...commonStyle,
    backgroundColor: '#98FB98',
  },
  '.hit-highlight-ROLE-label': {
    '&::after': {
      content: '"Role"',
    },
  },
  '.hit-highlight-FUZZY': {
    ...commonStyle,
    backgroundColor: '#E6E6FA',
  },
  '.hit-highlight-FUZZY-label': {
    '&::after': {
      content: '"Partial"',
    },
  },
  '.hit-highlight-SPECIALIZATION': {
    ...commonStyle,
    backgroundColor: '#dcdcdc',
  },
  '.hit-highlight-SPECIALIZATION-label': {
    '&::after': {
      content: '"Specialization"',
    },
  },
  '.hit-highlight-SKILL': {
    ...commonStyle,
    backgroundColor: '#D3D3D3',
  },
  '.hit-highlight-SKILL-label': {
    '&::after': {
      content: '"Skill"',
    },
  },
};

function getHitHighLightStyles(category: string) {
  const prefix = 'hit-highlight-';

  const style = {
    keywordStyle: '',
    labelStyle: '',
  };

  if (categories.indexOf(category) > -1) {
    style.keywordStyle = `${prefix}${category}`;
    style.labelStyle = `${prefix}${category}-label`;
  } else {
    style.keywordStyle = `${prefix}FUZZY`;
    style.labelStyle = `${prefix}FUZZY-label`;
    // There are more categories returned from the backend that i don't know what they are yet, hance the fallback.
  }

  return style;
}

export { getHitHighLightStyles, hitHighLightStyles };
