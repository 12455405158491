import axios, { AxiosRequestConfig } from 'axios';

import { ensureTokenIsValid } from './auth';
import { errorInterceptor, responseInterceptor } from './middlewares';
import { backendUrl } from '../constants';

if (!backendUrl) {
  throw new Error(
    'Could not get the backend URL from the corresponding env variable. (REACT_APP_BACKEND_URL)'
  );
}

const Qs = require('qs');

export const sherlockAxios = axios.create({
  baseURL: backendUrl,
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

sherlockAxios.interceptors.request.use(async axiosRequestConfig => {
  const session = await ensureTokenIsValid();

  // worst answer on Stackoverflow: https://stackoverflow.com/a/49529422/2524925
  const sherlockAxiosConfig: AxiosRequestConfig = {
    ...axiosRequestConfig,
    headers: {
      Authorization: `Bearer ${session ? session.idToken : ''}`,
    },
  };

  return sherlockAxiosConfig;
});

sherlockAxios.interceptors.response.use(
  response => {
    return responseInterceptor(response);
  },
  error => {
    return errorInterceptor(error);
  }
);
