import { FetchDelegateUserPayload } from '../../actions/delegateUser';

export const DATE_FORMAT = 'DD.MM.YYYY';

export interface DelegateUserState {
  delegatedUsers: FetchDelegateUserPayload | [];
}

export interface RoleAutoCompleteResponse {
  [key: string]: string;
  user_id: string;
}
