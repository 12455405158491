import { guid } from './helpers';

interface EnvConfig {
  clientId: string;
  tenantId: string;
  loginProvider: string;
}
interface EnvConfigs {
  development: EnvConfig;
  test: EnvConfig;
  production: EnvConfig;
}
const currentEnv = process.env.REACT_APP_ENVIRONMENT as keyof EnvConfigs | undefined;

if (!currentEnv || !['development', 'test', 'production'].includes(currentEnv)) {
  throw new Error('Environment variable REACT_APP_ENVIRONMENT is not defined');
}

const secrets = {
  development: {
    clientId: 'b4bd206c-a29e-4e2b-a14e-d8745e7e964e',
    tenantId: '8b86a65e-3c3a-4406-8ac3-19a6b5cc52bc',
  },
  test: {
    clientId: 'b4bd206c-a29e-4e2b-a14e-d8745e7e964e',
    tenantId: '8b86a65e-3c3a-4406-8ac3-19a6b5cc52bc',
  },
  production: {
    clientId: 'b4bd206c-a29e-4e2b-a14e-d8745e7e964e',
    tenantId: '8b86a65e-3c3a-4406-8ac3-19a6b5cc52bc',
  },
};

const secret = secrets[currentEnv];
const scopes = ['openid', 'profile', 'email', 'user.read', 'offline_access'];

const loginRedirectUri = encodeURIComponent(`${window.location.origin}/login-callback`);

const logoutRedirectUri = encodeURIComponent(window.location.origin);

const loginProvider = `https://login.microsoftonline.com/${secret.tenantId}/oauth2/v2.0`;

function getUrl(extraParameters?: Record<string, string>): string {
  return `
    ${loginProvider}/authorize
      ?response_type=token id_token
      &redirect_uri=${loginRedirectUri}
      &client_id=${secret.clientId}
      &scope=${scopes.map(scope => scope).join('+')}
      &nonce=${guid()}
      ${extraParameters &&
        Object.entries(extraParameters).reduce(
          (prev, parameter) => `${prev}&${parameter[0]}=${parameter[1]}`,
          ''
        )}
  `;
}

const loginUrl = getUrl();
const logoutUrl = `${loginProvider}/logout?post_logout_redirect_uri=${logoutRedirectUri}`;
const refreshTokenUrl = getUrl({ prompt: 'none' });

const authConfig = {
  ...secret,
  scopes,
  loginRedirectUri,
  logoutRedirectUri,
  loginUrl,
  logoutUrl,
  refreshTokenUrl,
};

export default authConfig;
