import React, { useState, Suspense, lazy } from 'react';
import {
  Tabs,
  Tab as MuiTab,
  ExpansionPanelActions,
  makeStyles,
  Theme,
  Divider,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { TabProps } from '@material-ui/core/Tab';
import SherlockIcon from '../../Icons';
import { ReactComponent as TeamsIcon } from '../../../static/icons/teams.svg';
import { ReactComponent as ForwardIcon } from '../../../static/icons/forward.svg';
import SearchResultData from './SearchResultData';
import EmailLink from '../../Links/EmailLink';
import TeamsLink from '../../Links/TeamsLink';
import RecommendTalentLink from '../../Links/RecommendTalentLink';
import { useSelector } from '../../../reduxHooks';
import { SearchModes } from '../../../reducers/talentSearch';
import { useLocation } from 'react-router';
import { PMI_TalentData } from '../../../actions/talentSearch';
import EmailIcon from '@material-ui/icons/Email';
import InfoContainer from '../../InfoButton/InfoContainer';
import ResultsAiMatchFeedbackInfo from '../../InfoButton/buttons/ResultsAiMatchFeedbackInfo';

const MatchFeedback = lazy(() => import('./MatchFeedback'));
const ExplainableAI = lazy(() => import('../../ExplainableAI/ExplainableAI'));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  content: {
    margin: theme.spacing(1, 3, 3),
    minHeight: 400,
    maxHeight: 400,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginRight: '38px',
  },
  expansionPanelActions: {
    padding: 0,
    justifyContent: 'flex-start',
  },
  tabsRoot: {
    minHeight: 'unset',
  },
  tabRoot: {
    minHeight: theme.spacing(5),
    minWidth: 'unset',
    padding: theme.spacing(1, 3),
  },
  flexGrow: {
    flexGrow: 1,
  },
  actionButton: {
    width: 40,
    height: 40,
    padding: 0,
  },
  panelActionButtons: {
    display: 'flex',
    marginRight: theme.spacing(3),
  },
  skypeIcon: {
    height: 30,
    width: 30,
    filter: 'grayscale(100%)',
  },
  teamsIcon: {
    height: 30,
    width: 30,
    filter: 'grayscale(100%)',
  },
  hiddenOnMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

interface ExpandedContentProps {
  talent: PMI_TalentData;
}

enum PanelActionTabs {
  SearchResultData = 'SEARCH_RESULT_DATA',
  ExplainableAi = 'EXPLAINABLE_AI',
  TalentComments = 'TALENT_COMMENTS',
  TalentProfile = 'TALENT_PROFILE',
  MatchFeedback = 'MATCH_FEEDBACK',
  TalentCv = 'TALENT_CV',
}

/**
 * The expanded content of the talent's list
 * shows the full set of skills of the expanded talent
 * plus explainable AI data on the set of skills,
 * the possibility of contacting or recommending the talent, or give feedback on the match.
 *
 * @param {PMI_TalentData} talent
 */
const ExpandedContent: React.FC<ExpandedContentProps> = ({ talent }) => {
  const [currentTab, setCurrentTab] = useState(PanelActionTabs.SearchResultData);
  const searchMode = useSelector(state => state.talentSearch.searchMode);
  const talentSearchResponse = useSelector(state => state.talentSearch.talentSearchResponse);
  const isProjectRecommendations = useLocation().pathname === '/project-recommendation';

  const classes = useStyles();

  const Tab: React.FC<TabProps> = props => {
    return <MuiTab classes={{ root: classes.tabRoot }} {...props} />;
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {currentTab === PanelActionTabs.SearchResultData && <SearchResultData talent={talent} />}
        {searchMode === SearchModes.talentSearch &&
          currentTab === PanelActionTabs.ExplainableAi &&
          searchMode === 'talentSearch' && (
            <Suspense fallback={<div />}>
              <ExplainableAI supplyKey={talent.supplyKey} />
            </Suspense>
          )}
        {currentTab === PanelActionTabs.MatchFeedback &&
          !isProjectRecommendations &&
          talent.rankPosition && (
            <Suspense fallback={<div />}>
              {<MatchFeedback talent={talent} isProfileMatching={searchMode !== 'talentSearch'} />}
            </Suspense>
          )}
      </div>
      <Divider />
      <ExpansionPanelActions classes={{ root: classes.expansionPanelActions }}>
        <InfoContainer>
          <Tabs
            value={currentTab}
            onChange={(event, value) => setCurrentTab(value)}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            classes={{ root: classes.tabsRoot }}
          >
            <Tab value={PanelActionTabs.SearchResultData} label='Search Result Data' />
            {searchMode === SearchModes.talentSearch && !isProjectRecommendations && (
              <Tab
                value={PanelActionTabs.ExplainableAi}
                label='Explainable AI'
                className={classes.hiddenOnMobile}
              />
            )}
            {!isProjectRecommendations && talent.rankPosition && (
              <Tab
                value={PanelActionTabs.MatchFeedback}
                label='Match Feedback'
                className={classes.hiddenOnMobile}
              />
            )}
          </Tabs>
          <ResultsAiMatchFeedbackInfo top={11}right={-10} position={'top'}/>
        </InfoContainer>
        <div className={classes.flexGrow} />
        <div className={classes.panelActionButtons}>
          <Tooltip title='Contact via Email' placement={'top'} enterDelay={300}>
            <div>
              <EmailLink link={`${talent.email}`}>
                <IconButton classes={{ root: classes.actionButton }} aria-label='Contact via Email'>
                  <SherlockIcon>
                    <EmailIcon />
                  </SherlockIcon>
                </IconButton>
              </EmailLink>
            </div>
          </Tooltip>
          <Tooltip title='Contact via MS Teams' placement={'top'} enterDelay={300}>
            <div>
              <TeamsLink link={talent.email}>
                <IconButton
                  classes={{ root: classes.actionButton }}
                  aria-label='Contact via MS Teams'
                >
                  <TeamsIcon className={classes.teamsIcon} />
                </IconButton>
              </TeamsLink>
            </div>
          </Tooltip>
          <Tooltip title='Recommend talent to colleague' placement={'top'} enterDelay={300}>
            <div>
              <RecommendTalentLink
                isProjectRecommendations={isProjectRecommendations}
                talentSearchResponse={talentSearchResponse}
                talent={talent}
              >
                <IconButton
                  classes={{ root: classes.actionButton }}
                  aria-label='Recommend talent to colleague'
                >
                  <ForwardIcon />
                </IconButton>
              </RecommendTalentLink>
            </div>
          </Tooltip>
        </div>
      </ExpansionPanelActions>
    </div>
  );
};

export default ExpandedContent;
