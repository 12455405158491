import React, { useState } from 'react';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { useSelector } from '../../reduxHooks';
import ListHeader from './TableHeader';
import TableRow from './TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { CircularProgress, IconButton, Typography, Grid, Paper } from '@material-ui/core';
import {
  setPage,
  PMI_TalentData,
  talentSearch,
  allTalentProfilesSearch,
} from '../../actions/talentSearch/index';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  blurry: {
    filter: 'blur(4px)',
  },
  whiteOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: 'white',
    opacity: 0.5,
  },
  loaderOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 2,
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2),' +
      '0px 1px 1px 0px rgba(0,0,0,0.14),' +
      '0px 2px 1px -1px rgba(0,0,0,0.12)',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 167,
  },
}));

interface PaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => void;
}

const pagigationActionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
  })
);

function PaginationActions(props: PaginationActionsProps) {
  const classes = pagigationActionStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='First Page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='Previous Page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='Next Page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='Last Page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
// eslint-disable-next-line
const component = (props: any) => <div className={props.className}>{props.children}</div>;

/**
 *Table displaying all the talents we have received from the backend
 * who either excel at the searched skill or
 * match the skills of the employee we are looking for
 * @constructor
 *
 * @param { PMI_TalentData[] | undefined} data
 * @returns {div} with a list of table rows (with talent info)
 */
const SearchResultsTable: React.FC<{ data: PMI_TalentData[] | undefined }> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const searchMode = useSelector(state => state.talentSearch.searchMode);
  const { rowsPerPage } = useSelector(state => state.talentSearch.pagination);
  const metadataTalentSearch = useSelector(state => state.talentSearch.talentSearchResponse.data);

  const metadataTalentProfiles = useSelector(
    state => state.talentSearch.talentProfilesResponse.data
  );

  const { metadata } =
    searchMode === 'talentProfiles' ? metadataTalentProfiles : metadataTalentSearch;

  const classes = useStyles();

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) {
    setPage(newPage);
    searchMode === 'talentProfiles' ? allTalentProfilesSearch() : talentSearch();
  }

  const table = (
    <>
      <ListHeader setLoadingState={setIsLoading} />
      {props &&
        props.data &&
        props.data.map((searchResult, index) => (
          <TableRow key={searchResult.firstName + index} searchResult={searchResult} />
        ))}
      {props && !props.data ? (
        /* Placeholder telling the user there are no matches for his/her query */
        <Paper>
          <Grid item xs={12} style={{ width: '100%', padding: 50, marginTop: 10 }}>
            <Typography
              variant='h6'
              color={'primary'}
              align={'center'}
              style={{ marginBottom: 20 }}
            >
              There are no experts available for this search
            </Typography>
            <Typography align={'center'}>
              Try searching for another person, competency or with different search parameters
            </Typography>
          </Grid>
        </Paper>
      ) : null}
      <TablePagination
        rowsPerPageOptions={[]}
        colSpan={4}
        count={metadata.pagination.totalCount}
        component={component}
        rowsPerPage={rowsPerPage}
        page={metadata.pagination.currentPage - 1}
        onChangePage={handleChangePage}
        ActionsComponent={PaginationActions}
      />
    </>
  );

  return isLoading ? (
    <div className={classes.root}>
      <div className={classes.blurry}>{table}</div>
      <div className={classes.whiteOverlay} />
      <div className={classes.loaderOverlay}>
        <CircularProgress />
      </div>
    </div>
  ) : (
    table
  );
};

export default SearchResultsTable;
