import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const useStyles = makeStyles(() => ({
    roleSearchTerm: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 7,
        left: 140,
        fontSize: '8px',
        fontWeight: 'bold',
        color: 'white',
        borderRadius: '10px',
        padding: '4px 8px'
    },
    talentSearchTerm: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'absolute',
        top: 0,
        left: 0,
        fontSize: '8px',
        fontWeight: 'bold',
        color: 'white',
        borderRadius: '0 0 10px 0',
        paddingRight: '10px',
        height: '18px',
        width: '105px'
    }
}))

const terms = {
    ideal: 'IDEAL MATCH', 
    good: 'GOOD MATCH',
    notgood: 'NOT A GOOD MATCH'
}

const backgroundColors = {
    ideal: '#1B89EE',
    good: '#57A3E8',
    notgood: '#A9A9A9'
}

type MatchTermProps = {
    score: number,
    isFromRoleSearch: boolean,
}

function getMatchTerm(score: number): string{
    let matchterm = terms.good
    if (score <= 0.5) return terms.notgood
    if (score >= 0.8) return terms.ideal
    return matchterm;
}

function getBackgroundColor(score: number): string{
    let backgroundColor = backgroundColors.good
    if (score <= 0.5) return backgroundColors.notgood
    if (score >= 0.8) return backgroundColors.ideal
    return backgroundColor;
}

const MatchTerm = ({score, isFromRoleSearch}: MatchTermProps) => {
    const classes = useStyles();
    const backgroundColor = getBackgroundColor(score);
    return (
        <div style={{ backgroundColor: backgroundColor }} className={isFromRoleSearch ? classes.roleSearchTerm : classes.talentSearchTerm}>
            {getMatchTerm(score) === terms.ideal && <StarIcon style={{ margin: '0 3 1.5 0', height: 10, width: 10 }}/>}
            {getMatchTerm(score) === terms.good && <ThumbUpIcon style={{ marginRight: 3, height: 8, width: 8 }}/>}
            {getMatchTerm(score)}
        </div>
    ) 
}

export default MatchTerm;
