import React, { useState, useRef } from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

interface styleProp {
  isDisabled: boolean;
}

const PMITooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '25px',
    padding: '30px 25px',
    width: 325,
    maxWidth: 325,
    color: 'black',
    fontSize: '1em',
    boxShadow: `-webkit-box-shadow: 0px 6px 23px -6px rgba(0,0,0,0.74); 
                box-shadow: 0px 6px 23px -6px rgba(0,0,0,0.74);`,
  },
}))(Tooltip);

const useStyles = makeStyles<Theme, styleProp>((theme) => ({
  icon: {
    margin: '0',
    padding: '0',
    position: 'absolute',
    color: ({isDisabled}) => isDisabled ? 'lightgray' : theme.palette.primary.main,
    fontSize: '1.2em',
    transition: '.2s all',
    cursor: 'pointer',
    '&:hover': {
      color: ({isDisabled}) => isDisabled ? 'gray' : theme.palette.primary.dark,
    },
    '&:active': {
      color: ({isDisabled}) => isDisabled ? 'lightgray' : theme.palette.primary.main,
    },
  },
  messageClose: {
    color: '#a0a0a0',
    position: 'absolute',
    top: 10,
    right: 10,
    fontSize: '1.2em',
    transition: '.2s all',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.04)',
    },
  },
  sectionLink: {
    color: theme.palette.primary.main,
    display: 'inlineBlock',
  },
}));

interface props {
  position?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
  top?: number | string;
  bottom?: number | string;
  right?: number | string;
  left?: number | string;
  refs?: Array<{
    section: string;
    link: string;
  }>;
  section?: string;
  sectionLink?: string;
  style?:{};
  children: React.ReactNode;
  isDisabled?: boolean;
}

const InfoButton: React.FC<props> = ({ position, top, bottom, right, left, refs, style, children, isDisabled = false }) => {
  const classes = useStyles({isDisabled: isDisabled});
  const [showInfo, setShowInfo] = useState(false);
  const [toggleInfo, setToggleInfo] = useState(false);
  const infoButtonRef = useRef(null);

  const buttonStyle = {
    top: top || 0,
    right: right,
    bottom: bottom,
    left: left,
  };

  const handleShowInfo = () => {
    if (!toggleInfo) {
      setShowInfo(true);
      setToggleInfo(true);
    } else {
      setShowInfo(false);
      setToggleInfo(false);
    }
  };

  const handleHideInfo = () => {
    setShowInfo(false);
    setToggleInfo(false);
  };

  const infoToolTipContent = (
    <React.Fragment>
      {children}
      {refs && (
        <div>
          <div style={{ fontSize: '.9em' }}>
            <b>Read more:</b>
          </div>
          {refs.map((ref, i, arr) => {
            return (
              <>
                <Link to={'/user-manual/' + ref.link} className={classes.sectionLink}>
                  {ref.section}
                </Link>
                {arr.length - 1 !== i && <span>, </span>}
              </>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
  return (
    <div>
      <ClickAwayListener onClickAway={handleHideInfo}>
        <PMITooltip
          title={infoToolTipContent}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleHideInfo}
          open={showInfo}
          disableFocusListener
          disableHoverListener
          placement={position}
          interactive
        >
          <InfoIcon
            ref={infoButtonRef}
            style={{...buttonStyle, ...style}}
            className={classes.icon}
            onClick={() => handleShowInfo()}
          />
        </PMITooltip>
      </ClickAwayListener>
    </div>
  );
};

export default InfoButton;
