/* eslint @typescript-eslint/no-explicit-any: "off" */
import isEqual from 'lodash/isEqual';
import { Store, AnyAction } from 'redux';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';

interface ReduxiosHooks<R = any> {
  beforeRequest?: () => any;
  onSuccess?: (response: AxiosResponse<R>) => any;
  onError?: (error: Error) => any;
  onComplete?: () => any;
}

interface FlyingRequest {
  url: AxiosRequestConfig['url'];
  parameters: AxiosRequestConfig;
  promise: Promise<AxiosResponse>;
}

export const createReduxios = (store: Store, backend: AxiosInstance = axios) => {
  // since it's not working to store an unserializable object
  // into redux we've to store it somewhere else
  let flyingRequests: FlyingRequest[] = [];

  return <R = any>(
    request: AxiosRequestConfig,
    actions?: ReduxiosHooks<R>
  ): Promise<AxiosResponse<R>> => {
    if (!request)
      throw new Error('`AxiosRequestConfig` must be provided as first parameter to reduxios');

    if (!request.url)
      throw new Error('`url` property must be provided to the request parameter of reduxios');

    const getFlyingRequest = (): FlyingRequest | undefined =>
      flyingRequests.filter(
        flyingRequest =>
          request.url === flyingRequest.url && isEqual(request, flyingRequest.parameters)
      )[0];

    const removeFlyingRequest = () => {
      flyingRequests = flyingRequests.filter(
        flyingRequest =>
          request.url !== flyingRequest.url || !isEqual(request, flyingRequest.parameters)
      );
    };

    const setFlyingRequest = (promise: FlyingRequest['promise']) => {
      if (getFlyingRequest()) removeFlyingRequest();

      flyingRequests.push({ url: request.url, parameters: request, promise });
    };

    const flyingRequest = getFlyingRequest();

    // skip this request if there's already a request in flight for this URL and parameters
    if (flyingRequest) return flyingRequest.promise;

    // if there's no actions object passed run the request without any redux
    if (!actions) {
      const requestPromise = backend.request<R>(request).finally(() => removeFlyingRequest());

      setFlyingRequest(requestPromise);

      return requestPromise;
    }

    const reduxiosDispatcher = <P = AxiosResponse<R> | Error | undefined>(
      actionCreator: (args: P) => AnyAction,
      passToActionCreator?: P
    ) => {
      const action = actionCreator(passToActionCreator as P);

      action && store.dispatch(action);
    };

    if (actions.beforeRequest) reduxiosDispatcher(actions.beforeRequest);

    const success = (response: AxiosResponse<R>) => {
      actions.onSuccess && reduxiosDispatcher(actions.onSuccess, response);

      return response;
    };

    const noSuccess = (error: any) => {
      actions.onError && reduxiosDispatcher(actions.onError, error);

      return error;
    };

    const requestPromise = backend
      .request<R>(request)
      .then(success, noSuccess)
      .catch(noSuccess)
      .finally(() => {
        removeFlyingRequest();
        actions.onComplete && reduxiosDispatcher(actions.onComplete);
      });

    setFlyingRequest(requestPromise);

    return requestPromise;
  };
};
