import React, { CSSProperties, useState } from 'react';
import { useSelector } from '../../reduxHooks';

import { SearchModes } from '../../reducers/talentSearch/types';

import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

import { emphasize, makeStyles, Theme, useTheme } from '@material-ui/core';

import { components, OptionType } from './Autocomplete-components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

interface AutocompleteProps {
  label?: string;
  options: { label: string; value: string }[] | undefined;
  value?: string[];
  onChange?: (value: string[]) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = props => {
  const classes = useStyles();
  const theme = useTheme();

  const { options, label, value, onChange } = props;

  const [isFocused, setIsFocused] = useState(false);

  const searchMode = useSelector(state => state.talentSearch.searchMode);

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  const shouldLabelShrink = (value && Array.isArray(value) && value.length > 0) || isFocused;

  const handleChange = (value: ValueType<OptionType>) => {
    const selected = value as OptionType[];

    if (value && value !== null && onChange) {
      const filters = selected.map(filter => filter.value);

      onChange(filters);
    }

    if (value === null && onChange) {
      onChange([]);
    }
  };

  return (
    <div className={classes.root}>
      <Select
        classes={classes}
        styles={selectStyles}
        isMulti={searchMode === SearchModes.talentProfiles ? false : true}
        options={options}
        TextFieldProps={{
          label: label || 'Label',
          InputLabelProps: {
            shrink: shouldLabelShrink,
          },
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        menuIsOpen={isFocused}
        placeholder=''
        menuPlacement='auto'
        components={components}
        onChange={value => handleChange(value)}
        value={value?.map(option => ({
          label: option,
          value: option,
        }))}
      />
    </div>
  );
};

export default Autocomplete;
