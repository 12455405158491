import React from 'react';
import InfoButton from '../InfoButton';

interface props {
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  position?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  style?: {};
}

const ProfileMathingResultsInfo: React.FC<props> = ({
  top,
  right,
  bottom,
  left,
  position,
  style,
}) => {
  return (
    <InfoButton
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      position={position}
      style={style}
      refs={[{ section: 'Profile Matching Results', link: 'ts-pm-results' }]}
    >
      <p>Click on one talent to expand the section</p>
      <p>
        General Details include competencies matching the search and latest project
        experiences.  
      </p>
      <p>
        link to the Skill Profiling Dashboard and direct contact supervisor via email or Teams is available
      </p>
    </InfoButton>
  );
};

export default ProfileMathingResultsInfo;
