import React, { useState, useCallback } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Theme,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { PMI_TalentData } from '../../actions/talentSearch';
import TalentProfilePicture from '../Images/TalentProfilePicture';
import ExpansionPanelContent from './ExpansionPanelContent';
import { sortSkillsByMostProficient } from '../../utils/sortSkills';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:nth-child(2)::before': {
      display: 'none',
    },
  },
  panelContent: {
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(15),
  },
  profileColumn: {
    width: 50,
    marginRight: 15,
    [theme.breakpoints.down(400)]: {
      display: 'none',
    },
  },
  nameColumn: {
    minWidth: '40%',
    marginRight: 25,
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
  },
  gridColumn: {
    height: '100%',
    width: '50%',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    marginRight: 6,
  },
  skillsColumn: {
    flexGrow: 2,
    width: 0,
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: 25,
    borderLeft: '1px solid #DEDEDE',
    paddingLeft: 15,
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableCell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  chip: {
    lineHeight: '1.5rem',
  },
  expansionPanelDetails: {
    padding: 0,
  },
  skillsRow: {
    textTransform: 'uppercase',
    color: '#39aefe',
    fontWeight: 500,
  },
}));

/**
 *Handles the talent's visible skills on the expandable list
 *
 * @param {PMI_TalentData} talent
 * @param {number} parentWidth
 * @returns {div} Container with sorted skills
 */
const RenderSkillSetChips = (
  talent: PMI_TalentData,
  classes: ReturnType<typeof useStyles>,
  parentWidth: number
) => {
  const { competency } = talent;

  const skills = competency && competency.length > 3 ? competency.slice(0, 3) : competency; // It looks bad if all skills are shown

  // Return different skills depending on where we're mapping them to
  const mapSkills = (key: string, label: string, skillsArrayLength: number, index: number) => (
    <Typography variant='body2' gutterBottom className={classes.chip} key={key}>
      {label}
    </Typography>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography className={classes.skillsRow}>Main Competencies</Typography>
      {skills &&
        sortSkillsByMostProficient(skills).map((skill, index: number) =>
          mapSkills(`skill${skill.name}`, skill.name, skills.length, index)
        )}
    </div>
  );
};

interface TableRowProps {
  searchResult: PMI_TalentData;
}

/** Displays matching talent's info (first, prominent talent in profile match and project recommendations) **/
/**
 * The matched talent corresponds to the person whose skills we want to seek on other employees, or
 * the talent of the person we're looking a recommended project for.
 *@constructor
 *
 * @returns {ExpansionPanel} with talent's info
 */
const ProfileTableRow: React.FC<TableRowProps> = props => {
  const talent = props.searchResult;
  const [expanded, setExpanded] = useState(false);
  const [width, setWidth] = useState(0);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setWidth(node.offsetWidth);
    }
  }, []);

  const classes = useStyles();

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <tbody>
      <tr>
        <td>
          <ExpansionPanel
            key={talent.supplyKey}
            expanded={expanded}
            onChange={toggleExpanded}
            classes={{ root: classes.root }}
          >
            <ExpansionPanelSummary
              classes={{ content: classes.panelContent }}
              expandIcon={<ExpandMoreIcon />}
              style={{ margin: '0 24px', padding: 0 }}
            >
              <Grid item xs={12} md={4} className={classes.gridColumn}>
                <div className={classes.profileColumn}>
                  {/******* Talent info goes here *******/}
                  <Grid container justify='center' alignItems='center'>
                    <TalentProfilePicture
                      placeholderStyle={{ position: 'absolute' }}
                      talent={talent}
                    />
                  </Grid>
                </div>
                <div className={classes.nameColumn}>
                  <Typography variant='h6' className={classes.tableCell}>
                    {`${talent.firstName} ${talent.lastName}`}
                  </Typography>
                  <Typography variant='body2'>
                    {talent.city} Office, {talent.function} Function Group
                  </Typography>
                </div>
                {/*******  End Talent info *******/}
              </Grid>
              <Grid ref={measuredRef} className={classes.skillsColumn}>
                {RenderSkillSetChips(talent, classes, width)}
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
              {expanded && <ExpansionPanelContent talent={talent} />}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </td>
      </tr>
    </tbody>
  );
};

export default ProfileTableRow;
