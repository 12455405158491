import { combineReducers } from 'redux';
import { SherlockActions } from '../../actions';
import { ACTIONS, OpenRolesActions, SortObject, SetSort } from '../../actions/openRoles';
import { 
  OpenRolesResponse, 
  Autocomplete, 
  Pagination, 
  XAIresponse, 
  BookedDemands
} from './types';
import { SearchFilters } from '../../actions/openRoles';
import { isArray } from 'util';
import Cookie from 'cookie-universal'

export * from './types';

const cookies = Cookie()

export const defaultFilters: SearchFilters = {
  levelFrom: 13,
  levelTo: 4,
  isChargeable: '',
  isSold: '',
  roleStatus: [],
  projectGeographicUnit: [],
  roleTalentSegment: [],
  roleLocation: [],
  primarySkills: [],
  locationPreference: [],
  demandSpecialist: [],
  active: {},
};

const searchFilters = (searchFilters: SearchFilters = defaultFilters, action: OpenRolesActions) => {
  switch (action.type) {
    case ACTIONS.RESET_FILTERS:
      return action.payload;
    case ACTIONS.SET_FILTERS: {
      const payload = action.payload;
      const arrays: any = {};

      Object.keys(payload).forEach(i => {
        const value = payload[i as keyof typeof payload];

        if (isArray(value) && value[0]) {
          arrays[i] = value;
        }
      });

      return {
        ...payload,
        active: {
          ...(payload.isChargeable && { isChargeable: payload.isChargeable }),
          ...(payload.isSold && { isSold: payload.isSold }),
          ...(payload.levelTo && payload.levelTo > 4 && { levelTo: payload.levelTo }),
          ...(payload.levelFrom && payload.levelFrom < 13 && { levelFrom: payload.levelFrom }),
          ...arrays,
        },
      };
    }
    default:
      return searchFilters;
  }
};

const openRolesResponse = (
  state: OpenRolesResponse = {
    data: null,
    isLoading: false,
    isLoaded: false,
  },
  action: OpenRolesActions
): OpenRolesResponse => {
  switch (action.type) {
    case ACTIONS.FETCH_OPEN_ROLES_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTIONS.FETCH_OPEN_ROLES:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        data: action.payload,
      };
    case ACTIONS.FETCH_OPEN_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const xAIResponse = (
  state: XAIresponse = {
    data: [],
  },
  action: OpenRolesActions
): XAIresponse => {
  switch (action.type) {
    case ACTIONS.INIT_XAI_DATA: {
      if (action.payload === state.data.length) {
        return {
          ...state,
          data: [],
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case ACTIONS.FETCH_XAI_DATA:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    default:
      return state;
  }
};

const bookedDemands = (
  state: BookedDemands = {demands: new Set()},
  action: OpenRolesActions
): BookedDemands => {
  switch(action.type){
    case ACTIONS.ADD_BOOKED_DEMANDS: {
      const bookedDemand = action.payload;
      const demandsSet = state.demands;
      demandsSet.add(bookedDemand);


      if(!cookies.get('bookedDemand')){
        const dateNow: Date = new Date();  
        const date = new Date(dateNow.getTime() + 2*60*60000);
        cookies.set('bookedDemand', [...demandsSet], { path: '/', expires: date })
        cookies.set('bookExpiry', date, { path: '/', maxAge: 2*60*60 })
      } else {
        const date = cookies.get("bookExpiry")
        cookies.set('bookedDemand', [...demandsSet], { path: '/', expires: new Date(date) })
      }

      return {
        ...state, 
        demands: demandsSet
      };
    }

    case ACTIONS.SET_BOOKED_DEMANDS: {
      const demandsSet = new Set(action.payload);
      return{
        ...state, 
        demands: demandsSet
      }
    }

    default:
      return state;
  }
}

const transformDate = (dateString: string) => {
  const fullDateString = dateString + " 09:00:00";
  const date = new Date(fullDateString);
  const day = date.getUTCDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear()
  return `${day} ${month} ${year}`
} 

const employeeSearch = (
  state:any = {
    employee: {}
  }, 
  action:any) => {
  switch (action.type) {
    case ACTIONS.FETCH_EMPLOYEE_INFO: {
      const data = action.payload
      const employee = {
        master: [{
          firstName: data.firstName,
          lastName: data.lastName,
          jobTitle: data.jobTitle,
        }],
        location: [{
          city: data.city,
        }],
        contact: [{
          email: data.email,
          managerEmail: data.managerEmail,
        }],
        ...data,
        fromDate: transformDate(data.fromDate),
        toDate: transformDate(data.toDate),
      }
      state.employee = employee;
      return state;
    }
    default:
      return state;
  }
};

const autoComplete = (
  state: Autocomplete = {
    selectedFilter: 'placeholder',
    filterSelectItems: [
      { value: 'placeholder', description: 'Choose Filter', disabled: true },
      { value: 'demandSubFunctions', description: 'Group​' },
      { value: 'roleNames', description: 'Role Name' },
      { value: 'projectNames', description: 'Project Name' },
    ],
    selectedFilterItem: 'placeholder',
    value: null,
    data: {},
  },
  action: SherlockActions
): Autocomplete => {
  switch (action.type) {
    case ACTIONS.ON_INPUT_CHANGE:
      return { ...state, value: action.payload };
    case ACTIONS.ON_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
        selectedFilterItem: state.filterSelectItems.find(item => item.value === action.payload),
      };
    case ACTIONS.FETCH_AUTOCOMPLETE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.subjectMatter]: action.payload.data.items.sort((a, b) => a < b ? -1 : 1).map(i => ({
            label: i,
            value: i,
          })),
        },
      };
    default:
      return state;
  }
};

const pagigation = (
  state: Pagination = {
    currentPage: 0,
    rowsPerPage: 5,
  },
  action: OpenRolesActions
) => {
  switch (action.type) {
    case ACTIONS.SET_PAGE: {
      const { currentPage } = state;

      if (currentPage !== action.payload) {
        return { ...state, currentPage: action.payload };
      }

      return state;
    }
    default:
      return state;
  }
};

const rolesSorting = (
  state: SortObject = {
    orderBy: 'roleName',
    order: 'asc',
  },
  action: SetSort
) => {
  switch (action.type) {
    case ACTIONS.SET_SORT: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default combineReducers({
  openRolesResponse,
  xAIResponse,
  autoComplete,
  pagigation,
  rolesSorting,
  searchFilters,
  employeeSearch,
  bookedDemands,
});
