import { sherlockBackend } from '../../store';
import {
  ACTIONS,
  AutoCompletePayload,
  FetchDelegateUserPayload,
  AddDelegatedUserPayload,
  RevokeDelegationsPayload,
  FetchUserDelegationRolesPayload,
  ExtendDelegatedUserPayload,
} from './types';
import { addNotification } from '../notification';

export * from './types';

export const fetchAutocomplete = async () =>
  sherlockBackend<AutoCompletePayload>(
    {
      method: 'get',
      url: '/access_control/users?getAll=true ',
    },
    {
      onSuccess: response => {
        return {
          type: ACTIONS.FETCH_AUTOCOMPLETE,
          payload: response.data,
        };
      },
    }
  );

export const fetchUserDelegationRoles = async () =>
  sherlockBackend<FetchUserDelegationRolesPayload>(
    {
      method: 'get',
      url: '/access_control/delegate/delegation_roles',
    },
    {
      onSuccess: response => {
        return {
          type: ACTIONS.FETCH_ROLES,
          payload: response.data,
        };
      },
    }
  );

export const fetchDelegateUser = async () =>
  sherlockBackend<FetchDelegateUserPayload>(
    {
      method: 'get',
      url: 'access_control/my_delegations',
    },
    {
      onSuccess: response => {
        return {
          type: ACTIONS.FETCH_DELEGATIONS,
          payload: response.data,
        };
      },
    }
  );

export const addUser = async (targetEnterpriseId: string) => {
  return sherlockBackend<any>({
    method: 'post',
    url: `/access_control/users?current_user=${targetEnterpriseId}`,
    data: {
      user_id: targetEnterpriseId,
    },
  }).then(
    res => {
      if (res.status === 200) {
        addNotification('success', res['data']['metadata']['serverMessages'][0].message);
      }

      fetchAutocomplete();

      return res;
    },
    () => {
      addNotification('error', "Internal server error! User hasn't been created..");
    }
  );
};

export const addDelegatedUser = async (targetEnterpriseId: string, roleId: string) => {
  await sherlockBackend<AddDelegatedUserPayload>({
    method: 'post',
    url: `/access_control/delegate`,
    data: {
      user_id: targetEnterpriseId,
      role_id: roleId,
    },
  })
    .then(res => {
      if (res.status === 200) {
        // @ts-ignore
        addNotification('success', res['data']['metadata']['serverMessages'][0].message);
      }

      fetchDelegateUser();
    })
    .catch(err => {
      addNotification('error', 'Something went wrong. Try again later..');
    });
};

export const revokeDelegations = async (enterpriseId: string, roleID: string) => {
  return sherlockBackend<RevokeDelegationsPayload>({
    method: 'delete',
    url: `access_control/delegate`,
    params: {
      target_user_id: enterpriseId,
      target_role: roleID,
    },
  }).then(
    res => {
      if (res.status === 200) {
        // @ts-ignore
        addNotification('success', res['data']['metadata']['serverMessages'][0].message);
      }

      fetchDelegateUser();
    },
    () => {
      addNotification('error', 'Something went wrong. Try again later..');
    }
  );
};

export const revokeUsers = async (enterpriseId: string) => {
  return sherlockBackend<RevokeDelegationsPayload>({
    method: 'delete',
    url: `access_control/users/${enterpriseId}`,
  }).then(
    res => {
      if (res.status === 200) {
        // @ts-ignore
        addNotification('success', res['data']['metadata']['serverMessages'][0].message);
      }

      fetchAutocomplete();
      fetchDelegateUser();
    },
    () => {
      addNotification('error', 'something went wrong. Try again later..');
    }
  );
};

export const extendUser = async (userID: string, roleID: string) => {
  return sherlockBackend<ExtendDelegatedUserPayload>({
    method: 'patch',
    url: '/access_control/delegate/extend',
    data: {
      user_id: userID,
      role_id: roleID,
    },
  })
    .then(res => {
      if (res.data.user_extended) {
        // @ts-ignore
        addNotification('success', res['data']['metadata']['serverMessages'][0].message);
      }

      fetchAutocomplete();
      fetchDelegateUser();
    })
    .catch(err => {
      addNotification('error', 'Unable to extend user.');
    });
};
