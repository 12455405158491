import { sherlockBackend, store } from '../../store';
import { ACTIONS } from './types';
import { fetchTalentProfilePictures } from '../profilePictures/index';
import { OpenRolesPayload, SortObject } from '../openRoles';

export * from './types';

export const fetchProjectRecommendation = async (enterpriseID?: string) => {
  const projectRecommendationsState = store.getState().projectRecommendations;
  const filters = projectRecommendationsState.filters;
  const sorting = projectRecommendationsState.sorting;

  return sherlockBackend<OpenRolesPayload>(
    {
      method: 'get',
      url: '/roles/project',
      params: {
        sortBy: sorting.orderBy,
        sortAsc: '' + (sorting.order === 'asc'),
        ...filters,
        id: enterpriseID ? enterpriseID : filters.enterpriseID,
      },
    },
    {
      beforeRequest: () => ({ type: ACTIONS.FETCH_PR_INIT }),
      onSuccess: response => {
        if (enterpriseID) {
          fetchTalentProfilePictures(response.data.referenceUser).then(res => {
            store.dispatch({
              type: ACTIONS.SET_FILTER,
              payload: { enterpriseID },
            });
          });
        }

        return {
          type: ACTIONS.FETCH_PR,
          payload: response.data,
        };
      },
      onError: () => ({
        type: ACTIONS.FETCH_PR_FAILURE,
      }),
    }
  );
};

export const setPage = (page: number) => {
  store.dispatch({
    type: ACTIONS.SET_PR_PAGE,
    payload: page,
  });
  fetchProjectRecommendation();
};

export const setFilter = (filters: object) => {
  store.dispatch({
    type: ACTIONS.SET_FILTER,
    payload: filters,
  });
  setPage(0);
};

export const onSort = (sort: SortObject) => {
  store.dispatch({
    type: ACTIONS.SET_SORT,
    payload: sort,
  });
  fetchProjectRecommendation();
};
