import React from 'react';
import InfoButton from '../InfoButton';

interface props {
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  position?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  style?: {};
}

const TalentSearchBarInfo: React.FC<props> = ({ top, right, bottom, left, position, style }) => {
  return (
    <InfoButton
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      position={position}
      style={style}
      refs={[{ section: 'Talent Search', link: 'ts-index' }]}
    >
      <p>
        Search with <b>keywords</b> or full text by using the ‘Autocomplete’ button
      </p>
    </InfoButton>
  );
};

export default TalentSearchBarInfo;
