import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { theme } from './theme';
import { store } from './store';
import App from './containers/App';
import { ensureLoggedIn } from './modules/auth';
import './index.css';

console.log(`ENV: ${process.env.REACT_APP_ENVIRONMENT}`)
ensureLoggedIn();

const iconVariant = {
  success: <CheckCircleIcon style={{ fontSize: 24, marginRight: 16 }}></CheckCircleIcon>,
  info: <InfoIcon style={{ fontSize: 24, marginRight: 16 }}></InfoIcon>,
  warning: <WarningIcon style={{ fontSize: 24, marginRight: 16 }}></WarningIcon>,
  error: <ErrorIcon style={{ fontSize: 24, marginRight: 16 }}></ErrorIcon>,
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider iconVariant={{ ...iconVariant }} preventDuplicate>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
