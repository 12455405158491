import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import { TalentSearchPayload } from '../../actions/talentSearch/types';
import { getHitHighLightStyles } from './style';
import { TalentSearchSpec } from './types';

function includes(collection: string, value: string) {
  return collection.toLowerCase().includes(value.toLowerCase());
}

function buildSpecs(talentSearchResponse: TalentSearchPayload) {
  const termsDetected = get(talentSearchResponse, ['termsDetected'], []);
  const specs: TalentSearchSpec[] = [];

  termsDetected.forEach(detected => {
    if (detected && detected.foundAtCharacter !== undefined && detected.foundAtCharacter !== -1) {
      const { keywordStyle, labelStyle } = getHitHighLightStyles(detected.category);

      specs.push({
        keyword: detected.term.toString(),
        className: keywordStyle,
        label: labelStyle,
      });
    }
  });

  return uniqBy(specs, 'keyword');
}

function keep_alpha_numeric_and_space(string: string) {
  const alpha_numeric = Array.from(
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 '
  );

  const json_string = JSON.stringify(string);

  let filterd_string = '';

  for (let i = 0; i < json_string.length; i++) {
    const char = json_string[i];
    const index = alpha_numeric.indexOf(char);

    if (index > -1) {
      filterd_string += alpha_numeric[index];
    }
  }

  return filterd_string;
}

export { includes, buildSpecs, keep_alpha_numeric_and_space };
