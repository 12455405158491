/*
These codes can be simplified once our backend is fixed and returns the correct stuff.
*/
import { TalentSearchPayload } from '../../actions/talentSearch';
import { includes, buildSpecs, keep_alpha_numeric_and_space } from './common';
import escapeRegExp from 'lodash/escapeRegExp';

type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R;
type SpanEl = Modify<
  HTMLSpanElement,
  {
    childNodes: NodeListOf<
      Modify<ChildNode, { classList: Pick<HTMLSpanElement, 'classList'>['classList'] }>
    >;
  }
>;

function getOriginalTextsForInput(collection: string, value: string) {
  const pattern = new RegExp(
    '(^|\\W)' + escapeRegExp(value.trim()) + '($|\\W)', // only match full string.
    'gi'
  );

  const result = collection.trim().match(pattern);

  if (Array.isArray(result) && result.length > 0) {
    // remove ', . 'these chars and duplicates;
    const matches = [...new Set(result.map(text => keep_alpha_numeric_and_space(text)))].map(
      text => {
        if (text.trim() === value.trim()) {
          return {
            text,
            fullMatch: true,
          };
        } else {
          return {
            text,
            fullMatch: false,
          };
        }
      }
    );

    return matches;
  }

  return null;
}

function getNewTextForInput(
  el: SpanEl,
  spec: {
    fullMatch: boolean;
    text: string;
  },
  className: string,
  label?: string
) {
  const { fullMatch, text } = spec;
  let hasBeenHighlighted = false;

  if (el.childNodes.length > 0) {
    Array.from(el.childNodes).forEach(node => {
      if (
        node &&
        node.textContent &&
        node.textContent.replace(/\s+/g, ' ').trim() === text.trim()
      ) {
        if (fullMatch && node.classList && node.classList.length > 0) {
          // fullMatch means the text returns from the backend is strictly equels to the "text" we are trying to replace.
          // But, that's too late, cause it has been highlighted already with a different category with a lower match rate.
          // So we remove the exsiting category and apply a new one.
          while (node && node.classList && node.classList.length > 0) {
            // @ts-ignore
            node.classList.remove(node.classList.item(0));
          }

          node.classList.add(className);
        }

        hasBeenHighlighted = true;
      } else {
        if (
          node &&
          node.textContent &&
          getOriginalTextsForInput(node.textContent, text) &&
          node.classList &&
          node.classList.length > 0
        ) {
          // Backend returns duplicate "text" for us to highlight, this is to prevent that.
          hasBeenHighlighted = true;
        }
      }
    });
  }

  if (hasBeenHighlighted) {
    return el.innerHTML;
  }

  /* const labelText = (label && ` <span class='hit-highlight-label ${label}'></span>`) || '';

  const highLightedText = ` <span class='${className}'>${text}${labelText}</span>`;
  const pattern = new RegExp(escapeRegExp(text), 'g'); // Case sensitive, cause we don't want to ruin the original text.
  const newText = el.innerHTML.replace(pattern, highLightedText);

  return newText; */
  return el.innerHTML;
}

function getHighLightTalentSearchInput(talentResponse: TalentSearchPayload) {
  const {
    query: { raw },
  } = talentResponse;

  const newInput = document.createElement('span') as SpanEl;

  newInput.innerHTML = raw;

  const specs = buildSpecs(talentResponse);

  specs.forEach(({ keyword, className, label }) => {
    if (includes(raw, keyword)) {
      const originaltexts = getOriginalTextsForInput(raw, keyword);

      if (Array.isArray(originaltexts)) {
        originaltexts.forEach(text => {
          const newText = getNewTextForInput(newInput, text, className, label);

          newInput.innerHTML = newText;
        });
      }
    }
  });

  if (newInput.innerHTML) {
    return `${newInput.innerHTML}&nbsp;`;
  }

  return '';
}

export { getHighLightTalentSearchInput };
