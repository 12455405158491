import React from 'react';

import EmailLink from './EmailLink';
import { PMI_TalentData } from '../../actions/talentSearch/types';
import getTalentInfoToEmailFormat from '../../utils/getTalentInfoToEmailFormat';
import { recommendTalentToColleague } from '../../modules/applicationInsights/analytics';

interface Props {
  talent: PMI_TalentData;
  talentSearchResponse: any;
  isProjectRecommendations?: boolean;
}

const RecommendTalentLink: React.FC<Props> = ({
  talent,
  talentSearchResponse,
  isProjectRecommendations,
  children,
}) => {
  const subject = 'I think this talent will be interesting for your project';
  const body = getTalentInfoToEmailFormat(talent, talentSearchResponse, isProjectRecommendations);
  const link = `?subject=${subject}&body=${body}`;

  return (
    <EmailLink forContactTalent={false} onClick={() => recommendTalentToColleague()} link={link}>
      {children}
    </EmailLink>
  );
};

export default RecommendTalentLink;
