import React from 'react';
import InfoButton from '../InfoButton';

interface props {
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  position?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
  style?:{};
}

const TalentSearchFiltersInfo: React.FC<props> = ({ top, right, bottom, left, position, style }) => {
  return (
    <InfoButton
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      position={position}
      style={style}
      refs={[{section:'Talent Search Filters', link:'ts-filters'}]}
    >
      <p>Available Filters:</p>
      <p>
        <b>1.</b> Availability (FTE) and dates
      </p>
      <p>
        <b>2.</b> Locations (cities / countries)
      </p>
      <p>
        <b>3.</b> Function Groups (e.g. Smoke-Free Products)
      </p>
      <p>
        <b>4.</b> Sub-functions (e.g. Insights)
      </p>
    </InfoButton>
  );
};

export default TalentSearchFiltersInfo;
