import React, { useState } from 'react';
import { Theme, Paper, Typography, IconButton, Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SherlockIcon from '../../Icons';
import FilterPopover from './FilterPopover';
import { useSelector } from '../../../reduxHooks';
import {
  setTalentSearchFilters,
  talentSearch,
  setTalentProfileSearchFilters,
  resetTalentProfileSearchFilters,
  allTalentProfilesSearch,
} from '../../../actions/talentSearch';
import { defaultFilters, SearchModes } from '../../../reducers/talentSearch';
import clsx from 'clsx';
import Chips from '../../../components/Filter/Chips';
import moment from 'moment';
import Autocomplete from '../../Filter/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import TalentSearchFiltersInfo from '../../InfoButton/buttons/TalentSearchFiltersInfo';
import InfoContainer from '../../InfoButton/InfoContainer';
import ProfileMathingResultsInfo from '../../InfoButton/buttons/ProfileMathingResultsInfo';
import TalentSearchResultsInfo from '../../InfoButton/buttons/TalentSearchResultsInfo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px 4px 0 0',
  },
  listHeaderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),
  },
  listHeaderTopRow: {
    paddingTop: theme.spacing(3),
  },
  tableTitle: {
    fontWeight: 500,
  },
  actionButtonWrapper: {
    display: 'flex',
    marginTop: -5,
    marginRight: -5,
  },
  actionButton: {
    width: 40,
    height: 40,
    padding: 0,
  },
  actionButtonIcon: {
    width: '0.8em !important',
  },
}));

interface Column {
  key: string;
  className?: string;
}

interface ListHeaderProps {
  setLoadingState: (isLoading: boolean) => void;
}

/**
 * Header for the popover filter
 *
 * @returns {Paper} Container with chips showing the set filters
 *
 */
const ListHeader: React.FC<ListHeaderProps> = props => {
  const [filterPopoverAnchor, setFilterPopoverAnchor] = useState<EventTarget & HTMLButtonElement>();
  const searchFilters = useSelector(state => state.talentSearch.searchFilters.active);
  const [filterTalentProfile, setFilterTalentProfile] = useState<string[] | undefined>();

  const searchMode = useSelector(state => state.talentSearch.searchMode);
  const talentSearchMode = useSelector(state => state.talentSearch.talentSearchResponse.searchMode);

  const talentProfileFilters = useSelector(
    state => state.talentSearch.talentProfilesResponse.filter
  );

  const isModeTalentSearch = talentSearchMode === SearchModes.talentSearch;
  const isModeTalentProfile = searchMode === SearchModes.talentProfiles;
  const classes = useStyles();

  const autocomplete = useSelector(state => state.talentSearch.autocomplete);
  const { userNames: consumersAutocomplete } = autocomplete;

  const filtersCopy = { ...searchFilters };

  if (filtersCopy.dateTo && filtersCopy.dateFrom && filtersCopy.availabilitypercent) {
    filtersCopy.dateTo = moment(filtersCopy.dateTo).format('DD/MM/YYYY');
    filtersCopy.dateFrom = moment(filtersCopy.dateFrom).format('DD/MM/YYYY');
    filtersCopy.availability = `${filtersCopy.availabilitypercent} (${filtersCopy.dateFrom} - ${filtersCopy.dateTo})`;
  } else {
    delete filtersCopy.dateTo;
    delete filtersCopy.dateFrom;
    delete filtersCopy.availabilitypercent;
  }

  const setTalentFilter = () => {
    if (filterTalentProfile !== undefined) {
      setTalentProfileSearchFilters(filterTalentProfile);
      allTalentProfilesSearch();
    }
  };

  const resetTalentFilter = () => {
    resetTalentProfileSearchFilters();
    allTalentProfilesSearch();
  };

  return (
    <Paper classes={{ root: classes.root }}>
      <div className={clsx(classes.listHeaderRow, classes.listHeaderTopRow)}>
        {isModeTalentSearch && (
          <InfoContainer>
            <Typography className={classes.tableTitle} variant='h6'>
              Talent Search Results
            </Typography>

            <TalentSearchResultsInfo
              top={3}
              right={-20}
              position={'top-start'}
            />
          </InfoContainer>
        )}
        {!isModeTalentSearch && (
          <InfoContainer>
            <Typography className={classes.tableTitle} variant='h6'>
              Profile Matching Results
            </Typography>
            <ProfileMathingResultsInfo top={4} right={-20} position={'top-start'} />
          </InfoContainer>
        )}
        {isModeTalentProfile ? (
          <div style={{ display: 'inline-flex', minWidth: 400 }}>
            <Autocomplete
              label='Name'
              value={filterTalentProfile !== [''] ? filterTalentProfile : undefined}
              onChange={value => setFilterTalentProfile(value)}
              options={
                consumersAutocomplete &&
                consumersAutocomplete.items.map(filter => ({ value: filter, label: filter }))
              }
            />
            <Button color='secondary' onClick={setTalentFilter}>
              SEARCH
            </Button>
            {talentProfileFilters ? (
              <Button color='secondary' onClick={resetTalentFilter}>
                RESET
              </Button>
            ) : (
              <Button disabled style={{ color: '#808080' }}>
                RESET
              </Button>
            )}
          </div>
        ) : (
          <div className={classes.actionButtonWrapper}>
            <InfoContainer>
              {' '}
              <Tooltip title='Filter search results' placement={'top'} enterDelay={300}>
                <IconButton
                  classes={{ root: classes.actionButton }}
                  aria-label='Filter search results'
                  onClick={e => setFilterPopoverAnchor(e.currentTarget)}
                >
                  <SherlockIcon iconClasses={classes.actionButtonIcon}>
                    <FilterListIcon />
                  </SherlockIcon>
                </IconButton>
              </Tooltip>
              <TalentSearchFiltersInfo top={10} left={35} position={'bottom-end'} />
            </InfoContainer>

            <FilterPopover
              setLoadingState={props.setLoadingState}
              anchorEl={filterPopoverAnchor}
              onClose={() => setFilterPopoverAnchor(undefined)}
            />
          </div>
        )}
      </div>
      <div className={classes.listHeaderRow}>
        <div>
          <Chips
            namesMap={{
              location: 'LOCATION',
              function: 'FUNCTION GROUPS',
              availability: 'AVAILABILITY',
              subFunction: 'SUB FUNCTIONS',
            }}
            filters={filtersCopy}
            defaults={defaultFilters}
            setFilters={async (filters: any, reset?: string) => {
              if (reset) {
                searchFilters[filters.name] = undefined;

                if (Object.keys(filters)[0] === 'availability') {
                  removeAvailability();
                }

                setTalentSearchFilters(filters);
              }

              props.setLoadingState(true);
              setTalentSearchFilters(filters);
              await talentSearch();
              props.setLoadingState(false);

              function removeAvailability() {
                if (!filters.availability) {
                  // eslint-disable-next-line require-atomic-updates
                  filters.dateTo = undefined;
                  // eslint-disable-next-line require-atomic-updates
                  filters.dateFrom = undefined;
                  // eslint-disable-next-line require-atomic-updates
                  filters.availabilitypercent = undefined;
                  delete filters.availability;
                }
              }
            }}
          />
        </div>
      </div>
    </Paper>
  );
};

export default ListHeader;
