/**
 * The authentication of this project is written manually but could be done
 * with a microsoft package called adal.js. Have a look here:
 * https://github.com/AzureAD/azure-activedirectory-library-for-js
 * And here's a sample on how to use it:
 * https://github.com/Azure-Samples/active-directory-javascript-singlepageapp-dotnet-webapi/blob/master/TodoSPA/App/Scripts/app.js
 */

 import { store } from '../../store';
 import {
   Session,
   login as loginAction,
   logout as logoutAction,
   ACTIONS as AUTHACTIONS,
 } from '../../actions/auth';
//  import { addNotification } from '../../actions/notification';
 import parseJwt from './parseJwt';
 import authConfig from './authConfig';
 
 const localStorageKey = 'auth';
 
 let tokenRefreshIntervallId: NodeJS.Timeout | null = null;
 
 interface CallbackSessionData {
   error?: string;
   access_token?: string;
   expires_in?: string;
   id_token?: string;
   scope?: string;
   session_state?: string;
   token_type?: string;
 }
 
 const handleLoginCallback = (): boolean => {
   const hash = window.location.hash;
 
   const parsedHash: CallbackSessionData = hash
     ? hash
         .substr(1)
         .split('&')
         .map(v => v.split('='))
         .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {})
     : {};
 
   /**
    * parsedHash.error could be
    *   'interaction_required' if logged in on the wrong stage
    *   'login_required' e.g. if the users password has changed
    */
   if (parsedHash.error) {
     // logout if Azure Active Directory return an error
     logoutAction();
 
     return false;
   }
 
   if (parsedHash.access_token) {
     const session: Session = {
       accessToken: parsedHash.access_token,
       accessTokenPayload: parseJwt(parsedHash.access_token),
       expiresIn: parsedHash.expires_in,
       idToken: parsedHash.id_token,
       scope: parsedHash.scope,
       sessionState: parsedHash.session_state,
       tokenType: parsedHash.token_type,
     };
 
     // eslint-disable-next-line @typescript-eslint/no-explicit-any
     (window as any).parent.loginSuccess(session);
     window.localStorage.setItem(localStorageKey, JSON.stringify(session));
 
      startPeriodicTokenRefresh();
   }
 
   return true;
 };
 
 const login = (): void => {
   const { loginUrl } = authConfig;
 
   window.location.replace(loginUrl);
 };
 
 const logout = (): void => {
   window.localStorage.removeItem(localStorageKey);
 
   const { logoutUrl } = authConfig;
 
   window.location.replace(logoutUrl);
 };
 
 const getSession = (): Session | null => {
   const storeAuth = store.getState().auth;
 
   if (storeAuth.isLoggedIn && storeAuth.session && storeAuth.session.accessToken) {
     return storeAuth.session;
   }
 
   const localStorageSession = window.localStorage.getItem(localStorageKey);
 
   if (localStorageSession) {
     const session: Session | null = JSON.parse(localStorageSession);
 
     if (session && session.accessToken) {
       // if the redux store does not contain the session, but there is
       // a session in the localStorage, the site was reloaded and the
       // session should be stored in redux again.
       loginAction(session);
 
       return session;
     }
   }
 
   return null;
 };
 
 const startPeriodicTokenRefresh = (): void => {
   const session = getSession();
 
   if (!session) {
     login();
 
     return;
   }
 
   if (!session) {
     return;
   }
 
   // only refresh token if the token expires within the next 10 minutes
   const tokenValidForSeconds = 10 * 60;
   // refresh token every 9 minutes (1 less than 10 to ensure that
   // the token refresh is done at least once in the last 10 minutes
   // before the token expires)
   const tokenRefreshIntervall = 9 * 60 * 1000;
 
   if (tokenRefreshIntervallId !== null) clearInterval(tokenRefreshIntervallId);
 
   tokenRefreshIntervallId = setInterval(() => {
     if (!isTokenValid(session.accessToken, tokenValidForSeconds)) {
       refreshToken();
     }
   }, tokenRefreshIntervall);
 };
 
 const isTokenValid = (token: string, validForSeconds = 0): boolean => {
   const expirationTime = parseJwt(token).exp;
 
   const currentEpochTime = Math.floor(new Date().getTime() / 1000.0);
 
   if (expirationTime - currentEpochTime > validForSeconds) {
     return true;
   }
 
   return false;
 };
 
 let refreshTokenPromise: Promise<Session> | null = null;
 
 const refreshToken = async (): Promise<Session> => {
   if (refreshTokenPromise) return refreshTokenPromise;
 
   refreshTokenPromise = new Promise((resolve, reject) => {
     // the official way to refresh tokens on Azure Active Directory
     // https://docs.microsoft.com/de-de/azure/active-directory/develop/v2-oauth2-implicit-grant-flow#refreshing-tokens
 
     const iframe = window.document.createElement('iframe');
 
     iframe.style.display = 'none';
     window.document.body.appendChild(iframe);
     iframe.src = authConfig.refreshTokenUrl;
 
     const cleanupIframe = () => {
       iframe.removeEventListener('load', cleanupIframe);
       window.document.body.removeChild(iframe);
 
       refreshTokenPromise = null;
 
       const session = getSession();
 
       if (session) resolve(session);
       else reject();
 
       return;
     };
 
     iframe.addEventListener('load', cleanupIframe);
 
     const timeUntilPromiseReject = 30 * 1000;
 
     setTimeout(() => {
       refreshTokenPromise = null;
       reject();
 
       return;
     }, timeUntilPromiseReject);
   });
 
   return refreshTokenPromise;
 };
 
 const ensureLoggedIn = async () => {
   const loginSucceessful = handleLoginCallback();
 
   if (!loginSucceessful) {
     return;
   }
 
   const session = getSession();
 
   if (!session || !isTokenValid(session.accessToken)) {
     store.dispatch({
       type: AUTHACTIONS.LOGOUT,
     });
    //  TODO: add ENV variable check statement
        login();
   } else {
     // refresh the token if the token expires within the next 10 minutes
     const validForSeconds = 10 * 60;
 
     if (!isTokenValid(session.accessToken, validForSeconds)) {
       await refreshToken();
     }
 
     startPeriodicTokenRefresh();
   }
 };
 
 // By default this function will check if the token is valid
 // for at least 30 seconds otherwise it will refresh the token
 const ensureTokenIsValid = async (validForSeconds = 30) => {
   let session = store.getState().auth.session;
   //  TODO: add ENV variable check statement
   if (session && session.idToken && !isTokenValid(session.idToken, validForSeconds)) {
     session = await refreshToken();
   }
 
  
    // if (!session) {
    //   const type = 'error';

    //   const message =
    //     'Could not validate your login status, try to refresh the page or log out and log in again';

    //   addNotification(type, message);

    //   throw new Error('Could not get a valid user session');
    // }
 
   return session;
 };
 
 export { login, logout, ensureLoggedIn, ensureTokenIsValid, refreshToken, getSession };