import React from 'react';
import { Chip } from '@material-ui/core';

const RenderChip = (label: string, value: string, resetFilter: () => void) => (
  <Chip
    key={`${label}+${value}`}
    label={
      <span>
        <b style={{ fontWeight: 700 }}>{`${label}: `}</b>
        {value}
      </span>
    }
    onDelete={resetFilter}
    color='primary'
    variant='outlined'
    style={{
      marginRight: 10,
      marginBottom: 10,
    }}
  />
);

export default RenderChip;
