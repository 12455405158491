import React, { useEffect } from 'react';
import { useSelector } from '../../reduxHooks';
import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fetchAutocomplete, AutocompleteTypes } from '../../actions/talentSearch';
import SearchBar from '../../components/TalentSearch/SearchBar';
import SearchResults from '../../components/TalentSearch/SearchResults';

const useStyles = makeStyles(() => ({
  spacer: {
    minHeight: '30vh',
  },
}));

const TalentSearch: React.FC = () => {
  const classes = useStyles();
  const talentSearchResponse = useSelector(state => state.talentSearch.talentSearchResponse.data);
  const autocomplete = useSelector(state => state.talentSearch.autocomplete);
  const { competency, userNames } = autocomplete;

  useEffect(() => {
    if (!competency) fetchAutocomplete(AutocompleteTypes.competency);

    if (!userNames) fetchAutocomplete(AutocompleteTypes.userNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competency]);

  return (
    <>
      <Collapse in={!talentSearchResponse} collapsedHeight='130px'>
        <div className={classes.spacer} />
      </Collapse>
      <SearchBar />
      <SearchResults />
    </>
  );
};

export default TalentSearch;
