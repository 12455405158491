import { NotificationContent } from '../actions/notification/types';
import get from 'lodash/get';
import { isProd } from '../constants';

import { ErrorWithWarningAndSuccess } from '../types';

function extractErrorFromStatus200(
  payload: ErrorWithWarningAndSuccess
): {
  errors: NotificationContent[];
  hasError: boolean;
} {
  const errorWith200: NotificationContent[] | null =
    payload && payload.error ? [{ type: 'error', message: payload.error }] : null;

  let errorOrWarningWithResults: NotificationContent[] = get(payload, ['serverMessages'], []);

  if (isProd) {
    // we don't show warning in prod
    errorOrWarningWithResults = errorOrWarningWithResults.filter(error => error.type !== 'warning');
  }

  const hasError = Boolean(errorWith200 || errorOrWarningWithResults.length > 0);

  const errors = errorWith200 || errorOrWarningWithResults;

  return {
    errors,
    hasError,
  };
}

function isSuccessful(
  payload: ErrorWithWarningAndSuccess | { data: ErrorWithWarningAndSuccess }
): boolean {
  return Boolean(get(payload, ['success'])) || Boolean(get(payload, ['data', 'success']));
}

export { extractErrorFromStatus200, isSuccessful };
