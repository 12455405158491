import React from 'react';
import InfoButton from '../InfoButton';

interface props {
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  position?:     
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
  isDisabled?: boolean;
}

const SkillProfilingDashboardInfo: React.FC<props> = ({ top, right, bottom, left, position, isDisabled = false }) => {
  return (
    <InfoButton
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      position={position}
      isDisabled={isDisabled}
      refs={[{ section: 'Skill Profiling Dashboard', link: 'tools-sp' }]}
    >
      <p>
        This will provide a <b>detailed view</b> on the <b>profile</b> of an individual, including
        <b> general information, competencies, experience </b> and much more.
      </p>
    </InfoButton>
  );
};

export default SkillProfilingDashboardInfo;
