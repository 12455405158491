import { OpenRolesPayload, SortObject } from '../openRoles';
import { NotificationContent } from '../notification';

export enum ACTIONS {
  FETCH_PR = 'PR/FETCH',
  FETCH_PR_FAILURE = 'PR/FETCH_FAILURE',
  FETCH_PR_INIT = 'PR/FETCH_INIT',
  SET_PR_PAGE = 'PR/SET_PAGE',
  SET_FILTER = 'PR/SET_FILTER',
  SET_SORT = 'PR/SET_SORT',
}

export interface FetchPR {
  type: ACTIONS.FETCH_PR;
  payload: OpenRolesPayload;
}
export interface BeforeFetchingPR {
  type: ACTIONS.FETCH_PR_INIT;
}
export interface FailToFetchPR {
  type: ACTIONS.FETCH_PR_FAILURE;
  payload: NotificationContent;
}

export interface SetPage {
  type: ACTIONS.SET_PR_PAGE;
  payload: number;
}

export interface SetFilter {
  type: ACTIONS.SET_FILTER;
  payload: object;
}

export interface SetSort {
  type: ACTIONS.SET_SORT;
  payload: SortObject;
}

export type ProjectRecommendationActions =
  | SetSort
  | FetchPR
  | BeforeFetchingPR
  | FailToFetchPR
  | SetPage
  | SetFilter;
