import React, { CSSProperties } from 'react';
import { Icon } from '@material-ui/core';
import { IconProps } from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  iconContainer: {
    width: 28,
    height: 28,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
  },
});

interface SherlockIconProps {
  color?: IconProps['color'];
  iconClasses?: string;
  className?: string;
  style?: CSSProperties;
}

const SherlockIcon: React.SFC<SherlockIconProps> = props => {
  const { className, style } = props;
  const color = props.color || 'action';
  const classes = useStyles();

  return (
    <Icon className={clsx(classes.iconContainer, className)} color={color} style={style}>
      {props.children}
    </Icon>
  );
};

export default SherlockIcon;
