/* eslint-disable no-console */
import React, { lazy, Suspense, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import AppBar from '../../components/AppBar';
import AppDrawer from '../../components/AppDrawer';
import TalentSearchPage from '../TalentSearch';
import history from '../../history';
import NotificationList from '../../components/Notification/NotificationList';
import ModalDisplay from '../../components/Popups/ModalDisplay';
import { hitHighLightStyles } from '../../modules/hitHighLight/style';
import ServerError from '../../components/Error/ServerError';

const DelegateUserPage = lazy(() => import('../DelegateUser'));
const OpenRolesPage = lazy(() => import('../OpenRoles'));
const ProjectRecommendation = lazy(() => import('../ProjectRecommendation'));
const Dashboard = lazy(() => import('../Dashboard'));
const UserManual = lazy(() => import('../UserManual'));

const useGlobalStyles = makeStyles(() => ({
  '@global': {
    ...hitHighLightStyles,
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflowY: 'hidden',
  },
  content: {
    width: '100%',
    minWidth: 320,
    backgroundColor: 'rgb(250, 250, 250)',
    position: 'relative',
    overflowY: 'scroll',
  },
  notLoggedInPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
  notLoggedInMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: '50pt',
  },
}));

const AppContent: React.FC = props => {
  const classes = useStyles();
  // const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  useGlobalStyles();

  const toggleDrawerHandler = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  /*
  if (!isLoggedIn) {
    return (
      <div className={`${classes.content} ${classes.notLoggedInPage}`}>
        <div className={classes.notLoggedInMessage}>
          <Typography variant={'h3'} className={classes.notLoggedInMessage} align={'center'}>
            {'Not logged in.'}
            <br />
            {'Redirecting to login provider...'}
          </Typography>
          <Box m={2} />
          <Typography>
            {"If you're not getting redirected click "}
            <Link href={authConfig.loginUrl} color={'primary'}>
              {'here'}
            </Link>
          </Typography>
        </div>
      </div>
    );
  }
  */

  return (
    <div className={classes.root}>
      <Router history={history}>
        <CssBaseline />
        <AppDrawer isOpen={isDrawerOpen} toggleDrawer={() => toggleDrawerHandler()} />
        <NotificationList />
        <ModalDisplay />
        <main className={classes.content}>
          <AppBar isDrawerOpen={isDrawerOpen} openDrawer={() => toggleDrawerHandler()} />
          <Suspense fallback={<div />}>
            <Switch>
              <Route path='/dashboard' component={Dashboard} />
              <Route path='/search' component={TalentSearchPage} />
              <Route path='/roles' component={OpenRolesPage} />
              <Route path='/delegate' component={DelegateUserPage} />
              <Route path='/project-recommendation' component={ProjectRecommendation} />
              <Route path='/server-error' component={ServerError} />
              <Route path='/user-manual/:id' component={UserManual} />
              <Route path='/user-manual' component={UserManual} />
              <Redirect from='/' to='/search' />
            </Switch>
          </Suspense>
        </main>
      </Router>
    </div>
  );
};

export default AppContent;
