export enum ACTIONS {
  RECEIVE_PROFILE_PICTURES = 'TALENT_SEARCH/RECEIVE_PROFILE_PICTURES',
}

export interface ProfileImage {
  [key: string]: string;
}
export interface LookupTable {
  [key: string]: {
    position: number;
    imageId: string;
  };
}

export interface NewTalentProfilePictures {
  profileImage: ProfileImage;
  lookupTable: LookupTable;
}
export interface ReceiveTalentProfilePictures {
  type: ACTIONS.RECEIVE_PROFILE_PICTURES;
  payload: NewTalentProfilePictures;
}

export type ProfilePictureActions = ReceiveTalentProfilePictures;
