import React, { useEffect, useState } from 'react';
import { useSelector } from '../../../reduxHooks';
import { ValueType } from 'react-select/src/types';
import {
  setPage,
  talentSearchFromQueryString,
  PMI_TalentData,
  toggleSearchMode,
  toggleInputMode,
  talentSearch,
} from '../../../actions/talentSearch';
import { SearchModes, InputModes, SearchModesType } from '../../../reducers/talentSearch';

import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Switch,
  Link,
  Table,
  Theme,
  CircularProgress,
  Tooltip,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import SherlockIcon from '../../Icons';
import AutocompleteSearchBar, { Option } from './AutocompleteSearchBar';
import AutoCorrect from './AutoCorrect';
import HitHighLightInput from './HitHighLightInput';
import ProfileTableRow from '../ProfileMatchRow';
import InfoContainer from '../../InfoButton/InfoContainer';
import TalentSearchInfo from '../../InfoButton/buttons/TalentSearchInfo';
import TalentSearchBarInfo from '../../InfoButton/buttons/TalentSearchBarInfo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 3),
    minHeight: 128,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1),
    },
  },
  searchBarContainer: {
    width: '100%',
    margin: 'auto',
    maxWidth: 900,
  },
  toggleSearchModeContainer: {
    display: 'flex',
  },
  toggleText: {
    margin: theme.spacing(1, 0, 1, 2),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  toggleTextActive: {
    color: theme.palette.primary.main,
  },
  switchContainer: {
    width: '100%',
    display: 'flex',
    paddingRight: 5,
  },
  switch: {
    marginLeft: 'auto',
  },
  searchBar: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 900,
  },
  iconButton: {
    padding: 10,
  },
  editableInput: {
    width: '100%',
    padding: '6px 0',
    color: theme.palette.text.primary,
    opacity: 1,
    outline: 0,
    minWidth: 'inherit',
    marginLeft: '12px',
    cursor: 'text',
    '&[contenteditable=true]:empty:before': {
      content: 'attr(placeholder)',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.54)',
      display: 'block' /* For Firefox */,
    },
  },
}));

const PurpleSwitch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const SearchBar: React.FC = () => {
  const classes = useStyles();

  const searchMode = useSelector(state => state.talentSearch.searchMode);
  const inputMode = useSelector(state => state.talentSearch.inputMode);
  const talentSearchFullResponse = useSelector(state => state.talentSearch.talentSearchResponse);

  const talentSearchResponse = talentSearchFullResponse.data;

  const [isLoading, setIsLoading] = useState(false);
  const [talent, setTalent] = useState<PMI_TalentData>();
  const [freeTextInputValue, setFreeTextInputValue] = useState('');
  const [autocompleteInputValue, setAutocompleteInputValue] = useState<ValueType<Option>>([]);

  const placeholder =
    searchMode === SearchModes.talentSearch
      ? 'Sherlock Talent Search'
      : 'Sherlock Profile Matching';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /**
   * Shows the talent to match in 
   * talentSearch and profileMatching
   *
   * @param {string} searchMode
   * @returns {div} container with matched talent
   * @example <div style={{ margin: '0px 40px -30px' }}>
        {searchMode === SearchModes.profileMatching && (
          <Paper style={{ width: '100%', overflowX: 'auto' }}>
            <Table style={{ minWidth: 650 }}>
              <ProfileTableRow searchResult={talent as PMI_TalentData} />
            </Table>
          </Paper>
        )}
      </div>
   */
  const matchThisTalent = (searchMode: string) => {
    return (
      <div style={{ margin: '0px 40px -30px' }}>
        {searchMode === SearchModes.profileMatching && (
          <Paper style={{ width: '100%', overflowX: 'auto' }}>
            <Table style={{ minWidth: 650 }}>
              <ProfileTableRow searchResult={talent as PMI_TalentData} />
            </Table>
          </Paper>
        )}
      </div>
    );
  };

  /**
   * This function sets the string from the searchbar as a parameter for the talent search
   */
  const search = () => {
    let searchString = freeTextInputValue;

    if (searchMode === SearchModes.profileMatching || inputMode === InputModes.skillsOnly) {
      if (autocompleteInputValue && Array.isArray(autocompleteInputValue)) {
        searchString = autocompleteInputValue.reduce(
          (previousValue: string, currentValue: Option) =>
            `${previousValue} ${
              searchMode === SearchModes.talentSearch ? currentValue.label : currentValue.value
            }`,
          ''
        );
      } else if (autocompleteInputValue) {
        const { label } = autocompleteInputValue as Option;

        searchString = label;
      } else {
        return;
      }
    }

    doSearch(searchString);
  };

  async function onAutoCorrect(value: string) {
    setFreeTextInputValue(value);
    await doSearch(value);
  }

  /**
   * Uses the previously set string (from the search function)
   * as a param for the search request
   *
   * @param {string} value
   * @async
   */
  async function doSearch(value: string) {
    if (isLoading) return;

    setIsLoading(true);
    await talentSearch(value, autocompleteInputValue);
    setPage(0);
    setIsLoading(false);
  }

  const toggleSearchModeClickHandler = (mode: SearchModesType) => {
    setAutocompleteInputValue(
      talentSearchResponse &&
        talentSearchResponse.query &&
        talentSearchResponse.query.searchMode === mode &&
        talentSearchFullResponse.autocompleteValue
        ? talentSearchFullResponse.autocompleteValue
        : []
    );
    toggleSearchMode(mode);
  };

  // If there are query parameters for the search, perform a search with those parameters,
  // then remove the parameters from the URL.
  if (window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);

    (async () => {
      if (isLoading) return;

      setIsLoading(true);

      if (searchParams.get('searchMode') === 'profileMatching') {
        toggleSearchModeClickHandler(SearchModes.profileMatching);
      }

      await talentSearchFromQueryString(searchParams);
      window.history.replaceState(null, '', '/search');
      setPage(0);
      setIsLoading(false);
    })();
  }

  const setValue = (value: ValueType<Option>) => {
    setAutocompleteInputValue(value);
  };

  useEffect(() => {
    if (talentSearchResponse && talentSearchResponse.query) {
      if (searchMode !== talentSearchResponse.query.searchMode) {
        if (searchMode === SearchModes.profileMatching) {
          toggleSearchModeClickHandler(SearchModes.profileMatching);
        } else if (searchMode === SearchModes.talentProfiles) {
          toggleSearchModeClickHandler(SearchModes.talentProfiles);
        }
      } else if (talentSearchResponse && talentSearchResponse.query.searchMode === searchMode) {
        setAutocompleteInputValue(talentSearchFullResponse.autocompleteValue);
      }
    }

    if (talentSearchResponse && talentSearchResponse.referenceUser) {
      setTalent(talentSearchResponse.referenceUser);
    }

    // eslint-disable-next-line
  }, [talentSearchResponse]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.searchBarContainer}>
          <div className={classes.toggleSearchModeContainer}>
            <InfoContainer>
              <Link
                onClick={() => toggleSearchModeClickHandler(SearchModes.talentSearch)}
                className={clsx(classes.toggleText, {
                  [classes.toggleTextActive]: searchMode === SearchModes.talentSearch,
                })}
              >
                Talent Search
              </Link>
              <Link
                onClick={() => {
                  toggleSearchModeClickHandler(SearchModes.profileMatching);
                }}
                className={clsx(classes.toggleText, {
                  [classes.toggleTextActive]: searchMode === SearchModes.profileMatching,
                })}
              >
                Profile matching
              </Link>
              <TalentSearchInfo position={'top-start'} right={-20} />
            </InfoContainer>
          </div>
          {searchMode !== SearchModes.talentProfiles && (
            <InfoContainer>
              <TalentSearchBarInfo top={20} right={10} style={{zIndex: 1000}} position={'top-end'} />
              <Paper className={classes.searchBar} elevation={1}>
                {isLoading ? (
                  <CircularProgress
                    style={{
                      width: 30,
                      height: 30,
                      margin: 10,
                    }}
                  />
                ) : (
                  <Tooltip title='Search' placement='top' enterDelay={300}>
                    <IconButton
                      color='primary'
                      className={classes.iconButton}
                      aria-label='Search'
                      onClick={search}
                      disabled={isLoading}
                    >
                      <SherlockIcon color={'primary'}>
                        <SearchIcon />
                      </SherlockIcon>
                    </IconButton>
                  </Tooltip>
                )}
                {inputMode === InputModes.freeText &&
                  searchMode !== SearchModes.profileMatching && (
                    <HitHighLightInput
                      disabled={isLoading}
                      placeholder={'Sherlock Talent Search'}
                      talentSearchResponse={talentSearchResponse}
                      search={e => doSearch(e)}
                      onChange={(value: string) => setFreeTextInputValue(value)}
                      className={`${classes.editableInput} ${'search-bar'}`}
                    />
                  )}
                {(inputMode === InputModes.skillsOnly ||
                  searchMode === SearchModes.profileMatching) && (
                  <AutocompleteSearchBar
                    disabled={isLoading}
                    placeholder={placeholder}
                    value={autocompleteInputValue}
                    searchMode={searchMode}
                    onEnter={search}
                    onChange={value => setValue(value)}
                  />
                )}
              </Paper>
            </InfoContainer>
          )}
          {searchMode !== SearchModes.profileMatching && searchMode !== SearchModes.talentProfiles && (
            <div className={classes.switchContainer}>
              <FormControlLabel
                classes={{
                  root: classes.switch,
                  label: classes.toggleText,
                }}
                label='Autocomplete'
                labelPlacement='start'
                control={
                  <PurpleSwitch
                    onChange={toggleInputMode}
                    checked={inputMode === InputModes.skillsOnly}
                  />
                }
              />
            </div>
          )}
          <AutoCorrect freeTextInputValue={freeTextInputValue} onAutoCorrect={onAutoCorrect} />
        </div>
      </div>
      {talent ? matchThisTalent(searchMode) : null}
    </>
  );
};

export default SearchBar;
