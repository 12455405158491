import { PMI_TalentData } from './../talentSearch/types';
import { NotificationContent } from '../notification';
import { SkillData, MetadataPayload } from '../talentSearch';

export enum ACTIONS {
  FETCH_OPEN_ROLES = 'OPEN_ROLES/FETCH',
  FETCH_OPEN_ROLES_FAILURE = 'OPEN_ROLES/FETCH_FAILURE',
  FETCH_OPEN_ROLES_INIT = 'OPEN_ROLES/FETCH_INIT',
  FILTER_OPEN_ROLES = 'OPEN_ROLES/FILTER',
  ADD_BOOKED_DEMANDS = 'OPEN_ROLES/ADD_BOOKED_DEMANDS',
  SET_BOOKED_DEMANDS = 'OPEN_ROLES/SET_BOOKED_DEMANDSS',
  ON_INPUT_CHANGE = 'OPEN_ROLES/ON_INPUT_CHANGE',
  ON_FILTER = 'OPEN_ROLES/ON_FILTER',
  SET_PAGE = 'OPEN_ROLES/SET_PAGE',
  SET_SORT = 'OPEN_ROLES/SET_SORT',
  FETCH_AUTOCOMPLETE = 'OPEN_ROLES/FETCH_AUTOCOMPLETE',
  SET_FILTERS = 'OPEN_ROLES/SET_FILTERS',
  RESET_FILTERS = 'OPEN_ROLES/RESET_FILTERS',
  FETCH_XAI_DATA = 'FETCH_XAI_DATA',
  INIT_XAI_DATA = 'INIT_XAI_DATA',
  FETCH_EMPLOYEE_INFO = 'FETCH_EMPLOYEE_INFO',
  FETCH_ALOCATION_INFO = 'OPEN_ROLES/SET_EMPLOYEE_INFO',
  SET_REAL_SUPPLY_FTE = 'OPEN_ROLES/SET_REAL_SUPPLY_FTE'
}

export interface XAIdata {
  demandKey: string;
  supplyKey: number;
  extractedData: [];
}

export interface EmployeeData {
  referenceUser: [];
}

export interface AllocationData {
  allocated: boolean;
  metadata: {
    serverMessages: [
      {
        message: string,
        type: string,
        code: number
      }
    ]
  }
}

export interface SortObject {
  order: 'asc' | 'desc' | undefined;
  orderBy: string;
}

export interface SupplyContact {
  cellPhone: string;
  email: string;
  officePhone: string;
  managerEmail: string;
}

export interface Supply {
  supplyKey: number;
  contact: SupplyContact[];
  location: { city: string }[];
  city ?: string;
  managerEmail ?: string;
  function: string;
  subFunction: string;
  allocatedFte: number;
  fromDate: string;
  toDate: string;
  availability: {
    percentage: number;
    fromDate: string;
    toDate: string;
  };
  master: {
    firstName: string;
    lastName: string;
    jobTitle: string;
  }[];
  score: number;
  competency: number;
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface PMI_OpenRole {
  projectName: string;
  demandKey: string;
  function: string;
  subFunction: string;
  portfolio: string;
  resourcePlanNumber: string;
  resourcePlanName: string;
  roleName: string;
  roleDescription: string;
  plannedFTE: string;
  startDate: string;
  endDate: string;
  competency: SkillData[];
  supply: Supply[];
}

export interface OpenRoleTalentData {
  peopleKey: number;
  enterpriseID: string;
  firstName: string;
  lastName: string;
}

export type AutocompleteTypes = string;

export type FetchAutocompletePayload = {
  items: string[];
  metadata: MetadataPayload;
};

export interface OpenRolesPayload {
  items: PMI_OpenRole[];
  metadata: MetadataPayload;
  error: string;
  maxEntries: number;
  referenceUser: PMI_TalentData[];
}

export interface FetchOpenRoles {
  type: ACTIONS.FETCH_OPEN_ROLES;
  payload: OpenRolesPayload;
}

export interface InitxAIdataRoles {
  type: ACTIONS.INIT_XAI_DATA;
  payload: number;
}
export interface FetchxAIdataRoles {
  type: ACTIONS.FETCH_XAI_DATA;
  payload: XAIdata;
}

export interface AddBookedDemands {
  type: ACTIONS.ADD_BOOKED_DEMANDS;
  payload:  string
}
export interface SetBookedDemands {
  type: ACTIONS.SET_BOOKED_DEMANDS;
  payload:  string
}

export interface FetchEmployeeInfo {
  type: ACTIONS.FETCH_EMPLOYEE_INFO;
  payload: EmployeeData;
}

export interface FetchAllocationInfo {
  type: ACTIONS.FETCH_ALOCATION_INFO;
  payload: AllocationData;
}

export interface BeforeFetchingOpenRoles {
  type: ACTIONS.FETCH_OPEN_ROLES_INIT;
}
export interface FailToFetchOpenRole {
  type: ACTIONS.FETCH_OPEN_ROLES_FAILURE;
  payload: NotificationContent;
}

export type SuggestionCriterias = SuggestionCriteria[];
export interface SuggestionCriteria {
  label: string;
  value: string;
}

export interface OnFilter {
  type: ACTIONS.ON_FILTER;
  payload: AutocompleteTypes;
}

export interface OnChange {
  type: ACTIONS.ON_INPUT_CHANGE;
  payload: SuggestionCriterias;
}
export interface SetPage {
  type: ACTIONS.SET_PAGE;
  payload: number;
}

export interface FilterOpenRoles {
  type: ACTIONS.FILTER_OPEN_ROLES;
  payload: PMI_OpenRole[];
}

export interface SetSort {
  type: ACTIONS.SET_SORT;
  payload: SortObject;
}

export interface FetchAutocomplete {
  type: ACTIONS.FETCH_AUTOCOMPLETE;
  payload: {
    subjectMatter: AutocompleteTypes;
    data: FetchAutocompletePayload;
  };
}

export interface SetFilters {
  type: ACTIONS.SET_FILTERS;
  payload: SearchFilters;
}

export interface ResetFilters {
  type: ACTIONS.RESET_FILTERS;
  payload: SearchFilters;
}

export type OpenRolesActions =
  | ResetFilters
  | SetFilters
  | FetchAutocomplete
  | FetchOpenRoles
  | BeforeFetchingOpenRoles
  | FailToFetchOpenRole
  | FilterOpenRoles
  | OnFilter
  | OnChange
  | SetPage
  | SetSort
  | InitxAIdataRoles
  | FetchxAIdataRoles
  | AddBookedDemands
  | SetBookedDemands
  | FetchEmployeeInfo
  | FetchAllocationInfo

export interface SearchFilters {
  levelFrom: number;
  levelTo: number;
  isChargeable: string;
  isSold: string;
  roleStatus: string[];
  projectGeographicUnit: string[];
  roleTalentSegment: string[];
  roleLocation: string[];
  primarySkills: string[];
  locationPreference: string[];
  demandSpecialist: string[];
  active: Partial<SearchFilters>;
}
