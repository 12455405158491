import React from 'react';
import InfoButton from '../InfoButton';

interface props {
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  position?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  style?: {};
}

const TalentSearchInfo: React.FC<props> = ({ top, right, bottom, left, position, style }) => {
  return (
    <InfoButton
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      position={position}
      style={style}
      refs={[
        { section: 'Talent Search', link: 'ts-index' },
        { section: 'Profile Matching', link: 'ts-pm' },
      ]}
    >
      <p>
        <b>Talent Search</b> looks for a talent with a given set of <b>competencies</b> across the
        organization.
      </p>
      <p>
        <b>Profile Matching</b> functionality provides <b>talents similar</b> to the one provided,
        based on skills and experience.
      </p>
    </InfoButton>
  );
};

export default TalentSearchInfo;
