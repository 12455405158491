import { ACTIONS, NotificationContent } from './types';
import { store } from '../../store';
import { NotificationType } from '../../reducers/notification/types';

export const addNotification = (type: NotificationType, message: string, timeout = 5000) => {
  store.dispatch({
    type: ACTIONS.ADD_NOTIFICATION,
    payload: {
      type,
      message,
      timeout,
    },
  });
};
export const addNotifications = (notifications: NotificationContent[]) => {
  store.dispatch({
    type: ACTIONS.ADD_NOTIFICATIONS,
    payload: notifications,
  });
};
export const removeNotification = (id: string) => {
  store.dispatch({
    type: ACTIONS.REMOVE_NOTIFICATION,
    payload: id,
  });
};
export * from './types';
