import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Typography,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Link as ExternalLink,
} from '@material-ui/core';
import PackageJson from '../../package.json';
import EmailLink from './Links/EmailLink';
import SherlockIcon from './Icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PieChartIcon from '@material-ui/icons/PieChart';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CommentIcon from '@material-ui/icons/Comment';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import UserManualDir from '../json/user_manual_dir.json';

let dW: { min: undefined | number; max: undefined | number } = {
  min: 63,
  max: 285,
};

interface AppDrawerProps {
  isOpen: boolean;
  toggleDrawer: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: (props: AppDrawerProps) => ({
      width: dW.max,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      overflow: 'hidden',
      position: 'relative',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    drawer: (props: AppDrawerProps) => ({
      width: dW.max,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    toggleDrawerButton: {
      color: 'inherit',
      padding: theme.spacing(1),
    },
    drawerListWrapper: {
      height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#50b7fc',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#2a94dd',
        borderRadius: '15px',
      },

    },
    divider: {
      margin: theme.spacing(0, 1),
      backgroundColor: theme.palette.common.white,
      opacity: 0.5,
    },
    listItem: {
      padding: theme.spacing(2),
    },
    listItemIcon: (props: AppDrawerProps) => ({
      marginRight: props.isOpen ? 30 : 0,
      width: 30,
      height: '100%',
      display: 'flex',
      justifyContent: 'center'
    }),
    drawerClose: (props: AppDrawerProps) => ({
      width: 0,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: dW.min,
      },
    }),
    toolbar: (props: AppDrawerProps) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: props.isOpen ? 'flex-end' : 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      [theme.breakpoints.up(0)]: {
        minHeight: 76,
      },
    }),
    versionNumber: {
      opacity: 0.5,
      marginRight: 30,
    },
    userManualContainer: {
      zIndex: 100,
      maxHeight: '230px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#50b7fc',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'white',
        borderRadius: '15px',
      },

    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
    emailLink: {
      display: 'flex',
    },
    nested: {
      zIndex: 1000,
      paddingLeft: theme.spacing(4),
      backgroundColor: '#50b7fc',
      '&:hover':{
        background: '#35a0ea',
      }
    },
    subNested: {
      zIndex: 1000,
      paddingLeft: theme.spacing(6),
      backgroundColor: '#50b7fc',
      '&:hover':{
        background: '#35a0ea',
      }
    },
  })
);

const AppDrawer: React.FC<AppDrawerProps> = props => {
  const classes = useStyles(props);
  const { isOpen: drawerOpen, toggleDrawer } = props;
  const nodeRef = React.useRef<HTMLElement>(null);

  const [drawerWMin, setDrawerWMin] = useState<number | undefined>(undefined);
  const [drawerWMax, setDrawerWMax] = useState<number | undefined>(undefined);
  const [openUserManual, setOpenUserManual] = React.useState<boolean>(false);
  const [userManualArrow, setUserManualArrow] = React.useState(<ExpandMore />);

  dW = {
    min: drawerWMin,
    max: drawerWMax,
  };

  const userManualPath = '/user-manual/';

  useEffect(() => {
    if (drawerOpen && !drawerWMax) {
      setDrawerWMax(nodeRef.current?.clientWidth);
    }

    if (!drawerOpen && !drawerWMin) {
      setDrawerWMin(nodeRef.current?.clientWidth);
    }
  }, [nodeRef, drawerOpen, drawerWMin, drawerWMax]);

  useEffect(() => {
    if (openUserManual) {
      setUserManualArrow(<ExpandLess />);
    } else {
      setUserManualArrow(<ExpandMore />);
    }
  }, [openUserManual]);

  const toggleDrawerHandler = () => {
    toggleDrawer();
    if (openUserManual) {
      setOpenUserManual(false);
    }
  };

  const toggleUserManualHandler = () => {
    if (!drawerOpen) {
      toggleDrawer();
    }
    if (openUserManual) {
      setOpenUserManual(false);
    }
  };

  const path = useLocation().pathname;

  const handleOpenManual = () => {
    setOpenUserManual(!openUserManual);
  };

  const UserManualSubIndexes = (item: any) => {
    return item.subsection.map((section: any) => {
      return (
        <Link to={userManualPath+section.link} className={classes.link}>
          <List component='div' disablePadding>
            <ListItem button className={classes.subNested}>
              <ListItemText primary={section.name} />
            </ListItem>
          </List>
        </Link>
      );
    });
  };

  const UserManuaIndex = UserManualDir.map(item => {
    return (
      <span>
        <Link to={userManualPath+item.link} className={classes.link}>
          <List component='div' disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemText primary={item.name} />
            </ListItem>
          </List>
        </Link>
        {UserManualSubIndexes(item)}
      </span>
    );
  });

  return (
    <Drawer
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
      open={drawerOpen}
      ref={nodeRef}
    >
      <div className={classes.toolbar}>
        {drawerOpen && (
          <Typography variant='button' className={classes.versionNumber} noWrap>
            {'Sherlock v' + PackageJson.version}
          </Typography>
        )}

        <IconButton onClick={() => toggleDrawerHandler()} className={classes.toggleDrawerButton}>
          <SherlockIcon>{drawerOpen ? <ArrowBackIosIcon /> : <MenuIcon />}</SherlockIcon>
        </IconButton>
      </div>
      <Divider classes={{ root: clsx({ [classes.divider]: !drawerOpen }) }} />
      <List className={classes.drawerListWrapper}>
        <div>
          {drawerOpen && (
            <ListItem key={'Search'}>
              <ListItemText>Search</ListItemText>
            </ListItem>
          )}
          <Link to='/search' className={classes.link}>
            <ListItem
              selected={path === '/search'}
              button
              key={'talentSearch'}
              classes={{ gutters: classes.listItem }}
            >
              <Tooltip title='Talent search' placement={'top'} enterDelay={300}>
                <div className={classes.listItemIcon}>
                  <SherlockIcon>
                    <PeopleAltIcon />
                  </SherlockIcon>
                </div>
              </Tooltip>
              {drawerOpen && <ListItemText primary={'Talent Search'} />}
            </ListItem>
          </Link>
          <Link to='/roles' className={classes.link}>
            <ListItem
              selected={path === '/roles'}
              button
              key={'roleSearch'}
              classes={{ gutters: classes.listItem }}
            >
              <Tooltip title='Role search' placement={'top'} enterDelay={300}>
                <div className={classes.listItemIcon}>
                  <SherlockIcon>
                    <BusinessCenterIcon />
                  </SherlockIcon>
                </div>
              </Tooltip>
              {drawerOpen && <ListItemText primary={'Role Search'} />}
            </ListItem>
          </Link>
          <Link to='/project-recommendation' className={classes.link}>
            <ListItem
              selected={path === '/project-recommendation'}
              button
              key={'roleSearch'}
              classes={{ gutters: classes.listItem }}
            >
              <Tooltip title='Project Recommendations' placement={'top'} enterDelay={300}>
                <div className={classes.listItemIcon}>
                  <SherlockIcon>
                    <DeviceHubIcon />
                  </SherlockIcon>
                </div>
              </Tooltip>
              {drawerOpen && <ListItemText primary={'Project Recommendations'} />}
            </ListItem>
          </Link>
          <Link to='/dashboard' className={classes.link}>
            <ListItem
              key={'dashboard'}
              selected={path === '/dashboard'}
              button
              classes={{ gutters: classes.listItem }}
            >
              <Tooltip title='Dashboard' placement={'top'} enterDelay={300}>
                <div className={classes.listItemIcon}>
                  <SherlockIcon>
                    <PieChartIcon />
                  </SherlockIcon>
                </div>
              </Tooltip>
              {drawerOpen && <ListItemText primary={'Dashboard'} />}
            </ListItem>
          </Link>
          <Divider classes={{ root: classes.divider }} />
          {drawerOpen && (
            <ListItem key={'settings'}>
              <ListItemText>Settings</ListItemText>
            </ListItem>
          )}
          <Link to='/delegate' className={classes.link}>
            <ListItem
              selected={path === '/delegate'}
              button
              key={'accessManagement'}
              classes={{ gutters: classes.listItem }}
            >
              <Tooltip title='Access Management' placement={'top'} enterDelay={300}>
                <div className={classes.listItemIcon}>
                  <SherlockIcon>
                    <HowToRegIcon />
                  </SherlockIcon>
                </div>
              </Tooltip>
              {drawerOpen && <ListItemText primary={'Access Management'} />}
            </ListItem>
          </Link>
          <Link to='#' className={classes.link} onClick={() => toggleUserManualHandler()}>
            <ListItem
              selected={path === '/'}
              button
              key={'accessManagement'}
              classes={{ gutters: classes.listItem }}
              onClick={handleOpenManual}
            >
              <Tooltip title='User Manual' placement={'top'} enterDelay={300}>
                <div className={classes.listItemIcon}>
                  <SherlockIcon>
                    <MenuBookIcon />
                  </SherlockIcon>
                </div>
              </Tooltip>
              {drawerOpen && <ListItemText primary={'User Manual'} />}
              {drawerOpen && userManualArrow}
            </ListItem>
          </Link>
          <Collapse in={openUserManual} timeout='auto' unmountOnExit>
            <div className={classes.userManualContainer}>
              <Link to="/user-manual/index" className={classes.link}>
                <List component='div' disablePadding>
                  <ListItem button className={classes.nested}>
                    <ListItemText primary="Index" />
                  </ListItem>
                </List>
              </Link>
              {UserManuaIndex}
            </div>
          </Collapse>
        </div>
        <div>
          <ListItem button key={'feedback'} classes={{ gutters: classes.listItem }}>
            <Tooltip title='Give us your feedback' placement={'top'} enterDelay={300}>
              <div>
                <EmailLink
                  forContactTalent={false}
                  className={classes.emailLink}
                  link='isabel.redondo@pmi.com?subject=Feedback for Sherlock Project Skills Matching tool'
                >
                  <>
                    <div className={classes.listItemIcon}>
                      <SherlockIcon>
                        <CommentIcon />
                      </SherlockIcon>
                    </div>
                    {drawerOpen && <ListItemText primary={'Give us feedback'} />}
                  </>
                </EmailLink>
              </div>
            </Tooltip>
          </ListItem>
          <ExternalLink
            href='https://urldefense.proofpoint.com/v2/url?u=https-3A__pmicloud.sharepoint.com_-3Av-3A_r_sites_RefPBOHub_Shared-2520Documents_Community-2526Resources_PMI-2520Sherlock-2520Video-2520-2D-2520October-25202020.mp4-3Fcsf-3D1-26web-3D1-26e-3DZe27fb&d=DwMGaQ&c=eIGjsITfXP_y-DLLX0uEHXJvU8nOHrUK8IrwNKOtkVU&r=s3GaFsh9Y5UwSj8rReddRolsDq8_cU0s1-mZxnr4lUw&m=QdwM5XceCHyVZirDck2FvAINZQ4sfHiU0ROFitMUuIw&s=un8CrwjSUz9YNJ_yV0vHBIDhV2bHti1P67faD-VxsC4&e='
            className={classes.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            <ListItem
              selected={path === '/delegate'}
              button
              key={'accessManagement'}
              classes={{ gutters: classes.listItem }}
            >
              <Tooltip title='More about Sherlock AI' placement={'top'} enterDelay={300}>
                <div className={classes.listItemIcon}>
                  <SherlockIcon>
                    <PlayCircleOutlineIcon />
                  </SherlockIcon>
                </div>
              </Tooltip>
              {drawerOpen && (
                <ListItemText primary={'More about Sherlock AI'}>More about Sherlock AI</ListItemText>
              )}
            </ListItem>
          </ExternalLink>
        </div>
      </List>
    </Drawer>
  );
};

export default AppDrawer;
