import uuidv1 from 'uuid/v1';
import { ACTIONS } from '../../actions/notification';
import { AllNotifications } from '../../actions';
import { NotificationState, Notification, NotificationType } from './types';

export * from './types';

const initialState = {
  notifications: [],
};

const getNotification = (
  type: NotificationType,
  message: string,
  timeout?: number
): Notification => ({
  type,
  message,
  timeout,
  id: uuidv1(),
});

export default (state: NotificationState = initialState, action: AllNotifications) => {
  switch (action.type) {
    case ACTIONS.REMOVE_NOTIFICATION: {
      const id = action.payload;
      const { notifications } = state;

      return {
        notifications: notifications.filter(notification => notification.id !== id),
      };
    }
    case ACTIONS.ADD_NOTIFICATION: {
      const { notifications } = state;
      const { type, message, timeout } = action.payload;

      return {
        notifications: [...notifications, getNotification(type, message, timeout)],
      };
    }
    case ACTIONS.ADD_NOTIFICATIONS: {
      const { notifications } = state;
      const { payload } = action;

      const newNotifications = payload.map(error => {
        return getNotification(error.type, error.message);
      });

      return {
        notifications: [...notifications, ...newNotifications],
      };
    }
    default: {
      return state;
    }
  }
};
