import { combineReducers } from 'redux';
import authReducer from './auth';
import talentSearchReducer from './talentSearch';
import openRolesReducer from './openRoles';
import delegateUserReducer from './delegateUser';
import notificationReducer from './notification';
import modalReducer from './modal';
import profilePicturesReducer from './profilePictures';
import projectRecommendations from './projectRecommendation';

const rootReducer = combineReducers({
  auth: authReducer,
  talentSearch: talentSearchReducer,
  openRoles: openRolesReducer,
  delegateUser: delegateUserReducer,
  notification: notificationReducer,
  modal: modalReducer,
  profilePictures: profilePicturesReducer,
  projectRecommendations: projectRecommendations,
});

export type SherlockState = ReturnType<typeof rootReducer>;

export default rootReducer;
