import { ACTIONS, ModalActions } from '../../actions/modal';
import { ModalState } from './types';

export * from './types';

const initialState = null;

export default (state: ModalState = initialState, action: ModalActions) => {
  switch (action.type) {
    case ACTIONS.ADD_MODAL:
      return action.payload;
    case ACTIONS.REMOVE_MODAL:
      return null;
    default: {
      return state;
    }
  }
};
