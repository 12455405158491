import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Typography, Button, Slider } from '@material-ui/core';
import Autocomplete from '../../Filter/Autocomplete';
import { useSelector } from '../../../reduxHooks';

import {
  AutocompleteTypes,
  fetchAutocomplete,
  setTalentSearchFilters as setFilters,
  resetTalentSearchFilters as resetFilters,
  talentSearch,
  SearchFilters,
} from '../../../actions/talentSearch';
import clsx from 'clsx';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '500px',
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  heading: {
    marginRight: 'auto',
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    '& > p': {
      minWidth: 130,
    },
  },
  availability: {
    zIndex: 3,
    display: 'flex',
  },
  label: {
    fontSize: 14,
  },
  required: {
    color: theme.palette.error.light,
    marginLeft: 130,
  },
}));

interface FilterPopoverProps {
  anchorEl?: PopoverProps['anchorEl'];
  onClose?: () => void;
  setLoadingState: (isLoading: boolean) => void;
}

/**
 * Shows and sets the talent search filters
 * Gets employees by
 * availability (FTE, available from, available to),
 * location, and
 * function groups
 *
 * @param {FilterPopoverProps} anchorEl
 * @param {FilterPopoverProps} onClose
 *
 * @returns {Popover} Popover with options to filter the search
 *
 * In order to set the availability filter
 * an availability FTE must be chosen in a time range
 * otherwise the backend doesn't return available people
 */

const FilterPopover: React.FC<FilterPopoverProps> = props => {
  const { anchorEl, onClose } = props;
  const [filtersBefore, setFiltersBefore] = useState<SearchFilters>();
  const classes = useStyles();
  const searchFilters = useSelector(state => state.talentSearch.searchFilters);

  const {
    availabilitypercent,
    dateTo,
    dateFrom,
    location,
    function: consumer,
    subFunction,
  } = searchFilters;

  const autocomplete = useSelector(state => state.talentSearch.autocomplete);

  const {
    locations: locationsAutocomplete,
    functions: consumersAutocomplete,
    subFunctions: subFunctionsAutocomplete,
  } = autocomplete;

  const [selectedToDate, setSelectedToDate] = React.useState<Date | null>(dateTo || null);
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date | null>(dateFrom || null);

  const [sliderValue, setSliderValue] = React.useState<number | number[]>(
    availabilitypercent || 0.0
  );

  const isDisabled = !!(
    (selectedToDate || selectedFromDate || sliderValue) &&
    (!selectedToDate || !selectedFromDate || !sliderValue)
  );

  useEffect(() => {
    if (anchorEl) {
      if (!locationsAutocomplete) fetchAutocomplete(AutocompleteTypes.locations);

      if (!consumersAutocomplete) fetchAutocomplete(AutocompleteTypes.functions);

      if (!subFunctionsAutocomplete) fetchAutocomplete(AutocompleteTypes.subFunctions);

      setFiltersBefore(searchFilters);
    }
    /** disable dependency check for searchFilters variable, since it shouldn't
     * update the filtersBefore state on every searchFilters change.
     *
     */
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl, locationsAutocomplete, consumersAutocomplete, setFiltersBefore]);

  const handleCloseEvent = async () => {
    if (isDisabled) {
      return;
    }

    setFiltersBefore(undefined);
    onClose && onClose();

    if (!isEqual(filtersBefore, searchFilters)) {
      props.setLoadingState(true);
      await talentSearch();
      props.setLoadingState(false);
    }
  };

  // For the Availabiity Slider
  function valuetext(value: number) {
    return `${value}`;
  }

  const handleDateToChange = (newDate: MaterialUiPickersDate) => {
    if (newDate) {
      setFilters({ dateFrom: newDate.toDate() });
      setSelectedFromDate(newDate.toDate());
    }
  };

  const handleDateFromChange = (newDate: MaterialUiPickersDate) => {
    if (newDate) {
      setFilters({ dateTo: newDate.toDate() });
      setSelectedToDate(newDate.toDate());
    }
  };

  const resetAllFilters = () => {
    handleCloseEvent();
    resetFilters();
    setSliderValue(0);
    setSelectedToDate(new Date() as Date);
    setSelectedFromDate(new Date() as Date);
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCloseEvent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant='h6' className={classes.heading}>
            FILTERS
          </Typography>
          <Button disabled={isDisabled} color='secondary' onClick={handleCloseEvent}>
            APPLY
          </Button>
          <Button disabled={isDisabled} color='secondary' onClick={resetAllFilters}>
            RESET
          </Button>
        </div>
        <div className={classes.filterRow} id={'slider-label'}>
          <Typography className={classes.label}>Availability (FTE)</Typography>
          <Slider
            defaultValue={0}
            onChange={(event, value) => {
              setFilters({ availabilitypercent: value });
              setSliderValue(value);
            }}
            getAriaValueText={valuetext}
            aria-labelledby='slider-label'
            step={0.1}
            min={0.0}
            max={1}
            valueLabelDisplay='on'
          />
        </div>
        {isDisabled && !sliderValue ? <div className={classes.required}>Required</div> : null}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <>
            <div className={clsx(classes.filterRow, classes.availability)}>
              <Typography className={classes.label}>Available From</Typography>
              <KeyboardDatePicker
                fullWidth
                variant='inline'
                autoOk
                disablePast
                format='DD/MM/YYYY'
                placeholder='DD/MM/YYYY'
                margin='normal'
                value={selectedFromDate}
                onChange={handleDateToChange}
              />
            </div>
            {isDisabled && !selectedFromDate ? (
              <div className={classes.required}>Required</div>
            ) : null}
            <div className={clsx(classes.filterRow, classes.availability)}>
              <Typography className={classes.label}>Available To</Typography>
              <KeyboardDatePicker
                fullWidth
                variant='inline'
                disablePast
                autoOk
                minDate={selectedFromDate}
                format='DD/MM/YYYY'
                placeholder='DD/MM/YYYY'
                margin='normal'
                value={selectedToDate}
                onChange={handleDateFromChange}
              />
            </div>
            {isDisabled && !selectedToDate ? (
              <div className={classes.required}>Required</div>
            ) : null}
          </>
        </MuiPickersUtilsProvider>
        <div className={classes.filterRow}>
          <Autocomplete
            label='Location'
            options={
              locationsAutocomplete &&
              locationsAutocomplete.items.map(option => ({ label: option, value: option }))
            }
            value={location}
            onChange={value => {
              setFilters({ location: value });
            }}
          />
        </div>
        <div className={classes.filterRow}>
          <Autocomplete
            label='Function Groups'
            options={
              consumersAutocomplete &&
              consumersAutocomplete.items.map(option => ({
                label: option,
                value: option,
              }))
            }
            value={consumer}
            onChange={value => {
              setFilters({ function: value });
            }}
          />
        </div>
        <div className={classes.filterRow}>
          <Autocomplete
            label='Sub Functions'
            options={
              subFunctionsAutocomplete &&
              subFunctionsAutocomplete.items.map(option => ({
                label: option,
                value: option,
              }))
            }
            value={subFunction}
            onChange={value => {
              setFilters({ subFunction: value });
            }}
          />
        </div>
      </div>
    </Popover>
  );
};

export default FilterPopover;
