import { ACTIONS, ProfilePictureActions } from '../../actions/profilePictures';

import { ProfilePictureState } from './types';

/*
There will be caching to indexDB soon, hence the structure.
*/
const initialState = {
  profileImages: {},
  lookupTable: {},
};

const profilePictures = (
  state: ProfilePictureState = initialState,
  action: ProfilePictureActions
) => {
  switch (action.type) {
    case ACTIONS.RECEIVE_PROFILE_PICTURES: {
      const { profileImage, lookupTable } = action.payload;

      return {
        profileImages: {
          ...state.profileImages,
          ...profileImage,
        },
        lookupTable: {
          ...state.lookupTable,
          ...lookupTable,
        },
      };
    }
    default:
      return state;
  }
};

export default profilePictures;
