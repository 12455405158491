import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { amber } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { removeNotification } from '../../actions/notification';
import { useSelector } from '../../reduxHooks';
import { Notification as NotificationType } from '../../reducers/notification';

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Notification = ({ type, message, id, timeout }: NotificationType) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleClose() {
    closeSnackbar(id);
    removeNotification(id);
  }

  const action = () => {
    return (
      <IconButton
        onClick={() => {
          handleClose();
        }}
        key='close'
        aria-label='Close'
        color='inherit'
      >
        <CloseIcon className={classes.icon} />
      </IconButton>
    );
  };

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, {
        variant: type,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        action,
        key: id,
        autoHideDuration: timeout || 2000,
        onClose: () => {
          handleClose();
        },
      });
    }
    // eslint-disable-next-line
    }, []);

  return null;
};

const NotificationList = () => {
  const notifications = useSelector(state => state.notification.notifications);

  return (
    <div>
      {notifications.map(notification => {
        return <Notification key={notification.id} {...notification} />;
      })}
    </div>
  );
};

export default NotificationList;
