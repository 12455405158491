export enum ACTIONS {
  LOGIN = 'AUTH/LOGIN',
  LOGOUT = 'AUTH/LOGOUT',
  LOGIN_REQUEST = 'AUTH/LOGIN_REQUEST',
}

export interface AccessTokenPayload {
  /** Audience (who or what the token is intended for) */
  aud: string;
  /** Issuer (who created and signed this token) */
  iss: string;
  /** Issued at (seconds since Unix epoch) */
  iat: number;
  /** Not valid before (seconds since Unix epoch) */
  nbf: number;
  /** Expiration time (seconds since Unix epoch) */
  exp: number;
  acct: number;
  /** Authentication context class */
  acr: string;
  aio: string;
  /** Authentication methods array */
  amr: string[];
  /** Name of the app which will be displayed to the user during login */
  app_displayname: string;
  /** App ID / Client ID */
  appid: string;
  /** App ID authentication context class */
  appidacr: string;
  /** Family name of the user */
  family_name: string;
  /** All given names of the user */
  given_name: string;
  /** IP address from which the user logged in */
  ipaddr: string;
  /** "<family_name>, <given_name>" Full name of the user */
  name: string;
  oid: string;
  onprem_sid: string;
  platf: string;
  puid: string;
  /** Scope(s) for which the access_token will be valid */
  scp: string;
  signin_state: string[];
  /** Subject (whom the token refers to) */
  sub: string;
  tid: string;
  /** Unique name of the user (Email address) */
  unique_name: string;
  /** Email address which the user used to sign in */
  upn: string;
  uti: string;
  ver: string;
  xms_st: {
    sub: string;
  };
  xms_tcdt: number;
}

export interface Session {
  accessToken: string;
  accessTokenPayload: AccessTokenPayload;
  expiresIn?: string;
  idToken?: string;
  scope?: string;
  sessionState?: string;
  tokenType?: string;
}

interface LoginAction {
  type: ACTIONS.LOGIN;
  payload: Session;
}
interface LoginRequestAction {
  type: ACTIONS.LOGIN_REQUEST;
}
interface LogoutAction {
  type: ACTIONS.LOGOUT;
}

export type AuthActions = LoginAction | LogoutAction | LoginRequestAction;
