export enum ACTIONS {
  FETCH_DELEGATIONS = 'DELEGATE_USER/FETCH',
  ADD_DELEGATED_USER = 'DELEGATE_USER/ADD_DELEGATED_USER',
  REVOKE_DELEGATIONS = 'DELEGATE_USER/REVOKE_DELEGATIONS',
  FETCH_AUTOCOMPLETE = 'DELEGATE_USER/FETCH_AUTOCOMPLETE',
  FETCH_ROLES = 'DELEGATE_USER/FETCH_ROLES',
}

interface DefaultServerResponse {
  metadata: {
    serverMessages: [
      {
        message: string;
        type: string;
        code: number;
      }
    ];
  };
}
export interface AutoCompleteResponse {
  [key: string]: string;
  user_id: string;
}
export interface AutoCompletePayload extends DefaultServerResponse {
  items: AutoCompleteResponse[];
}
export interface FetchAutocomplete {
  type: ACTIONS.FETCH_AUTOCOMPLETE;
  payload: AutoCompletePayload;
}

export type RoleAutoCompleteResponse = string;

export interface FetchUserDelegationRolesPayload extends DefaultServerResponse {
  items: RoleAutoCompleteResponse[];
}

export interface FetchDelegateUserRoles {
  type: ACTIONS.FETCH_ROLES;
  payload: FetchUserDelegationRolesPayload;
}

export enum DelegatedUser {
  assignationDate = 'assignation_date',
  enterpriseID = 'user_id',
  expiryDate = 'expiry_date',
  roleID = 'role_id',
}

export interface DelegatedUserResponse {
  [key: string]: string;
  assignation_date: string;
  assigner: string;
  expiry_date: string;
  role_id: string;
  user_id: string;
}

export interface ExtendDelegatedUserPayload {
  user_extended: boolean;
  metadata: {
    serverMessages: [
      {
        message: string;
        type: string;
        code: number;
      }
    ];
  };
}

export interface ExtendDelegatedUserPayloadError {
  detail: [
    {
      loc: [string];
      msg: string;
      type: string;
    }
  ];
}

export type FetchDelegateUserPayload = DelegatedUser[];

type AddDelegatedUserServerResponseMessage = 'successful' | 'error.redelegate.user';
export type AddDelegatedUserPayload = AddDelegatedUserServerResponseMessage;

type RevokeDelegationsServerResponseMessage = 'successful' | 'error.targetuser.not.found';
export type RevokeDelegationsPayload = RevokeDelegationsServerResponseMessage;

export interface FetchDelegateUser {
  type: ACTIONS.FETCH_DELEGATIONS;
  payload: any;
}
export interface AddDelegatedUser {
  type: ACTIONS.ADD_DELEGATED_USER;
  payload: AddDelegatedUserPayload;
}

export interface RevokeDelegations {
  type: ACTIONS.REVOKE_DELEGATIONS;
  payload: RevokeDelegationsPayload;
}

export type DelegateUserActions =
  | FetchDelegateUser
  | FetchDelegateUserRoles
  | AddDelegatedUser
  | RevokeDelegations
  | FetchAutocomplete;
