import {
  TalentSearchPayload,
  FetchAutocompletePayload,
  AutocompleteTypes,
  FetchProfilePicturePayload,
  SearchFilters,
} from '../../actions/talentSearch';
import { Option } from '../../components/TalentSearch/SearchBar/AutocompleteSearchBar';

export enum SearchModes {
  talentSearch = 'talentSearch',
  profileMatching = 'profileMatching',
  rawProfileMatching = 'rawProfileMatching',
  talentProfiles = 'talentProfiles',
}
export enum InputModes {
  freeText = 'freeText',
  skillsOnly = 'skillsOnly',
}
export type SearchModesType =
  | SearchModes.talentSearch
  | SearchModes.profileMatching
  | SearchModes.rawProfileMatching
  | SearchModes.talentProfiles;
export type InputModesType = InputModes.freeText | InputModes.skillsOnly;

export interface Pagination {
  currentPage: number;
  rowsPerPage: number;
}

export interface ExplainableAISkill {
  skill: string;
  'sherlock: proximity'?: number;
  competency?: number;
  competencyPool?: number;
  commentCompetency?: number;
  commentExpertise?: number;
  function?: number;
  identifiedStrength?: number;
  jobTitle?: string;
}
export interface TalentProfilesState {
  data: TalentSearchPayload | null;
  filter: { name: string | null };
  // pagination: Pagination;
}
export interface TalentSearchState {
  searchMode: SearchModes;
  inputMode: InputModes;
  talentSearchResponse: {
    data: TalentSearchPayload | null;
    inputMode: InputModesType;
    searchMode: SearchModesType;
    autocompleteValue?: Option;
  };
  searchFilters: SearchFilters;
  profilePictures: {
    [enterpriseId: string]: FetchProfilePicturePayload['profilePicture'] | undefined;
  };
  autocomplete: {
    [key in keyof typeof AutocompleteTypes]?: FetchAutocompletePayload;
  };
  explainableAIData: {
    [key: string]: ExplainableAISkill[] | undefined;
  };
  pagination: Pagination;
}
