import { SkillData } from '../actions/talentSearch';

function sortSkillsByMostProficient(skills: SkillData[]) {
  return skills.sort((a, b) => {
    if (a.proficiency > b.proficiency) return -1;

    if (a.proficiency < b.proficiency) return 1;

    return 0;
  });
}

function extractExtraSkills(extra: any, skills: SkillData[]) {
  const extraSkills: string[] = [];

  if (extra) {
    extra.forEach((e: any) => {
      e.prediction.forEach((competency: string) => {
        if (competency === e.term) {
          if (!skills.find(i => i.name === competency)) {
            extraSkills.push(competency);
          }
        }
      });
    });
  }

  return extraSkills;
}

export { sortSkillsByMostProficient, extractExtraSkills };
