import { SherlockActions } from '../../actions';
import { ACTIONS, SearchFilters, ExplainableAIPayload } from '../../actions/talentSearch';
import {
  SearchModes,
  InputModes,
  TalentSearchState,
  ExplainableAISkill,
  TalentProfilesState,
} from './types';
import emitter from '../../modules/emitter';
import { eventMessages } from '../../constants';

import { combineReducers } from 'redux';
import { isArray } from 'util';

export * from './types';

const searchModeReducer = (
  searchMode: TalentSearchState['searchMode'] = SearchModes.talentSearch,
  action: SherlockActions
) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_SEARCH_MODE:
      return action.payload;
    default:
      return searchMode;
  }
};

const inputModeReducer = (
  inputMode: TalentSearchState['inputMode'] = InputModes.skillsOnly,
  action: SherlockActions
) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_INPUT_MODE:
      return inputMode === InputModes.freeText ? InputModes.skillsOnly : InputModes.freeText;
    default:
      return inputMode;
  }
};

const initialTalentSearchResponse = null;

const talentSearchResponseReducer = (
  state: TalentSearchState['talentSearchResponse'] = {
    /*
    Whether or not we are in free text mode is not returned from the backend
    So searchResultMode and searchResultInputMode are for flagging the response so that
    we know which mode this response belongs to as state.talentSearch.inputMode and state.talentSearch.searchMode can be
    changed by anything.
    */
    data: initialTalentSearchResponse,
    inputMode: InputModes.skillsOnly,
    searchMode: SearchModes.talentSearch,
  },
  action: SherlockActions
) => {
  switch (action.type) {
    case ACTIONS.TALENT_SEARCH: {
      const newState = {
        data: action.payload,
        inputMode: action.inputMode,
        searchMode: action.searchMode,
        autocompleteValue: action.autocompleteValue,
      };

      if (
        action.inputMode === InputModes.freeText &&
        action.searchMode === SearchModes.talentSearch
      ) {
        emitter.emit(eventMessages.HIGH_LIGHT_TALENT_SEARCH_INPUT, action.payload);
      }

      return {
        ...newState,
      };
    }
    case ACTIONS.UPDATE_TALENTS: {
      return { ...action.payload };
    }
    default:
      return state;
  }
};

const talentProfilesReducer = (
  state: TalentProfilesState = {
    data: initialTalentSearchResponse,
    filter: { name: null },
  },
  action: SherlockActions
) => {
  switch (action.type) {
    case ACTIONS.PROFILES_SEARCH: {
      const newState = {
        ...state,
        data: action.payload,
      };

      return {
        ...newState,
      };
    }
    case ACTIONS.SET_TALENT_PROFILE_FILTERS: {
      return { ...state, filter: action.payload };
    }
    case ACTIONS.RESET_TALENT_PROFILE_FILTERS: {
      return { ...state, filter: action.payload };
    }
    default:
      return state;
  }
};

export const defaultFilters: SearchFilters = {
  active: {},
  availabilitypercent: 0,
  dateTo: null,
  dateFrom: null,
  location: [],
  function: [],
  subFunction: [],
};

const searchFiltersReducer = (
  searchFilters: TalentSearchState['searchFilters'] = defaultFilters,
  action: SherlockActions
) => {
  switch (action.type) {
    case ACTIONS.RESET_FILTERS: {
      return action.payload;
    }
    case ACTIONS.SET_FILTERS: {
      const payload = action.payload;
      const arrays: any = {};

      Object.keys(payload).forEach(i => {
        const value = payload[i as keyof typeof payload];

        if (isArray(value) && value[0]) {
          arrays[i] = value;
        }
      });

      return {
        ...payload,
        active: {
          ...(payload.availabilitypercent && {
            availabilitypercent: payload.availabilitypercent,
          }),
          ...(payload.dateTo && { dateTo: payload.dateTo }),
          ...(payload.dateFrom && { dateFrom: payload.dateFrom }),
          ...arrays,
        },
      };
    }
    default:
      return searchFilters;
  }
};

const profilePicturesReducer = (
  profilePictures: TalentSearchState['profilePictures'] = {},
  action: SherlockActions
) => {
  switch (action.type) {
    case ACTIONS.FETCH_PROFILE_PICTURE:
      return {
        ...profilePictures,
        [action.payload.enterpriseId]: action.payload.profilePicture,
      };
    default:
      return profilePictures;
  }
};

const autocompleteReducer = (
  autocomplete: TalentSearchState['autocomplete'] = {},
  action: SherlockActions
) => {
  switch (action.type) {
    case ACTIONS.FETCH_AUTOCOMPLETE:
      return {
        ...autocomplete,
        [action.payload.subjectMatter]: action.payload.data,
      };
    default:
      return autocomplete;
  }
};

export enum SkillLabelMap {
  competency = 'Competency',
  competencyPool = 'Competency Pool',
  managerReview = 'Manager Review',
  selfReview = 'Self Review',
  strengths = 'Strengths',
  internalRoleDetails = 'Internal Role XP',
  externalRoleDetails = 'External Role XP',
  internalExperience = 'Internal XP',
  externalExperience = 'External XP',
  jobTitle = 'Job Title',
  roleName = 'Role Name',
}

const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

const processXAIDataForGraph = (data: ExplainableAIPayload) => {
  return data.data.extractedData.map(({ term, tfValue }) => {
    const xAISkillObject: ExplainableAISkill = {
      skill: term,
    };

    keys(tfValue).forEach(key => {
      const newKey = SkillLabelMap[key];

      // @ts-ignore
      xAISkillObject[newKey] = tfValue[key];
    });

    return xAISkillObject;
  });
};

const explainableAIDataReducer = (
  explainableAIData: TalentSearchState['explainableAIData'] = {},
  action: SherlockActions
) => {
  switch (action.type) {
    case ACTIONS.TALENT_SEARCH:
      // reset the explainable AI data on every talent search request
      return {};
    case ACTIONS.FETCH_EXPLAINABLE_AI_DATA:
      return {
        ...explainableAIData,
        [action.payload.supplyKey]: processXAIDataForGraph(action.payload.data),
      };
    default:
      return explainableAIData;
  }
};

const pagigationReducer = (
  state: TalentSearchState['pagination'] = {
    currentPage: 0,
    rowsPerPage: 5,
  },
  action: SherlockActions
) => {
  switch (action.type) {
    case ACTIONS.SET_PAGE: {
      const { currentPage } = state;

      if (currentPage !== action.payload) {
        return { ...state, currentPage: action.payload };
      }

      return state;
    }
    default:
      return state;
  }
};

export default combineReducers({
  searchMode: searchModeReducer,
  inputMode: inputModeReducer,
  talentSearchResponse: talentSearchResponseReducer,
  talentProfilesResponse: talentProfilesReducer,
  searchFilters: searchFiltersReducer,
  profilePictures: profilePicturesReducer,
  autocomplete: autocompleteReducer,
  explainableAIData: explainableAIDataReducer,
  pagination: pagigationReducer,
});
