import { AccessTokenPayload } from '../../actions/auth';

const parseJwt = (token: string): AccessTokenPayload => {
  const tokenPayload = token.split('.')[1];

  if (!tokenPayload) {
    throw new Error('Cannot parse access token');
  }

  const base64 = decodeURIComponent(
    atob(tokenPayload)
      .split('')
      .map(char => {
        return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(base64);
};

export default parseJwt;
