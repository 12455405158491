import React from 'react';

import EmailLink from './EmailLink';
import { recommendSherlockToColleague } from '../../modules/applicationInsights/analytics';
import Tooltip from '@material-ui/core/Tooltip';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: object;
}
const RecommendSherlockLink: React.FC<Props> = ({ children, className, style }) => {
  const body = `Hello,

  I have been using the new talent search tool called Sherlock Project Skills Matching and I really love it. Therefore, I am sharing it with you here.
  What does it do?\n
    * It helps you to efficiently find relevant talents in your organization, so that you can engage them on your projects.\n
    * It helps you to fill in open positions in your projects by recommending relevant available talents so that their time is most efficiently utilized.\n
    * It finds similar talents to a given employee name based on their competencies.\n
    * It recommends talents who are searching for opportunities project roles that need staffing.
  
  Curious? 
    * Just try it:  https://sherlock.pmicloud.biz
  You need help or have questions? 
    * Reach out to isabel.redondo@pmi.com 
  Hope you enjoy!
  `;

  const subject =
    'Try "Sherlock Project Skills Matching", the New State of the Art Talent Search Tool';

  const link = `?subject=${subject}&body=${encodeURI(body)}`;

  return (
    <Tooltip title='Recommend tool to colleagues' placement={'top'} enterDelay={300}>
      <div>
        <EmailLink
          className={className}
          style={style}
          forContactTalent={false}
          onClick={() => {
            recommendSherlockToColleague();
          }}
          link={link}
        >
          {children}
        </EmailLink>
      </div>
    </Tooltip>
  );
};

export default RecommendSherlockLink;
