import {
  ACTIONS,
  FetchAutocomplete,
  FetchDelegateUser,
  FetchDelegateUserRoles,
} from '../../actions/delegateUser';
import { AutoCompleteResponse } from '../../actions/delegateUser/types';
import { RoleAutoCompleteResponse, DATE_FORMAT } from './types';
import { combineReducers } from 'redux';
import moment from 'moment';

export * from './types';

const autocomplete = (autocomplete: AutoCompleteResponse[] = [], action: FetchAutocomplete) => {
  switch (action.type) {
    case ACTIONS.FETCH_AUTOCOMPLETE:
      return action.payload.items;
    default:
      return autocomplete;
  }
};

const delegatedUsersReducer = (delegatedUsers: any[] = [], action: FetchDelegateUser) => {
  switch (action.type) {
    case ACTIONS.FETCH_DELEGATIONS:
      // eslint-disable-next-line no-case-declarations
      const response = [...action.payload.items];

      response.forEach(user => {
        user.assignation_date = moment(user.assignation_date).format(DATE_FORMAT);
        user.expiry_date = moment(user.expiry_date).format(DATE_FORMAT);
      });

      return action.payload.items;
    default:
      return delegatedUsers;
  }
};

const delegatedUserRolesReducer = (
  delegatedUsersRoles: RoleAutoCompleteResponse[] | null = null,
  action: FetchDelegateUserRoles
) => {
  switch (action.type) {
    case ACTIONS.FETCH_ROLES:
      return action.payload.items.map(role => {
        return { user_id: role };
      });
    default:
      return delegatedUsersRoles;
  }
};

export default combineReducers({
  delegatedUsers: delegatedUsersReducer,
  autocomplete: autocomplete,
  delegatedUserRoles: delegatedUserRolesReducer,
});
