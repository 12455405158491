const profilePictureSize = 40;
const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';
const currentEnv = (process.env.REACT_APP_ENVIRONMENT === "development") ? " - Dev" : (process.env.REACT_APP_ENVIRONMENT === "test") ? " - Qas" : "";
const inStaging = process.env.REACT_APP_IN_STAGING === 'true';
const soldColor = (sold: boolean) => (sold ? 'rgb(81, 197, 106)' : 'rgb(224, 98, 110)');
const appInsightKey = process.env.REACT_APP_APP_INSIGHT_KEY || '';
const powerBiUserSatisfactionId = process.env.REACT_APP_SATISFACTION_POWER_BI;
const powerBiSherlockUsageId = process.env.REACT_APP_USAGE_POWER_BI;
const powerBiDemandAndSupplyId= process.env.REACT_APP_DEMAND_POWER_BI;


const backendUrl = process.env.REACT_APP_BACKEND_URL || window.location.origin + '/api';

const eventMessages = {
  HIGH_LIGHT_TALENT_SEARCH_INPUT: 'HIGH_LIGHT_TALENT_SEARCH_INPUT',
};
const isLocalHost = window.location.origin.includes('localhost');
let env = window.location.origin.replace('https://', '').split('-')[0];
if(['dev', 'qas'].includes(env)) {
  env += '-'
} else {
  isLocalHost 
   ? env = 'dev-'
   : env = ''
}



export {
  appInsightKey,
  inStaging,
  profilePictureSize,
  isProd,
  backendUrl,
  soldColor,
  eventMessages,
  env,
  currentEnv,
  powerBiUserSatisfactionId,
  powerBiSherlockUsageId,
  powerBiDemandAndSupplyId
};
