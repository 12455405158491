import { useSelector as officialUseSelector } from 'react-redux';
import { SherlockState } from './reducers';

/**
 * useSelector comes from redux is not type aware, therefore making a wrapper around it to give it type.
 * @param selector
 */
function useSelector<S>(selector: (state: SherlockState) => S): S {
  return officialUseSelector((state: SherlockState) => selector(state));
}

export { useSelector };
