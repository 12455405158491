import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  infoContainer: {
    display: ' block',
    margin: '0',
    padding: '0',
    position: 'relative',     
    overflow: 'visible',
  },
}));

interface props{
    children: any
}

const InfoContainer: React.FC<props> = ({children}) => {
  const classes = useStyles();

  return (
    <div className={classes.infoContainer}>
      {children}
    </div>
  );
};

export default InfoContainer;
