import { ACTIONS, AuthActions } from '../../actions/auth';
import { AuthState } from './types';

export * from './types';

const initialState: AuthState = {
  isLoggedIn: false,
};

export default (state: AuthState = initialState, action: AuthActions) => {
  switch (action.type) {
    case ACTIONS.LOGIN:
      return { ...state, isLoggedIn: true, session: action.payload, loginInProgress: false };
    case ACTIONS.LOGOUT:
      return { ...state, isLoggedIn: false, session: initialState.session, loginInProgress: false };
    case ACTIONS.LOGIN_REQUEST:
      return { ...state, loginInProgress: true };
    default:
      return state;
  }
};
