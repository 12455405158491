import get from 'lodash/get';
import { AxiosResponse } from 'axios';
import { SeverityLevel } from '@microsoft/applicationinsights-common';

import { addNotification, addNotifications } from '../actions/notification/index';
import { extractErrorFromStatus200 } from '../utils/apiCallStatus';
import aiTracking from '../utils/aiTracking';


const session_ended = 'error.signature.expired';

// eslint-disable-next-line
function responseInterceptor(response: AxiosResponse<any>) {
  const { data } = response;
  const { hasError, errors } = extractErrorFromStatus200(data);

  if (hasError) {
    addNotifications(errors);
  }

  return response;
}

function errorInterceptor(error: Error) {
  const response = get(error, 'response');
  const statusCode = get(error, ['response', 'status'], null);

  const isBackendDown =
    response === undefined || (statusCode && (statusCode === 503 || statusCode === 500));

  if (isBackendDown) {
    aiTracking.catchError(error, SeverityLevel.Critical);
  }

  if (response === undefined) {
    // history.push('/server-error');
    addNotification(
      'error',
      'A network error occurred. Could be internet connection or service temporarily unavailable',
      3000
    );
  } else {
    if (statusCode) {
      if (
        statusCode === 401 &&
        (response || {}).data === session_ended
        //    &&  !authState.loginInProgress
      ) {
        // loginInProgress();
        // login();
      } else {
        const serverMessages = response.data.metadata.serverMessages;

        // structure of serverMessages is inconsistent, so we need multiple approaches
        addNotification(serverMessages.type, serverMessages[0].message, 3000);

        /*
        if (serverMessages) {
          for (const message of serverMessages) {
            addNotification(message.type, message.message, 3000);
          }
        }
         */
      }
    }
  }

  return Promise.reject(error);
}

export { errorInterceptor, responseInterceptor };
