import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ControlProps, Props } from 'react-select';
import {
  makeStyles,
  Theme,
  Popper,
  Grow,
  Paper,
  MenuItem,
  Chip,
  Typography,
} from '@material-ui/core';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';

import { OptionProps } from 'react-select/src/components/Option';
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueContainerProps } from 'react-select/src/components/containers';

import CancelIcon from '@material-ui/icons/Cancel';

export interface OptionType {
  label: string;
  value: string;
}

interface MenuStylesProps {
  anchorDomEl: HTMLDivElement | null;
  anchorBoundingRect: ClientRect | DOMRect | null;
  maxMenuHeight?: number;
  hasValue?: boolean;
  menuPlacement?: Props<OptionType>['menuPlacement'];
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.TextFieldProps}
      variant='outlined'
    />
  );
}

const useMenuStyles = makeStyles<Theme, MenuStylesProps>(theme => ({
  popper: ({ anchorDomEl, anchorBoundingRect }) => {
    if (!anchorDomEl || !anchorBoundingRect) return {};

    const topOffset = anchorBoundingRect.top + window.pageYOffset;

    return {
      zIndex: 9999,
      minWidth: anchorDomEl.clientWidth,
      position: 'absolute',
      transform: `translate3d(${anchorBoundingRect.left}px, ${topOffset}px, 0px)`,
      top: 0,
      left: 0,
    };
  },
  paper: ({ anchorBoundingRect, maxMenuHeight, hasValue, menuPlacement }) => {
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    let displayMenuAbove: boolean;

    if (menuPlacement === 'top') displayMenuAbove = true;
    else if (menuPlacement === 'bottom') displayMenuAbove = false;
    else if (anchorBoundingRect)
      displayMenuAbove = windowHeight - (anchorBoundingRect.top + (maxMenuHeight || 250)) < 0;
    else displayMenuAbove = false;

    return {
      position: 'absolute',
      zIndex: 1,
      margin: theme.spacing(1, 0.5),
      left: 0,
      right: 0,
      ...(displayMenuAbove ? { bottom: hasValue ? 66 : 60 } : { top: 0 }),
    };
  },
}));

function Menu(props: MenuProps<OptionType>) {
  const { maxMenuHeight, hasValue, innerProps, children, menuPlacement } = props;
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef<HTMLDivElement>(null);
  const anchorBoundingRect = anchorEl.current && anchorEl.current.getBoundingClientRect();

  const menuClasses = useMenuStyles({
    anchorDomEl: anchorEl.current,
    anchorBoundingRect,
    maxMenuHeight,
    hasValue,
    menuPlacement,
  });

  useEffect(() => {
    setIsOpen(Boolean(anchorEl));
  }, [anchorEl, setIsOpen]);

  return (
    <>
      <div ref={anchorEl} />
      {isOpen && (
        <Popper
          anchorEl={anchorEl.current}
          open
          className={menuClasses.popper}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
          {...innerProps}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper className={menuClasses.paper}>{children}</Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
}

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Placeholder(props: PlaceholderProps<OptionType>) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ padding: props.hasValue ? 10 : 14 }}
    >
      {props.children}
    </div>
  );
}

const IndicatorsContainer = () => null;

export const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorsContainer,
};
