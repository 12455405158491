import { ACTIONS, Session } from './types';
import { logout as loginProviderLogout } from '../../modules/auth';
import { store } from '../../store';

export * from './types';

export /**
 * Login takes place with a session token
 *
 * @param {Session} session
 */
const login = (session: Session) => {
  store.dispatch({
    type: ACTIONS.LOGIN,
    payload: session,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).loginSuccess = login;

export const loginInProgress = () => {
  store.dispatch({
    type: ACTIONS.LOGIN_REQUEST,
  });
};

export const logout = () => {
  loginProviderLogout();

  store.dispatch({
    type: ACTIONS.LOGOUT,
  });
};
