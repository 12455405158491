import React from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { directContactWithTalent } from '../../modules/applicationInsights/analytics';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

interface Props {
  link?: string;
  className?: string;
  children?: React.ReactNode;
  message?: string;
}

const TeamsLink: React.FC<Props> = ({ link, className, children, message }) => {
  const classes = useStyles();

  return (
    <a
      onClick={() => directContactWithTalent('teams')}
      target='_blank'
      rel='noopener noreferrer'
      className={clsx(classes.link, className)}
      href={`https://teams.microsoft.com/l/chat/0/0?users=${link}`}
    >
      {children ? children : link}
    </a>
  );
};

export default TeamsLink;
