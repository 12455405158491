import React from 'react';
import Link from '@material-ui/core/Link';
import { useSelector } from '../../../reduxHooks';
import { InputModes, SearchModes } from '../../../reducers/talentSearch';

interface Query {
  freeText: boolean;
  raw: string;
  autocorrect: string;
}
/**
 *
 *
 * @param {function} onAutoCorrect
 * @param {string} freeTextInputValue
 * 
 * @returns {div} a container with autocorrected text
 * @example <div>
      <p>
        <span>Did you mean: </span>
        <Link
          component='button'
          variant='body2'
          onClick={() => {
            onAutoCorrect(autocorrect);
          }}
        >
          {autocorrect}
        </Link>
      </p>
    </div>
 */
const AutoCorrect = ({
  onAutoCorrect,
  freeTextInputValue,
}: {
  onAutoCorrect: (value: string) => void;
  freeTextInputValue: string;
}) => {
  const talentSearchResponse = useSelector(state => state.talentSearch.talentSearchResponse.data);

  const searchMode = useSelector(state => state.talentSearch.searchMode);
  const inputMode = useSelector(state => state.talentSearch.inputMode);

  const searchResultMode = useSelector(state => state.talentSearch.talentSearchResponse.searchMode);

  const searchResultInputMode = useSelector(
    state => state.talentSearch.talentSearchResponse.inputMode
  );

  const query: Query | null = talentSearchResponse && talentSearchResponse.query;

  if (
    inputMode !== InputModes.freeText ||
    searchMode !== SearchModes.talentSearch ||
    searchResultMode !== SearchModes.talentSearch ||
    searchResultInputMode !== InputModes.freeText ||
    !query ||
    !freeTextInputValue
  ) {
    return null;
  }

  const { autocorrect, raw } = query;

  if (autocorrect === raw) {
    return null;
  }

  return (
    <div>
      <p>
        <span>Did you mean: </span>
        <Link
          component='button'
          variant='body2'
          onClick={() => {
            onAutoCorrect(autocorrect);
          }}
        >
          {autocorrect}
        </Link>
      </p>
    </div>
  );
};

export default AutoCorrect;
