import React, { useRef } from 'react';
import { PMI_TalentData } from '../../../actions/talentSearch';
import {
  Grid,
  Theme,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Divider,
  Button,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from '../../../reduxHooks';
import EmailLink from '../../Links/EmailLink';
import { ReactComponent as OutlookIcon } from '../../../static/icons/outlook.svg';
import TeamsLink from '../../Links/TeamsLink';
import { ReactComponent as TeamsIcon } from '../../../static/icons/teams.svg';
import { useLocation } from 'react-router';
import { extractExtraSkills } from '../../../utils/sortSkills';
import { env } from '../../../constants';
import uuidv1 from 'uuid/v1';

import SkillProfilingDashboardInfo from '../../InfoButton/buttons/SkillProfilingDashboardInfo';
import InfoContainer from '../../InfoButton/InfoContainer';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    height: '100%',
    marginTop: 0,
    paddingLeft: 6,
    paddingRight: 6,
  },
  gridColumn: {
    height: '100%',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    overflowY: 'auto',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '90%',
    },
  },
  columnHeading: {
    height: 14,
    fontWeight: 'bold',
    color: '#000',
  },
  dividerLeft: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  talentDetailContainer: {
    margin: theme.spacing(2, 0),
    overflowY: 'auto',
  },
  talentDetail: {
    display: 'flex',
  },
  talentEmail: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
  contactTFSBtn: {
    height: 20,
    width: 20,
    marginLeft: 10,
  },
  talentDetailHeading: {
    display: 'flex',
    alignItems: 'center',
  },
  talentDetailDescription: {
    marginRight: 15,
    fontSize: 14,
    color: '#999999',
    marginBottom: 10,
    '&::before': {
      content: ' :',
    },
  },
  talentDetailValue: {
    fontSize: 14,
  },
  columnContentContainer: {
    maxHeight: 352,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  skillColumnContainer: {
    maxHeight: 390,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: '30px',
  },
  table: {
    margin: `${theme.spacing(0)}px -13px`,
  },
  tableRow: {
    '&:nth-child(even)': {
      backgroundColor: '#F6F6F6',
    },
  },
  tableCell: {
    paddingLeft: 13,
    paddingRight: 13,
    borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 120,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

interface SearchResultDataProps {
  talent: PMI_TalentData;
}

const skillLabelMap: any = {
  selfReview: 'Self Review',
  competency: 'Competency',
  competencyPool: 'Competency Pool',
  managerReview: 'Manager Review',
  strengths: 'Strengths',
  internalRoleDetails: 'Internal XP',
  externalRoleDetails: 'External XP',
};

/**
 * SearchResultData shows the basic info of the expanded talent
 * (name, contact info, etc)
 * and makes possible to send an email or direct message with the appropriate buttons
 * 
 *
 * @param {PMI_TalentData} talent
 * 
 * @example <Tooltip
                  title='Email Manager'
                  aria-label='Email Manager'
                  placement={'top'}
                  enterDelay={300}
                >
                  <div>
                    <EmailLink link={talent.managerEmail.toLowerCase()}>
                      <OutlookIcon className={classes.contactTFSBtn} />
                    </EmailLink>
                  </div>
                </Tooltip>
 */
const SearchResultData: React.FC<SearchResultDataProps> = props => {
  const { talent } = props;
  // const { skillsExtra, specializations, proximities, skills } = talent;
  const searchMode = useSelector(state => state.talentSearch.searchMode);
  const isProjectRecommendations = useLocation().pathname === '/project-recommendation';

  const skillsRef = useRef(null);
  const specializationsRef = useRef(null);

  const availabilitypercent = useSelector(
    state => state.talentSearch.searchFilters.active.availabilitypercent
  );

  const availabilityFrom = useSelector(state => state.talentSearch.searchFilters.active.dateFrom);
  const availabilityTo = useSelector(state => state.talentSearch.searchFilters.active.dateTo);

  const classes = useStyles();

  const extra = talent.extra
    ? [...talent.extra].filter((e: any) => !e.prediction.find((i: string) => i === e.term))
    : [];

  const extraSkills: string[] = extractExtraSkills(talent.extra, talent.competency);

  return (
    <Grid container spacing={3} className={classes.gridContainer}>
      <Grid item xs={12} md={4} className={classes.gridColumn}>
        <div className={classes.talentDetailHeading}>
          <Typography className={classes.columnHeading}>General</Typography>
        </div>
        <div className={classes.talentDetailContainer}>
          <div className={classes.talentDetail}>
            <Typography className={classes.talentDetailDescription}>Name</Typography>
            <Typography>{`${talent.firstName} ${talent.lastName}`}</Typography>
          </div>
          <div className={classes.talentDetail}>
            <Typography className={classes.talentDetailDescription}>Personnel Number</Typography>
            <Typography className={classes.talentDetailValue}>{talent.supplyKey}</Typography>
          </div>
          <div className={classes.talentDetail}>
            <Typography className={classes.talentDetailDescription}>Email</Typography>
            <Typography className={classes.talentDetailValue}>
              {' '}
              {talent.email && talent.email !== 'N/A' ? (
                <EmailLink className={classes.talentEmail} link={talent.email}>
                  {talent.email}
                </EmailLink>
              ) : (
                'N/A'
              )}
            </Typography>
          </div>
          <div className={classes.talentDetail}>
            <Typography className={classes.talentDetailDescription}>Job Title</Typography>
            <Typography className={classes.talentDetailValue}>
              {talent.jobTitle ? talent.jobTitle : 'N/A'}
            </Typography>
          </div>
          <div className={classes.talentDetail}>
            <Typography className={classes.talentDetailDescription}>City</Typography>
            <Typography className={classes.talentDetailValue}>{talent.city}</Typography>
          </div>
          <div className={classes.talentDetail}>
            <Typography className={classes.talentDetailDescription}>Function Group</Typography>
            <Typography className={classes.talentDetailValue}>{talent.function}</Typography>
          </div>
          <div className={classes.talentDetail}>
            <Typography className={classes.talentDetailDescription}>Sub Function Group</Typography>
            <Typography className={classes.talentDetailValue}>
              {talent.subFunction ? talent.subFunction : 'N/A'}
            </Typography>
          </div>
          {!isProjectRecommendations && talent.rankPosition ? (
            <>
              {availabilitypercent ? (
                <div className={classes.talentDetail}>
                  <Typography className={classes.talentDetailDescription}>
                    Availability (FTE)
                  </Typography>
                  <Typography className={classes.talentDetailValue}>
                    {talent.availability && talent.availability.percentage >= 0
                      ? talent.availability.percentage
                      : 'N/A'}
                  </Typography>
                </div>
              ) : null}
              {availabilityFrom ? (
                <div className={classes.talentDetail}>
                  <Typography className={classes.talentDetailDescription}>
                    Availability From
                  </Typography>
                  <Typography className={classes.talentDetailValue}>
                    {talent.availability && talent.availability.fromDate
                      ? talent.availability.fromDate
                      : 'N/A'}
                  </Typography>
                </div>
              ) : null}
              {availabilityTo ? (
                <div className={classes.talentDetail}>
                  <Typography className={classes.talentDetailDescription}>
                    Availability To
                  </Typography>
                  <Typography className={classes.talentDetailValue}>
                    {talent.availability && talent.availability.toDate
                      ? talent.availability.toDate
                      : 'N/A'}
                  </Typography>
                </div>
              ) : null}
            </>
          ) : null}
          <div className={classes.talentDetail}>
            <Typography className={classes.talentDetailDescription}>Manager</Typography>
            <Typography>{talent.managerName}</Typography>
            {talent.managerEmail && talent.managerEmail !== 'N/A' ? (
              <>
                {' '}
                <Tooltip
                  title='Email Manager'
                  aria-label='Email Manager'
                  placement={'top'}
                  enterDelay={300}
                >
                  <div>
                    <EmailLink link={talent.managerEmail.toLowerCase()}>
                      <OutlookIcon className={classes.contactTFSBtn} />
                    </EmailLink>
                  </div>
                </Tooltip>
                <Tooltip
                  title='Chat with Manager'
                  aria-label='Chat with Manager'
                  placement={'top'}
                  enterDelay={300}
                >
                  <div>
                    <TeamsLink link={talent.managerEmail}>
                      <TeamsIcon className={classes.contactTFSBtn} />
                    </TeamsLink>
                  </div>
                </Tooltip>
              </>
            ) : null}
          </div>
          <div className={classes.talentDetail}>
            <InfoContainer>
              <Link
                href={`https://${env}skilldashboard.pmicloud.biz/SherlockWithParams/?employee_id=${talent.supplyKey}`}
                className={classes.link}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Button size='small' variant='outlined' color='primary'>
                  Skill Profiling Dashboard
                </Button>
              </Link>
              <SkillProfilingDashboardInfo top={5} right={-20} position={'bottom-end'} />
            </InfoContainer>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={6}
        md={5}
        ref={skillsRef}
        className={clsx(classes.gridColumn, classes.dividerLeft, classes.skillColumnContainer)}
      >
        <Typography style={{ marginBottom: 15 }} className={classes.columnHeading}>
          Competencies
        </Typography>
        <Divider />
        <div className={classes.table}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {searchMode === 'talentProfiles' && (
                  <TableCell className={classes.tableCell}>
                    <Typography variant='caption' gutterBottom>
                      Proficiency
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {talent.competency &&
                talent.competency.map((skill, i) => (
                  <TableRow className={classes.tableRow} key={skill.name + uuidv1()}>
                    <TableCell className={classes.tableCell} component='th' scope='row'>
                      {skill.name}
                    </TableCell>
                    {searchMode === 'talentProfiles' && (
                      <TableCell className={classes.tableCell} component='th' scope='row'>
                        {skill.proficiency ? skill.proficiency : 'NA'}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              {extraSkills &&
                extraSkills.map((skill, i) => (
                  <TableRow className={classes.tableRow} key={skill + uuidv1()}>
                    <TableCell className={classes.tableCell} component='th' scope='row'>
                      {skill}
                    </TableCell>
                    {searchMode === 'talentProfiles' && (
                      <TableCell className={classes.tableCell} component='th' scope='row'>
                        {''}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        {extra && extra[0] ? (
          <>
            <Typography
              style={{ marginBottom: 15, marginTop: 25 }}
              className={classes.columnHeading}
            >
              Other Found Competencies
            </Typography>
            <Divider />
            <div className={classes.table}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography variant='caption' gutterBottom>
                        Partial Match
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant='caption' gutterBottom>
                        Prediction
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant='caption' gutterBottom>
                        Source
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extra.map((e: any) => (
                    <TableRow className={classes.tableRow} key={e.term}>
                      <TableCell className={classes.tableCell} component='th' scope='row'>
                        {e.term}
                      </TableCell>
                      <TableCell className={classes.tableCell} component='th' scope='row'>
                        {e.prediction.join(', ')}
                      </TableCell>
                      <TableCell className={classes.tableCell} component='th' scope='row'>
                        {e.source.map((i: string) => skillLabelMap[i]).join(', ')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        ) : null}
      </Grid>
      <Grid ref={specializationsRef} item xs={6} md={3} className={clsx(classes.gridColumn)}>
        <div className={classes.columnContentContainer} style={{ maxHeight: 390 }}>
          <Typography
            variant='h6'
            component='h5'
            color='textSecondary'
            className={classes.columnHeading}
            style={{ marginBottom: 15 }}
          >
            Previous Experience
          </Typography>
          <Divider />
          <div className={classes.table}>
            <Table size='small'>
              <TableBody>
                {talent.internalExperience.map((experience: string, index) => (
                  <TableRow key={experience + index}>
                    <TableCell className={classes.tableCell} component='th' scope='row'>
                      {experience}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          {talent.jobExperience && (
            <>
              <Typography variant='h6' component='h5' color='textSecondary'>
                {'Project Experience'}
              </Typography>
              <Table size='small' className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>Project</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                </TableBody>
              </Table>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default SearchResultData;
