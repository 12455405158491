import { SeverityLevel, IEventTelemetry } from '@microsoft/applicationinsights-common';

import { isProd, inStaging } from '../constants';
import { AppInsight } from '../modules/applicationInsights/types';
import history from '../history';

// lazy loading it, cause the appInsight lib is like 160kb.
const appInsightPromise = import('../modules/applicationInsights/TelemetryService');

const shouldTrack = isProd || inStaging;

class AiTracking {
  ai?: AppInsight;
  constructor() {
    this.ai = undefined;
  }
  async loadAppInsight() {
    const appInsight = await appInsightPromise;

    this.ai = appInsight.default;
    this.ai.initialize({ history: history });
  }
  async init() {
    // we only track it in https://sherlock-scheduling.accenture.com
    // and https://dev.sherlock-scheduling.accenture.com
    if (!shouldTrack) {
      return;
    }

    await this.loadAppInsight();
  }
  async catchError(error: Error, Level: SeverityLevel = SeverityLevel.Error) {
    if (!shouldTrack) {
      return;
    }

    if (!this.ai) {
      await this.loadAppInsight();
    }

    if (this.ai && this.ai.appInsights) {
      this.ai.appInsights.trackException({
        error,
        severityLevel: Level,
      });
    }
  }
  async sendEvent(data: IEventTelemetry) {
    if (!shouldTrack) {
      return;
    }

    if (!this.ai) {
      await this.loadAppInsight();
    }

    if (this.ai && this.ai.appInsights) {
      this.ai.appInsights.trackEvent(data);
    }
  }
}

const aiTracking = new AiTracking();

export default aiTracking;
