export enum ACTIONS {
  ADD_MODAL = 'ADD_MODAL',
  REMOVE_MODAL = 'REMOVE_MODAL',
}
export interface AddModal {
  type: ACTIONS.ADD_MODAL;
  payload: React.ReactNode;
}
export interface RemoveModal {
  type: ACTIONS.REMOVE_MODAL;
}

export type ModalActions = RemoveModal | AddModal;
