import React from 'react';
import { Theme } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { useSelector } from '../../reduxHooks';
import { profilePictureSize } from '../../constants';

const availabilityIndicatorSize = 8; // 8px.

const useStyles = makeStyles((theme: Theme) => ({
  pictureContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  availabilityIndicator: ({ size }: { size: number }) => ({
    position: 'absolute',
    width: size + availabilityIndicatorSize,
    height: size + availabilityIndicatorSize,
    borderRadius: '50%',
  }),
}));

const availabilityColors = {
  available: 'rgb(81, 197, 106)',
  check: 'rgb(94, 175, 222)',
  stuffed: 'rgb(224, 98, 110)',
  default: 'rgb(169, 169, 169)',
};

interface Props {
  talent: any;
  useLetter?: boolean;
  forOpenRole?: boolean;
  placeholderStyle?: CSSProperties;
  size?: number;
  roleFte?: string;
  isFromRoleSearch?: boolean;
}

function getAvailabilityColor(talent: Props['talent'], roleFte?: Props['roleFte']): string {
  let availabilityColor = availabilityColors.default;
  let availability = talent.availability?.percentage;
  availability = availability !== undefined ? availability : talent.allocatedFte;
  
  if (roleFte && availability !== undefined)  {
    if (availability === 0) return availabilityColors.stuffed;

    if (roleFte && availability < +roleFte) return availabilityColors.stuffed;

    return availabilityColors.available;
  }

  return availabilityColor;
}

function getImageStyle(
  url: string,
  position: number,
  size: number
): {
  [key: string]: string;
} {
  // the default image size is 40px, if anything else use the following fomular:
  // position / defaultImageSize * newImageSize.
  return {
    backgroundPosition: `${(position / profilePictureSize) * size}px 0px`,
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    zIndex: '1',
  };
}

const PictureAvatar = ({
  talent,
  size,
  isImageReady,
  profilePicture,
  profilePicturePosition,
}: {
  size: number;
  talent: Props['talent'];
  isImageReady: boolean;
  profilePicture: any;
  profilePicturePosition: any;
}) => {

  return (
    <>
      {!isImageReady && <Skeleton variant="circle" width={size} height={size} />}
      {profilePicture !== null && profilePicturePosition !== null && (
        <span style={getImageStyle(profilePicture, profilePicturePosition, size)} />
      )}
    </>
  );
};

const TalentProfilePicture = ({
  talent,
  useLetter,
  placeholderStyle,
  forOpenRole,
  size = profilePictureSize,
  roleFte,
  isFromRoleSearch,
}: Props) => {
  const classes = useStyles({ size });

  // open role doesn't have availabilityColors;
  const backgroundColor = getAvailabilityColor(talent, roleFte);

  let profilePicture = null;

  const { profileImages, lookupTable } = useSelector(state => state.profilePictures);

  const profilePictureSpec = lookupTable[talent.supplyKey] || null;

  let profilePicturePosition = null;

  if (profilePictureSpec) {
    const { imageId, position } = profilePictureSpec;

    profilePicturePosition = position;
    profilePicture = profileImages[imageId];
  }

  const isImageReady = profilePicture !== null && profilePicturePosition !== null;

  return (
    <Tooltip
      title={
        talent.master
          ? `${talent.master[0].firstName} ${talent.master[0].lastName}`
          : `${talent.firstName} ${talent.lastName}`
      }
      placement='bottom-start'
      enterDelay={300}
      key={talent.supplyKey}
    >
      <div className={classes.pictureContainer}>
        <div className={classes.availabilityIndicator} style={{ backgroundColor: isImageReady ? backgroundColor : 'rgba(0, 0, 0, 0.11)' }}>
        </div>
        <PictureAvatar
            talent={talent}
            size={size}
            isImageReady={isImageReady}
            profilePicture={profilePicture}
            profilePicturePosition={profilePicturePosition}
          />
      </div>
    </Tooltip>
  );
};

TalentProfilePicture.defaultProps = {
  placeholderStyle: {
    position: 'relative',
  },
};

export default TalentProfilePicture;
