import {
  ACTIONS,
  SuggestionCriteria,
  OpenRolesPayload,
  AutocompleteTypes,
  FetchAutocompletePayload,
  SearchFilters,
  SortObject,
  XAIdata,
  EmployeeData,
  AllocationData
} from './types';
import { store, sherlockBackend } from '../../store';
import { defaultFilters } from '../../reducers/openRoles';
import Cookie from 'cookie-universal'

export * from './types';

const cookies = Cookie()

const filtersMap: any = {
  roleNames: 'roleName',
  functions: 'function',
  projectNames: 'projectName',
  demandSubFunctions: 'demandSubFunction',
};

export const fetchOpenRoles = async () => {
  const openRolesState = store.getState().openRoles;
  const pagigation = openRolesState.pagigation;
  const offset = pagigation.currentPage * pagigation.rowsPerPage;

  const autoComplete = openRolesState.autoComplete;
  const selectedFilterItem = autoComplete.selectedFilterItem;

  const mappedFilters: any = {};

  const sorting = openRolesState.rolesSorting;

  const params: { [key: string]: any } = {
    offset,
    sortBy: sorting.orderBy,
    sortAsc: '' + (sorting.order === 'asc'),
    ...mappedFilters,
  };

  if (selectedFilterItem && selectedFilterItem !== 'placeholder' && autoComplete.value) {
    params[
      filtersMap[selectedFilterItem.value] || selectedFilterItem.value
    ] = autoComplete.value.map(i => i.value);
  }

  return sherlockBackend<OpenRolesPayload>(
    {
      method: 'get',
      url: '/roles/demand',
      params,
    },
    {
      beforeRequest: () => ({ type: ACTIONS.FETCH_OPEN_ROLES_INIT }),
      onSuccess: response => ({
        type: ACTIONS.FETCH_OPEN_ROLES,
        payload: response.data,
      }),
      onError: () => ({
        type: ACTIONS.FETCH_OPEN_ROLES_FAILURE,
      }),
    }
  );
};

export const fetchXAIdata = async (supplyKey: number, demandKey: string, numOfData?: number) => {
  return sherlockBackend<XAIdata>(
    {
      method: 'get',
      url: '/roles/xai',
      params: {
        demandKey: demandKey,
        supplyKey: supplyKey,
      },
    },
    {
      beforeRequest: () => ({ type: ACTIONS.INIT_XAI_DATA, payload: numOfData }),
      onSuccess: response => {
        return {
          type: ACTIONS.FETCH_XAI_DATA,
          payload: response.data,
        };
      },
    }
  );
};

export const addBookedDemands = (demandKey: string) => {
  store.dispatch({
    type: ACTIONS.ADD_BOOKED_DEMANDS,
    payload: demandKey
  })
}

export const setBookedDemands = () => {
  const bookedDemands = store.getState().openRoles.bookedDemands;
  const cookieSet = cookies.get('bookedDemand');
  if(bookedDemands.demands.size === 0 && cookieSet){
    store.dispatch({
      type: ACTIONS.SET_BOOKED_DEMANDS,
      payload: cookieSet
    })
  }
}


export const employeeSearch = async (talentName: String, dateFrom: string, dateTo: string) => {
  return sherlockBackend<EmployeeData>(
    {
      method: 'get',
      url: '/roles/user_data',
      params: {
        userName: talentName,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    },
    {
      onSuccess: response => {
        store.dispatch({
          type: ACTIONS.FETCH_EMPLOYEE_INFO,
          payload: response.data,
        });
      },
    }
  );
};

export const allocateResource = async (
  supplyId: number,
  demandId: string,
  demandFte: string,
  dateFrom: string,
  toDate: string,
  supplyName?: string,
) => {
  return sherlockBackend<AllocationData>(
    {
      method: 'post',
      url: '/roles/allocate',
      data: {
        supplyId: supplyId,
        supplyName: supplyName,
        demandId: demandId,
        demandFte: demandFte,
        fromDate: new Date(dateFrom),
        toDate: new Date(toDate),
      },
    },
    {
      onSuccess: response => {
        return {
          type: ACTIONS.FETCH_ALOCATION_INFO,
          payload: response.data,
        };
      },
    }
  );
};

export const onFilter = (category: string) => {
  store.dispatch({
    type: ACTIONS.ON_FILTER,
    payload: category,
  });
};

export const onChange = (value: SuggestionCriteria[]) => {
  // @ts-ignore
  store.dispatch({
    type: ACTIONS.ON_INPUT_CHANGE,
    payload: value,
  });
};

export const setPage = (page: number) => {
  store.dispatch({
    type: ACTIONS.SET_PAGE,
    payload: page,
  });
  fetchOpenRoles();
};

export const onSort = (sort: SortObject) => {
  store.dispatch({
    type: ACTIONS.SET_SORT,
    payload: sort,
  });
  fetchOpenRoles();
};

export const fetchAutocomplete = async (subjectMatter: AutocompleteTypes) => {
  return sherlockBackend<FetchAutocompletePayload>(
    {
      method: 'get',
      url: `/autocomplete`,
      params: {
        subject_matter: subjectMatter,
      },
    },
    {
      onSuccess: response => ({
        type: ACTIONS.FETCH_AUTOCOMPLETE,
        payload: { subjectMatter: subjectMatter, data: response.data },
      }),
    }
  );
};

export const setFilters = (searchFilters: Partial<SearchFilters>) => {
  store.dispatch({
    type: ACTIONS.SET_FILTERS,
    payload: {
      ...defaultFilters,
      ...store.getState().openRoles.searchFilters,
      ...searchFilters,
    },
  });
};

export const resetFilters = () => {
  store.dispatch({ type: ACTIONS.RESET_FILTERS, payload: defaultFilters });
  fetchOpenRoles();
};

export const fetchProfile = async (id: number) => {
  let res;

  await sherlockBackend<FetchAutocompletePayload>(
    {
      method: 'get',
      url: `/users/profile?ids[]=${id}`,
    },
    {
      onSuccess: response => {
        res = response;
      },
    }
  );

  return res;
};
