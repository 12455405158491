import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MuiAppBar from '@material-ui/core/AppBar';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Theme,
  Popover,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Button,
  IconButton,
} from '@material-ui/core';
import clsx from 'clsx';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import MailIcon from '@material-ui/icons/Mail';
import MoreIcon from '@material-ui/icons/MoreVert';
import { logout } from '../actions/auth';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from '../reduxHooks';
import { fetchProfilePicture } from '../actions/talentSearch';
import { ReactComponent as SherlockLogo } from '../static/icons/sherlockLogo.svg';
import { ReactComponent as PMILogo } from '../static/icons/pmiLogo.svg';
import RecommendSherlockLink from './Links/RecommendSherlockLink';
import SherlockIcon from './Icons';
import ShareIcon from '@material-ui/icons/Share';
import { currentEnv } from '../constants';

interface AppBarProps {
  isDrawerOpen: boolean;
  openDrawer: Function;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottom: '1px solid #ccc',
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
  sherlockLogo: {
    height: 60,
    width: 60,
    margin: theme.spacing(1, 2, 1, 8),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 2, 1, 2),
    },
  },
  pmiLogo: {
    height: 100,
    width: 100,
    margin: theme.spacing(0, 2, 0, 8),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 2, 0, 2),
    },
  },
  appNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: '#000',
  },
  appName: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    letterSpacing: '.33rem',
  },
  appNameTagline: {
    letterSpacing: '.1rem',
    fontSize: '12pt',
  },
  userButton: {
    padding: '5px 15px',
    marginLeft: 10,
  },
  userName: {
    fontSize: '14pt',
    textTransform: 'none',
    marginRight: 10,
  },
  userImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px'
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up(850)]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up(850)]: {
      display: 'none',
    },
  },
}));

const mobileMenuItemStyle = { color: 'inherit', display: 'flex', textDecoration: 'none' };

/**
 * Appbar is a sidenav
 * @constructor
 * @returns {div} a container with the header and links to all components
 */
const AppBar: React.FC<AppBarProps> = props => {
  const { isDrawerOpen: drawerOpen, openDrawer } = props;

  const firstName = useSelector(
    state => (state.auth.session && state.auth.session.accessTokenPayload.given_name) || ''
  );

  const lastName = useSelector(
    state => (state.auth.session && state.auth.session.accessTokenPayload.family_name) || ''
  );

  const enterpriseID = 'me';

  const profilePictures = useSelector(state => state.talentSearch.profilePictures);

  const profilePicture = profilePictures[enterpriseID];

  useEffect(() => {
    if (!profilePicture) {
      fetchProfilePicture(enterpriseID);
    }
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<(EventTarget & HTMLElement) | null>(
    null
  );

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const renderMenu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Popover>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMobileMenuClose}>
        <RecommendSherlockLink style={mobileMenuItemStyle}>
          <IconButton color='inherit'>
            <SherlockIcon>
              <ShareIcon />
            </SherlockIcon>
          </IconButton>
          <p>Recommend Sherlock</p>
        </RecommendSherlockLink>
      </MenuItem>
      <MenuItem disabled onClick={handleMobileMenuClose}>
        <IconButton color='inherit'>
          <MailIcon />
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton color='inherit'>
          <AccountCircle />
        </IconButton>
        <p>{`${firstName} ${lastName}`}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <MuiAppBar position='relative' className={classes.appBar}>
        <Toolbar disableGutters={true}>
          <IconButton
            color='inherit'
            aria-label='Open drawer'
            onClick={() => openDrawer()}
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Link to='/'>
            <SherlockLogo className={classes.sherlockLogo} />
          </Link>
          <Link to='/' className={classes.appNameContainer}>
            <Typography className={classes.appName} variant='h5' noWrap>
              Sherlock {currentEnv}
            </Typography>
            <span className={classes.appNameTagline}>Sherlock Project Skills Matching</span>
          </Link>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <PMILogo className={classes.pmiLogo} />
            <IconButton color='inherit'>
              <RecommendSherlockLink>
                <SherlockIcon>
                  <ShareIcon />
                </SherlockIcon>
              </RecommendSherlockLink>
            </IconButton>
            <Button
              className={classes.userButton}
              aria-owns={isMenuOpen ? 'material-appbar' : undefined}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'
              style={{ marginRight: drawerOpen ? 0 : 24 }}
              size='large'
            >
              <div className={classes.userName}>{`${firstName} ${lastName}`}</div>
              <div className={classes.userImageContainer}>
                {profilePicture ? <img src={profilePicture} style={{ borderRadius: '50%', height: '100%' }} alt={`${firstName} ${lastName}`} /> : <Skeleton variant="circle" width="40px" height="40px" />}
              </div>
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton aria-haspopup='true' onClick={handleMobileMenuOpen} color='inherit'>
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </MuiAppBar>
      {renderMenu}
      {renderMobileMenu}
    </>
  );
};

export default AppBar;
