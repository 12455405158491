/*
Using them as a function so that later on we can easily add more stuff to it if needed.
*/

import { IEventTelemetry } from '@microsoft/applicationinsights-common';

import { ContactMethod, TalentSearchQuery, TalentSearchQueryWithFilters } from './types';
import aiTracking from '../../utils/aiTracking';

// eslint-disable-next-line
function withAI(func: (...args: any) => IEventTelemetry) {
  // eslint-disable-next-line
  function fn(...args: any) {
    return aiTracking.sendEvent(func(...args));
  }

  return fn;
}

export const talentSearchQuery = withAI(
  (data: TalentSearchQuery | TalentSearchQueryWithFilters) => {
    return {
      name: 'Talent_Search_Query',
      properties: {
        ...data,
      },
    };
  }
);
export const directContactWithTalent = withAI((method: ContactMethod) => {
  return {
    name: 'Direct_Contact_With_Talent',
    properties: {
      method,
    },
  };
});

export const viewCV = withAI(() => {
  return {
    name: 'View_CV',
  };
});

export const directContactWithManager = withAI((method: ContactMethod) => {
  return {
    name: 'Direct_Contact_With_Manager',
    properties: {
      method,
    },
  };
});

export const recommendTalentToColleague = withAI(() => {
  return {
    name: 'Recommend_Talent_To_Colleague',
  };
});

export const recommendSherlockToColleague = withAI(() => {
  return {
    name: 'Recommend_Sherlock_To_Colleague',
  };
});
