import React from 'react';
import { useSelector } from '../../reduxHooks';

const ModalDisplay = () => {
  const modal = useSelector(state => state.modal);

  return <>{modal}</>;
};

export default ModalDisplay;
