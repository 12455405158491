import React from 'react';
import { SeverityLevel } from '@microsoft/applicationinsights-common';

import aiTracking from '../../utils/aiTracking';
import AppContent from './AppContent';
import { syncProfilePicturesToReduxStore } from '../../utils/cacheProfileImages';

class App extends React.Component {
  async componentDidMount() {
    await aiTracking.init();
    await syncProfilePicturesToReduxStore();
  }
  componentDidCatch(error: Error) {
    aiTracking.catchError(error, SeverityLevel.Critical);
  }

  render() {
    // we don't show error page in sherlock;
    return <AppContent />;
  }
}

export default App;
