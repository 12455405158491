import React from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { directContactWithTalent } from '../../modules/applicationInsights/analytics';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export interface EmailLinkProps {
  link: string;
  className?: string;
  children?: React.ReactNode;
  forContactTalent?: boolean;
  onClick?: () => void;
  style?: object;
  data?: string;
}

/**
 * EmailLink lets the user send emails to talents
 * This component is default for contacting a talent to see if he/she is interested in a new project.
 *
 *
 * @param {*} {
 *   link,
 *   className,
 *   children,
 *   forContactTalent,
 *   onClick,
 *   style,
 *   data,
 * }
 * @returns {a} a default link for sending emails to a talent
 */
const EmailLink: React.FC<EmailLinkProps> = ({
  link,
  className,
  children,
  forContactTalent,
  onClick,
  style,
  data,
}) => {
  const classes = useStyles();

  const text = data ? data : '';

  return (
    <a
      onClick={() => {
        onClick && onClick();

        if (forContactTalent) directContactWithTalent('email');
      }}
      className={clsx(classes.link, className)}
      href={children ? `mailto:${link}${text}` : `mailto:${encodeURIComponent(link)}${text}`}
      style={style}
    >
      {children ? children : link}
    </a>
  );
};

EmailLink.defaultProps = {
  forContactTalent: true,
  onClick: () => {},
};

export default EmailLink;
