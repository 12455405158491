import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#39aefe',
      light: '#85CEFF',
      dark: '#3078C6',
    },
    secondary: { main: '#008eff', light: '#00baff', dark: '#004dff' },
  },
  mixins: { toolbar: { minHeight: 76 } },
  typography: {
    /*
    H5 and H6 were already being used everywhere before we have the style guide from oru designer
    So, h5 and h6 will be used as a replacement for h1 and h2 from now on.
    */
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1rem',
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: '1rem',
    },
    body2: {
      fontSize: '.875rem',
    },
    button: {
      fontSize: '.875rem',
    },
  },
});
