import React from 'react';
import { isArray } from 'util';
import renderChip from './RenderChip';
import { IconButton, Tooltip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const Chips = ({
  filters,
  namesMap,
  setFilters,
  defaults,
}: {
  filters: any;
  namesMap: object;
  setFilters: any;
  defaults: any;
}) => {
  const filtersArray = Object.keys(filters); // this is the array where the filter values are set

  return (
    <>
      {filtersArray.map(i => {
        // go through every filter value set
        const items = [];

        if (i === 'date') {
          items.push({ name: i, value: filters[i].toLocaleDateString() }); // transform the date to an easily readable format
        } else if (isArray(filters[i])) {
          filters[i].forEach((e: string) => items.push({ name: i, value: e }));
        } else {
          items.push({ name: i, value: filters[i] });
        }

        return items.map(i => {
          if (!namesMap[i.name as keyof typeof namesMap]) return null;

          return renderChip(namesMap[i.name as keyof typeof namesMap], i.value + '', () => {
            const name = i.name;

            const filterObj = {
              [name]: isArray(filters[i.name])
                ? filters[name].filter((o: string) => o !== i.value)
                : defaults[name],
            };

            setFilters(filterObj, 'reset filters');
          });
        });
      })}
      {filtersArray.length > (filters.availability ? 4 : 1) ? (
        <IconButton
          color='primary'
          style={{ marginTop: '-9px' }}
          onClick={() => setFilters(defaults)}
        >
          <Tooltip title='Clear all filters'>
            <Clear />
          </Tooltip>
        </IconButton>
      ) : null}
    </>
  );
};

export default Chips;
