import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { filter } from 'lodash/fp';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { NoticeProps, MenuProps } from 'react-select/src/components/Menu';
import { Theme, Typography, Paper, Chip, MenuItem } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { OptionProps } from 'react-select/src/components/Option';
import { ControlProps } from 'react-select/src/components/Control';
import { SearchModes } from '../../../reducers/talentSearch';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from '../../../reduxHooks';
import { SherlockState } from '../../../reducers';

export interface Option {
  value: string;
  label: string;
}

const buildSuggestions = (
  autocomplete: SherlockState['talentSearch']['autocomplete']
): [Option[], Option[], Option[]] => {
  const skillsMapper = (skill: string) => ({
    value: skill
      .toLowerCase()
      .split(' ')
      .join('_'),
    label: skill,
  });

  const mapSuggestions = (
    autocompleteSubject: string[] | undefined,
    mapperFunction: typeof skillsMapper
  ) =>
    autocompleteSubject && Array.isArray(autocompleteSubject)
      ? autocompleteSubject.map(mapperFunction)
      : [];

  return [
    mapSuggestions(autocomplete.competency && autocomplete.competency.items, skillsMapper),
    mapSuggestions(autocomplete.userNames && autocomplete.userNames.items, skillsMapper),
    mapSuggestions(autocomplete.rawUserNames && autocomplete.rawUserNames.items, skillsMapper),
  ];
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: 10,
    maxWidth: 'calc(100% - 60px)',
    minHeight: 40,
  },
  input: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    minHeight: '40px',
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

const Control = (props: ControlProps<Option>) => {
  const { children, innerRef, innerProps } = props;

  return (
    <div className={props.selectProps.classes.input} ref={innerRef} {...innerProps}>
      {children}
    </div>
  );
};

const NoOptionsMessage = (props: NoticeProps<Option>) => (
  <Typography
    color='textSecondary'
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const Option = (props: OptionProps<Option>) => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component='div'
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

const Placeholder = (props: PlaceholderProps<Option>) => (
  <Typography
    color='textSecondary'
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const ValueContainer = (props: ValueContainerProps<Option>) => (
  <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
);

const MultiValue = (props: MultiValueProps<Option>) => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={clsx(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    onDelete={props.removeProps.onClick}
    deleteIcon={<CancelIcon {...props.removeProps} />}
  />
);

const Menu = (props: MenuProps<Option>) => (
  <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
    {props.children}
  </Paper>
);

const IndicatorsContainer = () => null;

const AutocompleteSearchBarComponents = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
  IndicatorsContainer,
};

interface AutocompleteSearchBarProps {
  maxOptionsDisplayed?: number;
  placeholder?: string;
  searchMode: SearchModes;
  value: ValueType<Option>;
  onChange?: (value: ValueType<Option>) => void;
  onEnter?: () => void;
  disabled?: boolean;
}

const AutocompleteSearchBar: React.FC<AutocompleteSearchBarProps> = props => {
  const { onChange, value, searchMode, onEnter, disabled } = props;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const autocomplete = useSelector(state => state.talentSearch.autocomplete);
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const [displayedOptions, setDisplayedOptions] = useState<Option[]>([]);
  const [skillSuggestions, setSkillSuggestions] = useState<Option[]>([]);
  const [employeeSuggestions, setEmployeeSuggestions] = useState<Option[]>([]);
  const [rawEmployeeSuggestions, setRawEmployeeSuggestions] = useState<Option[]>([]);

  const maxOptionsDisplayed = props.maxOptionsDisplayed || 200;
  const placeholder = props.placeholder || 'Search Sherlock';

  useEffect(() => {
    const [skillSuggestions, employeeSuggestions, rawEmployeeSuggestions] = buildSuggestions(autocomplete);

    setSkillSuggestions(skillSuggestions);
    setEmployeeSuggestions(employeeSuggestions);
    setRawEmployeeSuggestions(rawEmployeeSuggestions);

    if (searchMode === SearchModes.profileMatching) {
      setDisplayedOptions(employeeSuggestions.slice(0, maxOptionsDisplayed));
    } else if (searchMode === SearchModes.rawProfileMatching) {
      setDisplayedOptions(rawEmployeeSuggestions.slice(0, maxOptionsDisplayed));
    } else {
      setDisplayedOptions(skillSuggestions.slice(0, maxOptionsDisplayed));
    }
  }, [autocomplete, searchMode, maxOptionsDisplayed]);

  const handleInputChange = useCallback(
    (inputValue: string) => {
      let option: Option[]
      if (searchMode === SearchModes.profileMatching) {
        option = employeeSuggestions as Option[]
      } else if (searchMode === SearchModes.rawProfileMatching) {
        option = rawEmployeeSuggestions as Option[]
      } else {
        option = skillSuggestions as Option[]
      }

      const displayedOptions = filter(
        ({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()), (option)
      ).slice(0, maxOptionsDisplayed);

      setDisplayedOptions(displayedOptions);
    },
    [searchMode, maxOptionsDisplayed, skillSuggestions, employeeSuggestions, rawEmployeeSuggestions]
  );

  useEffect(() => {
    handleInputChange('');
  }, [searchMode, handleInputChange]);

  const selectStyles = {
    container: (base: any) => ({
      ...base,
      zIndex: '999',
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: (base: any) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={`${classes.root} ${'search-bar'}`}>
      <Select
        isClearable={true}
        classes={classes}
        styles={selectStyles}
        options={displayedOptions}
        components={AutocompleteSearchBarComponents}
        value={value}
        onInputChange={handleInputChange}
        onChange={onChange}
        placeholder={placeholder}
        isMulti={searchMode === SearchModes.talentSearch}
        onKeyDown={event => event.key === 'Enter' && !menuIsOpen && onEnter && onEnter()}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        isDisabled={disabled}
      />
    </div>
  );
};

export default AutocompleteSearchBar;
