import { createStore, compose } from 'redux';
import reducers from './reducers';
import { sherlockAxios } from './modules/apiClient';
import { createReduxios } from './modules/reduxios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(reducers, composeEnhancers());

export const sherlockBackend = createReduxios(store, sherlockAxios);
