import {
  ACTIONS,
  AutocompleteTypes,
  TalentSearchPayload,
  FetchAutocompletePayload,
  SearchFilters,
  TalentSearchQueryString,
  PMI_TalentData,
} from './types';
import { sherlockBackend, store } from '../../store';
import { AxiosResponse } from 'axios';
import { defaultFilters, SearchModesType } from '../../reducers/talentSearch';
import { fetchTalentProfilePictures } from '../profilePictures';
import { talentSearchQuery } from '../../modules/applicationInsights/analytics';
import { isArray } from 'util';
import { Option } from '../../components/TalentSearch/SearchBar/AutocompleteSearchBar';

export * from './types';

/**
 * Functions on this file (src\actions\talentSearch\index.ts)
 * save the payload to the state (using redux).
 */

/**
 * Changes between Talent Search and Profile Match views.
 *
 * @param {SearchModesType} mode
 */
export const toggleSearchMode = (mode: SearchModesType) => {
  store.dispatch({
    type: ACTIONS.TOGGLE_SEARCH_MODE,
    payload: mode,
  });
};

/**
 * Users have the possibility to search with or without autocomplete.
 *
 *@function toggleInputMode
 *
 *
 */

export const toggleInputMode = () => {
  store.dispatch({
    type: ACTIONS.TOGGLE_INPUT_MODE,
  });
};

const talentSearchResponseHandler = (
  response: AxiosResponse<TalentSearchPayload>,
  autocompleteValue?: Option
) => {
  if (typeof response.data === 'string') {
    throw new Error(
      'Talent search results contain invalid JSON characters, ' +
        'therefore axios cannot parse the response.'
    );
  }

  if (response.data) {
    let talents = [];
    
    if (response.data.referenceUser) {
      talents.push(response.data.referenceUser);
    }

    talents.push(response.data.items);
    
    fetchTalentProfilePictures(talents.flat());
  }

  return {
    type: ACTIONS.TALENT_SEARCH,
    payload: response.data,
    searchMode: store.getState().talentSearch.searchMode,
    inputMode: store.getState().talentSearch.inputMode,
    autocompleteValue:
      autocompleteValue || store.getState().talentSearch.talentSearchResponse.autocompleteValue,
  };
};

const talentProfilesSearchResponseHandler = (response: AxiosResponse<TalentSearchPayload>) => {
  if (typeof response.data === 'string') {
    throw new Error(
      'Talent search results contain invalid JSON characters, ' +
        'therefore axios cannot parse the response.'
    );
  }

  if (response.data) {
    fetchTalentProfilePictures(response.data.items);
  }

  return {
    type: ACTIONS.PROFILES_SEARCH,
    payload: response.data,
  };
};

export const updateLocalTalents = (id: string, fields: any) => {
  const reduxStore = store.getState();
  const talentSearchResponse = reduxStore.talentSearch.talentSearchResponse;

  const searchResults =
    talentSearchResponse && talentSearchResponse.data && talentSearchResponse.data.items;

  const talent =
    searchResults && searchResults[0] && searchResults.find((i: any) => i.enterpriseID === id);

  if (!talent) return;

  for (const prop in fields) {
    // @ts-ignore
    talent[prop] = fields[prop];
  }

  store.dispatch({
    type: ACTIONS.UPDATE_TALENTS,
    payload: talentSearchResponse,
  });
};

/**
 * Talent Search results
 * @function talentSearch
 *
 *
 * @param {string} [searchString]
 * @param {*} [autocompleteValue]
 * @param {boolean} [isFiltersUpdated]
 * @returns {Promise}
 */
export const talentSearch = async (
  searchString?: string,
  autocompleteValue?: any,
  isFiltersUpdated?: boolean
) => {
  const reduxStore = store.getState();
  const searchFilters = { ...reduxStore.talentSearch.searchFilters.active };
  const talentSearchResponse = reduxStore.talentSearch.talentSearchResponse.data;
  const pagination = reduxStore.talentSearch.pagination;
  const isNewRequest = searchString || autocompleteValue;

  const offset =
    isNewRequest || isFiltersUpdated ? 0 : pagination.currentPage * pagination.rowsPerPage;

  if (isNewRequest || isFiltersUpdated) {
    setPage(0);
  }

  Object.keys(searchFilters).forEach((i: string) => {
    const e = searchFilters[i as keyof SearchFilters];

    if (isArray(e) && e[0]) {
      searchFilters[i] = e.join();
    }
  });

  const params = {
    offset,
    limit: pagination.rowsPerPage,
    query:
      searchString ||
      (talentSearchResponse && talentSearchResponse.query && talentSearchResponse.query.raw),
    freeTextQuery: reduxStore.talentSearch.inputMode === 'freeText',
    searchMode: reduxStore.talentSearch.searchMode,
    dateTo: searchFilters.dateTo,
    dateFrom: searchFilters.dateFrom,
    availabilitypercent: searchFilters.availabilitypercent,
    ...searchFilters,
  };

  delete params.date;

  talentSearchQuery({
    ...params,
    inputModes: reduxStore.talentSearch.inputMode,
  });

  return sherlockBackend<TalentSearchPayload>(
    {
      method: 'get',
      url: '/talent_search',
      params,
    },
    {
      onSuccess: searchString
        ? response => {
            return talentSearchResponseHandler(response, autocompleteValue);
          }
        : talentSearchResponseHandler,
    }
  );
};

/**
 *
 * Sets or deletes filters for the talent search.
 *@function resetTalentSearchFilters
 */
export const resetTalentSearchFilters = () => {
  store.dispatch({ type: ACTIONS.RESET_FILTERS, payload: defaultFilters });

  return talentSearch();
};

export const setTalentSearchFilters = (searchFilters: Partial<SearchFilters>) => {
  store.dispatch({
    type: ACTIONS.SET_FILTERS,
    payload: {
      ...defaultFilters,
      ...store.getState().talentSearch.searchFilters,
      ...searchFilters,
    },
  });
};

export const allTalentProfilesSearch = async () => {
  const reduxStore = store.getState();
  const searchFilters = reduxStore.talentSearch.talentProfilesResponse.filter.name;
  const pagination = reduxStore.talentSearch.pagination;

  const offset = pagination.currentPage * pagination.rowsPerPage;

  const params = {
    offset,
    limit: pagination.rowsPerPage, //5
  };

  if (searchFilters) {
    Object.assign(params, { name: searchFilters });
    setPage(0);
  }

  talentSearchQuery({
    ...params,
  });

  return sherlockBackend<TalentSearchPayload>(
    {
      method: 'get',
      url: '/users/profiles',
      params,
    },
    {
      onSuccess: response => {
        return talentProfilesSearchResponseHandler(response);
      },
    }
  );
};

export const setTalentProfileSearchFilters = async (incomingFilter: string[]) => {
  store.dispatch({
    type: ACTIONS.SET_TALENT_PROFILE_FILTERS,
    payload: {
      name: incomingFilter[incomingFilter.length - 1],
    },
  });
};

export const resetTalentProfileSearchFilters = async () => {
  store.dispatch({
    type: ACTIONS.RESET_TALENT_PROFILE_FILTERS,
    payload: {
      name: null,
    },
  });
};

export const talentSearchFromQueryString = async (searchParams: URLSearchParams) => {
  const searchParamsObject: TalentSearchQueryString = { ...defaultFilters };

  for (const [param, value] of searchParams) {
    searchParamsObject[param] = value;
  }

  const {
    availabilityPercent,
    dateTo,
    dateFrom,
    location,
    function: consumer,
  } = searchParamsObject;

  const params = {
    query: searchParamsObject.query,
    freeTextQuery: searchParamsObject.freeTextQuery,
    searchMode: searchParamsObject.searchMode,
    ...(availabilityPercent && { availabilityPercent: availabilityPercent }),
    ...(dateTo && {
      dateTo: dateTo,
    }),
    ...(dateFrom && {
      dateFrom: dateFrom,
    }),
    ...(location && location.length > 0 && { location: location }),
    ...(consumer && consumer.length > 0 && { functionGroups: consumer }),
  };

  return sherlockBackend<TalentSearchPayload>(
    {
      method: 'get',
      url: '/talent_search',
      params,
    },
    { onSuccess: talentSearchResponseHandler }
  );
};

export const fetchProfilePicture = async (enterpriseId: string) => {
  if (store.getState().talentSearch.profilePictures[enterpriseId]) return;

  const prefix = 'ids=';

  return sherlockBackend<Blob>(
    {
      method: 'get',
      url: `/users/images?${prefix}${enterpriseId}`,
      responseType: 'blob',
    },
    {
      onSuccess: response => {
        return {
          type: ACTIONS.FETCH_PROFILE_PICTURE,
          payload: {
            enterpriseId,
            profilePicture: URL.createObjectURL(response.data),
          },
        };
      },
    }
  );
};
/**
 * Gets all possible search parameters depending on what you are searching for (for example, employees names when searching for a talent)
 *  @function fetchAutocomplete
 *
 * @param {AutocompleteTypes} subjectMatter
 * @returns {Promise}
 */
export const fetchAutocomplete = async (subjectMatter: AutocompleteTypes) => {
  const keys = Object.keys(AutocompleteTypes).filter(
    value => AutocompleteTypes[value as keyof typeof AutocompleteTypes] === subjectMatter
  );

  if (keys.length <= 0) return;

  const key = keys[0] as keyof typeof AutocompleteTypes;

  if (store.getState().talentSearch.autocomplete[key]) return;

  return sherlockBackend<FetchAutocompletePayload>(
    {
      method: 'get',
      url: `/autocomplete`,
      params: {
        subject_matter: subjectMatter,
      },
    },
    {
      onSuccess: response => {
        if (response.status === 401) {
          return {
            type: ACTIONS.FETCH_AUTOCOMPLETE,
            payload: {
              subjectMatter: key,
              data: {
                items: ['Access denied...'],
              },
            },
          };
        }

        return {
          type: ACTIONS.FETCH_AUTOCOMPLETE,
          payload: { subjectMatter: key, data: response.data },
        };
      },
    }
  );
};

/**
 * Gets the data to populate the XAI graph on the expandable section of each employee in talent search
 *
 *@function fetchExplainableAIData
 *
 * @param {number} supplyKey
 * @returns {Promise}
 */

export const fetchExplainableAIData = async (supplyKey: PMI_TalentData['supplyKey']) => {
  const talentSearchResponse = store.getState().talentSearch.talentSearchResponse.data;
  const termsDetected = store.getState().talentSearch.talentSearchResponse.data.termsDetected;

  if (!talentSearchResponse)
    throw new Error(
      'No `talentSearchResponse` in redux store. Redux store MUST contain' +
        'a search response to request explainable AI data!'
    );

  const { metadata } = talentSearchResponse;

  return sherlockBackend(
    {
      method: 'post',
      url: 'talent_search/explain',
      data: {
        supplyKey: supplyKey,
        tracking: metadata.tracking,
        termsDetected: termsDetected,
      },
    },
    {
      onSuccess: response => ({
        type: ACTIONS.FETCH_EXPLAINABLE_AI_DATA,
        payload: { supplyKey, data: response },
      }),
    }
  );
};

export const setPage = (page: number) => {
  return store.dispatch({
    type: ACTIONS.SET_PAGE,
    payload: page,
  });
};
