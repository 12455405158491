import React from 'react';
import InfoButton from '../InfoButton';

interface props {
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  position?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const ResultsAiMatchFeedbackInfo: React.FC<props> = ({ top, right, bottom, left, position }) => {
  return (
    <InfoButton
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      position={position}
      refs={[
        { section: 'Talent Search Results', link: 'ts-results' },
        { section: 'Explainable AI', link: 'ts-ai' },
        { section: 'Match Feedback', link: 'ts-feedback' },
      ]}
    >
      <p>
        <b>SearchResult Data:</b>This will display general details, competencies matching the
        search, latest project experiences and more
      </p>
      <p>
        <b>Explainable AI:</b> Chart clarifying why the talent is positioned at a certain rank and
        how they are matched for the query.
      </p>
      <p>
        <b>Match Feedback:</b> Click on the Match Feedback tab to express satisfaction with the
        matched result
      </p>
    </InfoButton>
  );
};

export default ResultsAiMatchFeedbackInfo;
