import React from 'react';
import SearchResultsTable from './Table';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from '../../reduxHooks';
import { SearchModes } from '../../reducers/talentSearch';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowX: 'auto',
    minHeight: 100,
  },
  searchResultsTable: {
    width: '100%',
    marginTop: 80,
    padding: theme.spacing(0, 5, 6),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 6),
    },
  },
}));

const SearchResults: React.FC = props => {
  const classes = useStyles();
  const searchMode = useSelector(state => state.talentSearch.searchMode);

  const talentProfilesResponse = useSelector(
    state => state.talentSearch.talentProfilesResponse.data
  );

  const talentSearchResponse = useSelector(state => state.talentSearch.talentSearchResponse.data);

  const talentData =
    searchMode === SearchModes.talentProfiles ? talentProfilesResponse : talentSearchResponse;

  return (
    <div className={classes.root}>
      {talentData && (
        <div className={classes.searchResultsTable}>
          <SearchResultsTable data={talentData.items} />
        </div>
      )}
    </div>
  );
};

export default SearchResults;
