import { OpenRolesResponse, Pagination } from '../openRoles';
import {
  ACTIONS,
  ProjectRecommendationActions,
  SetSort,
} from '../../actions/projectRecommendation';
import { combineReducers } from 'redux';
import { SortObject } from '../../actions/openRoles';

const projectRecommendationResponse = (
  state: OpenRolesResponse = {
    data: null,
    isLoading: false,
    isLoaded: false,
  },
  action: ProjectRecommendationActions
): OpenRolesResponse => {
  switch (action.type) {
    case ACTIONS.FETCH_PR_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTIONS.FETCH_PR:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        data: action.payload,
      };
    case ACTIONS.FETCH_PR_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const pagination = (
  state: Pagination = {
    currentPage: 0,
    rowsPerPage: 10,
  },
  action: ProjectRecommendationActions
) => {
  switch (action.type) {
    case ACTIONS.SET_PR_PAGE: {
      const { currentPage } = state;

      if (currentPage !== action.payload) {
        return { ...state, currentPage: action.payload };
      }

      return state;
    }
    default:
      return state;
  }
};

export const defaultFilters = {
  isSold: null,
  enterpriseID: '',
};

const filters = (state = defaultFilters, action: ProjectRecommendationActions) => {
  switch (action.type) {
    case ACTIONS.SET_FILTER: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

const sorting = (
  state: SortObject = {
    orderBy: 'roleName',
    order: 'asc',
  },
  action: SetSort
) => {
  switch (action.type) {
    case ACTIONS.SET_SORT: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default combineReducers({
  projectRecommendationResponse,
  pagination,
  filters,
  sorting,
});
