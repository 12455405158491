import React, { useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Theme,
  Grid,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { PMI_TalentData, SkillData, TalentSearchPayload } from '../../actions/talentSearch';
import TalentProfilePicture from '../Images/TalentProfilePicture';
import ExpansionPanelContent from './ExpansionPanelContent';
import { sortSkillsByMostProficient, extractExtraSkills } from '../../utils/sortSkills';
import { useSelector } from '../../reduxHooks';
import MatchTerm from '../shared/MatchTerm/MatchTerm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:nth-child(2)::before': {
      display: 'none',
    },
  },
  panelContent: {
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(15),
  },
  rankingColumn: {
    minWidth: 50,
    maxWidth: 50,
    paddingLeft: 5,
    textAlign: 'center',
    marginRight: 10,
    [theme.breakpoints.down(400)]: {
      display: 'none',
    },
  },
  profileColumn: {
    width: 50,
  },
  nameColumn: {
    minWidth: '50%',
    padding: '0 12px',
    marginLeft: '24px',
    flex: 1,
    [theme.breakpoints.down(640)]: {
      maxWidth: '130px',
    },
    [theme.breakpoints.down(400)]: {
      padding: 0,
      margin: 0,
    },
  },
  matchPictureColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0'
    },
    [theme.breakpoints.down(600)]: {
      margin: '20px 0'
    },
  },
  gridColumn: {
    height: '100%',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    marginRight: 6,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
    },
  },
  skillsColumn: {
    flexGrow: 1,
    width: 0,
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: 25,
    borderLeft: '1px solid #DEDEDE',
    paddingLeft: 15,
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableCell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  expansionPanelDetails: {
    padding: 0,
  },
  skillsRow: {
    textTransform: 'uppercase',
    color: '#39aefe',
    fontWeight: 500,
  },
  queryItem: {
    marginBottom: '10px',
  },
}));

interface TableRowProps {
  searchResult: PMI_TalentData;
}

/** The table rows handle the data of each of the individuals returned by the backend **/
const TableRow: React.FC<TableRowProps> = props => {
  const searchMode = useSelector(state => state.talentSearch.searchMode);

  const talentSearchResults = useSelector(state => state.talentSearch);

  const termsDetected: TalentSearchPayload['termsDetected'] =
    searchMode === 'talentProfiles'
      ? undefined
      : talentSearchResults.talentSearchResponse.data.termsDetected;

  const talent = props.searchResult;
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const RenderSkillSetChips = () => {
    const { competency, extra } = talent; // extra = related skills

    // +++++++++++++++++ STORING THE GENERAL RELATED COMPETENCIES RETURNED FOR THIS PARTICULAR SKILL SEARCH +++++++++++++++++++++

    const relatedSkills: string[] = [];
    const mainSkills: string[] = [];

    searchMode !== 'talentProfiles' &&
      termsDetected &&
      termsDetected.forEach(terms => {
        if (
          (terms && terms.category && terms.category === 'COMPETENCYPOOL') ||
          terms.category === 'FUNCTION'
        ) {
          //** All tallent's skills related to the one searched for are put in this array  **/
          terms.predictions &&
            terms.predictions.map((competency: string) => relatedSkills.push(competency));
        } else if (terms && terms.category && terms.category === 'COMPETENCY') {
          //** All main skills related to the search are put in this array  */
          terms.predictions &&
            terms.predictions.map((competency: string) => mainSkills.push(competency));
        }
      });

    // +++++++++++++++++++++++ MAPPING THE TALENT'S SKILLS ++++++++++++++++++++++++++++++++
    /** get all talent's competencies and check if they coincide with the expertises the backend returned
     * If the talent's competencies are in that array, we map them to the 'main competencies' field on talent search results.
     */
    const competencies: SkillData[] = [];
    const related: SkillData[] = [];

    competency.forEach((skill: SkillData) => {
      if (mainSkills.includes(skill.name)) {
        competencies.push(skill);
      }
    });

    competency.forEach((skill: SkillData) => {
      related.push(skill);
    });

    // check if thalent has this skill, if so it will get shown
    extra.forEach(skill => {
      related.push({ name: skill.term, proficiency: 0 });
    });

    const skillsMatch =
      competencies && competencies.length > 3 ? competencies.slice(0, 3) : competencies; // It looks bad if all skills are shown

    const skillsInferred = related && related.length > 3 ? related.slice(0, 3) : related;

    //** Return different skills depending on where we're mapping them to  */
    const mapSkills = (
      key: string,
      label: string,
      skillsArrayLength: number,
      index: number,
      mapTo: string,
      avatarContent?: React.ReactNode
    ) => {
      //** If we are looking to match a profile we don't return related skills  */
      if (searchMode !== 'talentSearch') {
        if (mapTo === 'searched') {
          //** If we want to search for more than one skill */
          return (
            <span key={key} className={classes.queryItem}>
              {label}
            </span>
          );
        } else if (mapTo === 'related') {
          // We want everything to be a perfect match
          return null;
        }
      }

      //** For talent searches we return related skills */
      // Return to 'Searched Skills' field
      if (mapTo === 'searched') {
        // If we want to search for more than one skill
        if (label && mainSkills.includes(label) && index + 1 < skillsArrayLength) {
          return (
            <span key={key} className={classes.queryItem}>
              {label}
            </span>
          );
        } else if (label && mainSkills.includes(label) && index + 1 === skillsArrayLength) {
          return (
            <span key={key} className={classes.queryItem}>
              {label}
            </span>
          );
        }
      } else if (mapTo === 'related') {
        // Return to 'Related Skills' field
        if (label && !mainSkills.includes(label) && index + 1 < skillsArrayLength) {
          return (
            <span key={key} className={classes.queryItem}>
              {label}
            </span>
          );
        } else if (label && !mainSkills.includes(label) && index + 1 === skillsArrayLength) {
          return (
            <span key={key} className={classes.queryItem}>
              {label}
            </span>
          );
        }
      } else if (mapTo === 'sherlock') {
        // Return to 'Sherlock Icon'
        if (label && !mainSkills.includes(label.toLowerCase())) {
          return (
            <span key={key} className={classes.queryItem}>
              {avatarContent} {label}
            </span>
          );
        }
      }
    };

    const skillsMatchSorted = sortSkillsByMostProficient(skillsMatch);
    const extraSkills: string[] = extractExtraSkills(talent.extra, skillsMatchSorted);

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p className={classes.skillsRow}>Main Competencies</p>

          {skillsMatch &&
            skillsMatchSorted.map((skill, index: number) =>
              mapSkills(
                `skill${skill.name}${index}`,
                skill.name,
                skillsMatch.length,
                index,
                'searched'
              )
            )}
        </div>
        <Divider />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p className={classes.skillsRow}>Related Competencies</p>
          {extraSkills.map((competency: string, i: number) => (
            <span key={competency + i} className={classes.queryItem}>
              {competency}
            </span>
          ))}
          {skillsInferred &&
            sortSkillsByMostProficient(skillsInferred).map((skill, index: number) =>
              mapSkills(
                `skill${skill.name}${index}`,
                skill.name,
                skillsInferred.length,
                index,
                'related'
              )
            )}
        </div>
      </>
    );
  };
  
  const isFromRoleSearch = false

  /** Simplify the numbers in the ranking */
  const checkNumber = (score: number) => {
    score = score * 100;

    return Number.isInteger(score)
      ? score + '% Match'
      : Number.parseFloat(score.toFixed(2)) + '% Match';
  };

  return (
    <ExpansionPanel
      key={talent.firstName}
      expanded={expanded}
      onChange={toggleExpanded}
      classes={{ root: classes.root }}
    >
      <ExpansionPanelSummary
        classes={{ content: classes.panelContent }}
        expandIcon={<ExpandMoreIcon />}
        style={{ margin: 0, padding: '0 24px' }}
      >
        <MatchTerm score={talent.score} isFromRoleSearch={isFromRoleSearch}/>
        <Grid item xs={12} md={5} lg={4} className={classes.gridColumn}>
          <Grid item xs={12} md={3} className={classes.matchPictureColumn}>
            {searchMode === 'talentProfiles' ? null : talent.rankPosition ? (
              <Typography style={{marginTop:'10px', fontSize: '13px', whiteSpace: 'nowrap'}}>
                {checkNumber(talent.score)}
              </Typography>
            ) : null}
            <div className={classes.profileColumn}>
              {/******* Talent info goes here *******/}
              <Grid container justify='center' alignItems='center'>
                <TalentProfilePicture placeholderStyle={{ position: 'absolute' }} talent={talent} isFromRoleSearch={isFromRoleSearch}/>
              </Grid>
            </div>
          </Grid>
          <div className={classes.nameColumn}>
            <Typography variant='h6' className={classes.tableCell}>
              {`${talent.firstName} ${talent.lastName}`}
            </Typography>
            <Typography variant='body2'>
              {talent.function} Function Group, {talent.city} Office
            </Typography>
          </div>
          {/*******  End Talent info *******/}
        </Grid>
        <div className={classes.skillsColumn}>
          {searchMode !== 'talentProfiles' && RenderSkillSetChips()}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
        {expanded && <ExpansionPanelContent talent={talent} />}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default TableRow;
